/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Card, CircularProgress, Grid, List, ListItem, ListItemText} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import CollectionGridItems from '../../components/CollectionGridItems';
import UserFavouritedLinks from '../../components/UserFavouritedLinks';
import UserPublishedLinks from '../../components/UserPublishedLinks';
import {
  CollectionsContext,
  LinksContext,
  ProfileCollectionsContext,
  UsersContext,
} from '../../context';
import {get} from '../../requests';

const useStyles = makeStyles(theme => ({
  root: {},
  iconPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
  },
  viewAllText: {
    width: '62px',
    color: '#0266B2',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  userName: {
    height: '32px',
    lineHeight: '32px',
    marginTop: '15px !important',
    marginBottom: '9px !important',
    fontSize: '27px',
    color: '#333948',
    letterSpacing: '-0.5px',
  },
  userEmail: {
    marginTop: 0,
    marginBottom: '45px !important',
    height: '19px',
    lineHeight: '19px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#0266B2',
  },
  showMoreButton: {
    color: '#0266B2',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  showMoreSpacing: {
    marginTop: 32,
  },
}));

const Profile = () => {
  const classes = useStyles();
  const params = useParams();
  const profileId = params.user;
  const [profileCollections, setProfileCollections] = useContext(ProfileCollectionsContext);
  const [links, setLinks] = useContext(LinksContext);
  const [users, setUsers] = useContext(UsersContext);
  const [collections] = useContext(CollectionsContext);
  const [profile, setProfile] = useState(null);
  const [userFavourites, setUserFavourites] = useState(null);
  const [userUploaded, setUserUploaded] = useState(null);
  const [userCollections, setUserCollections] = useState(null);
  const [viewableCollections, setViewableCollections] = useState([]);
  const [showMore, setShowMore] = useState(null);
  const [userProfileCollection, setUserProfileCollection] = useState(true);

  useEffect(() => {
    const initalItems =
      userCollections && userCollections.length > 4
        ? [userCollections[0], userCollections[1], userCollections[2], userCollections[3]]
        : userCollections;
    setViewableCollections(initalItems);
    setProfileCollections(userCollections);
  }, [userCollections]);

  useEffect(() => {
    if (userCollections && userCollections.length > 4) setShowMore(true);
    if (
      viewableCollections &&
      viewableCollections.length &&
      userCollections.length === viewableCollections.length
    )
      setShowMore(false);
  }, [viewableCollections]);

  const getProfileData = () => {
    Promise.all([
      get('links/favourite', profileId),
      get('links/uploaded', profileId),
      get(`users/${profileId}/collections`),
      get('user', profileId),
    ])
      .then(([fetchedFavourites, fetchedUploaded, fetchedUserCollections, fetchedProfile]) => {
        setUserFavourites(fetchedFavourites);
        setUserUploaded(fetchedUploaded);
        setUserCollections(fetchedUserCollections);
        setProfile(fetchedProfile);
      })
      .catch(error => {
        console.log(':( something is wrong with the API');
      });
  };

  if (profile === null) get('user', profileId).then(setProfile);

  const handleViewMore = () => {
    let numberOfCollectionsBeingViewed;
    if (viewableCollections && viewableCollections.length)
      numberOfCollectionsBeingViewed = viewableCollections.length;
    if (numberOfCollectionsBeingViewed && numberOfCollectionsBeingViewed === userCollections.length)
      setShowMore(false);
    const numberOfTotalCollections = userCollections.length;
    const remainingCollections = numberOfTotalCollections - numberOfCollectionsBeingViewed;
    const shouldFullIncrement = remainingCollections > 4;
    const newCollections = shouldFullIncrement
      ? userCollections.slice(numberOfCollectionsBeingViewed, numberOfCollectionsBeingViewed + 4)
      : userCollections.slice(
          numberOfCollectionsBeingViewed,
          numberOfCollectionsBeingViewed + remainingCollections
        );
    setViewableCollections([...viewableCollections, ...newCollections]);
  };

  useEffect(() => {
    getProfileData();
  }, [links, collections, profileId]);

  return (
    <section>
      {userFavourites && userUploaded && userCollections && profile ? (
        <>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              {profile ? (
                <img
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '5%',
                  }}
                  src={profile.imageBase64}
                  alt="preview"
                />
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Grid item>
              {profile ? (
                <h1 className={classes.userName}>{profile.name}</h1>
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Grid item>
              {profile ? (
                <h2 className={classes.userEmail}>{profile.emailAddress}</h2>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" spacing={4}>
            <Grid key={0} item xs={6}>
              <Card className={classes.card}>
                <UserPublishedLinks user={profile} links={userUploaded} />
              </Card>
            </Grid>
            <Grid key={1} item xs={6}>
              <Card className={classes.card}>
                <UserFavouritedLinks user={profile} links={userFavourites} />
              </Card>
            </Grid>
            {viewableCollections
              ? viewableCollections.map(collection => {
                  return (
                    <Grid key={collection.id} item xs={6}>
                      <Card className={classes.card}>
                        <CollectionGridItems
                          userProfileCollection={userProfileCollection}
                          name={collection.name}
                          collectionLinks={collection.links}
                          links={links}
                          users={users}
                          id={collection.id}
                          collectionFilter={collection.filter}
                          privateLink={collection.private}
                          isSmart={collection.smart}
                          owner={profile.id}
                        />
                      </Card>
                    </Grid>
                  );
                })
              : null}
          </Grid>
          {showMore ? (
            <Grid
              className={classes.showMoreSpacing}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2}>
                <List>
                  <ListItem button onClick={handleViewMore}>
                    <ListItemText
                      className={classes.showMoreButton}
                      disableTypography
                      primary="show more"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : (
        <CircularProgress />
      )}
    </section>
  );
};

export default Profile;
