import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {UsersContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';
import LinksResults from '../../components/LinksResults';

const UserLinksList = () => {
  const location = useLocation();
  const id = location.pathname.split('/')[1];
  const [users] = useContext(UsersContext);
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const links = await get(`links/uploaded/${id}`);
        if (links) {
          setUploadedLinks(links);
        }
      };
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (users && id) {
      const profileUser = users.find(u => u.id === parseInt(id, 10));
      setUser(profileUser);
    }
  }, [users, id]);

  return (
    user && <LinksResults links={uploadedLinks} current={{name: user.displayName + `'s uploaded links`, isFunctionalGroup: false}} />
  );
};

export default withStyles(styles)(UserLinksList);
