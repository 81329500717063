/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, Divider, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import Category from './Category';
import FormModal from '../../components/FormModal';
import {CategoriesContext, UserContext} from '../../context';
import {get, post, postImage} from '../../requests';
import styles from '../../styles';
import AddCategory from './AddCategory';

const Categories = props => {
  const {classes} = props;
  const [file, setFile] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [openFunctionalAdd, setOpenFunctionalAdd] = useState(false);
  const [tags, setTags] = useState();
  const [categoryName, setCategoryName] = useState('');
  const [isFunctionalGroupCategory, setIsFunctionalGroupCategory] = useState(false);
  const [categories, setCategories] = useContext(CategoriesContext);
  const [allTags, setAllTags] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newCategory, setNewCategory] = useState(null);
  const [user] = useContext(UserContext);

  function handleAddOpen() {
    setIsFunctionalGroupCategory(false);
    setCategoryName('');
    setOpenAdd(true);
  }

  function handleAddFunctionalGroupOpen() {
    setCategoryName('');
    setOpenFunctionalAdd(true);
    setIsFunctionalGroupCategory(true);
  }

  const handleClose = () => {
    setCategoryName('');
    setOpenAdd(false);
    setOpenFunctionalAdd(false);
  };

  const handleAddCategory = () => {
    const fetchData = async () => {
      const addedCategory = await post('tag', {
        name: categoryName,
        type: 2,
        isFunctionalGroup: isFunctionalGroupCategory,
      });
      setNewCategory(addedCategory);
    };
    fetchData();
  };

  const formData = new FormData();

  const setImage = () => {
    formData.append('file', file);
    setNewImage(formData);
  };

  useEffect(() => {
    setTags(categories);
  }, [categories]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedTags = await get('tags');
      setAllTags(fetchedTags);
    };
    fetchData();
  }, [tags]);

  useEffect(() => {
    if (newCategory) {
      const fetchData = async () => {
        if (newImage) {
          await postImage(`category/${newCategory.id}/image`, newImage);
        }
        const fetchedCategories = await get('tags?type=Category');
        setCategories(fetchedCategories);
      };
      fetchData();
    }
  }, [newCategory]);

  useEffect(() => {
    if (file) {
      setImage();
    }
  }, [file]);

  return (
    <section style={{display: 'flex', flexDirection: 'column'}}>
      {openAdd && user.userLevel === 'Admin' ? (
        <AddCategory
          open={openAdd}
          setOpen={setOpenAdd}
          file={file}
          setFile={setFile}
          isFunctionalGroup={false}
          edit={false}
        />
      ) : null}

      {openFunctionalAdd && user.userLevel === 'Admin' ? (
        <AddCategory
          open={openFunctionalAdd}
          setOpen={setOpenFunctionalAdd}
          file={file}
          setFile={setFile}
          isFunctionalGroup
          edit={false}
        />
      ) : null}

      <Box display="flex" flexDirection="row" justifyContent="center" style={{paddingBottom: 20}}>
        <Typography className={classes.titleFont}>functional groups</Typography>
        {user.userLevel === 'Admin' ? (
          <Button onClick={handleAddFunctionalGroupOpen} className={classes.addCollectionButton}>
            add
          </Button>
        ) : null}
      </Box>

      <Divider className={classes.dividerFull} />

      {tags ? (
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
          {tags
            .filter(t => t.isFunctionalGroup)
            .map(tag => (
              <Box key={tag.id}>
                <Category category={tag} key={tag.id} />
              </Box>
            ))}
        </Box>
      ) : null}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{paddingTop: 45, paddingBottom: 20}}
      >
        <Typography className={classes.titleFont}>other categories</Typography>
        {user.userLevel === 'Admin' ? (
          <Button onClick={handleAddOpen} className={classes.addCollectionButton}>
            add
          </Button>
        ) : null}
      </Box>
      <Divider className={classes.dividerFull} />

      {tags ? (
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
          {tags
            .filter(t => !t.isFunctionalGroup)
            .map(tag => (
              <Box key={tag.id}>
                <Category category={tag} key={tag.id} />
              </Box>
            ))}
        </Box>
      ) : null}
    </section>
  );
};

export default withStyles(styles)(Categories);
