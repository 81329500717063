/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Grid, ListItem, Paper, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import EditOutlined from '@material-ui/icons/EditOutlined';
import styles from '../../../styles';
import {UserContext} from '../../../context';
import AddCategory from '../AddCategory';

const Category = props => {
  const {category, classes} = props;

  const [openEdit, setEditOpen] = useState(false);
  const [file, setFile] = useState();
  const [newImage, setNewImage] = useState(null);
  const [hover, setHover] = useState(false);
  const [user] = useContext(UserContext);

  const handleEditCategoryOpen = () => {
    setEditOpen(true);
  };

  const formData = new FormData();

  const setImage = () => {
    formData.append('file', file);
    setNewImage(formData);
  };

  useEffect(() => {
    if (file) {
      setImage();
    }
  }, [file]);

  return (
    <section>
      {openEdit ? (
        <AddCategory
          open={openEdit}
          setOpen={setEditOpen}
          category={category}
          setFile={setFile}
          file={file}
          edit
          imageUrl={category.imageUrl}
        />
      ) : null}

      <Paper
        className={classes.categoryChip}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Grid container className={classes.categoryCardHover}>
          <Grid
            item
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Button
              component={Link}
              to={`/categories/${category.name
                .replace(/[^\w\s-]/g, '')
                .replace(/\s+/g, '-')
                .trim()
                .toLowerCase()}`}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Typography
                style={{
                  color: 'white',
                  fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
                  fontSize: 27,
                  fontWeight: 'bold',
                  lineHeight: 1,
                  textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                  textTransform: 'none',
                  width: 208,
                }}
              >
                {category.name}
              </Typography>
            </Button>
          </Grid>
          {user.isAdmin && (
            <Grid container className={classes.categoryCardHoverActionIcons}>
              <Grid
                item
                style={{
                  float: 'right',
                  width: '100%',
                  paddingBottom: '5px',
                }}
              >
                <EditOutlined
                  className={classes.linkCardHoverActionIcon}
                  onClick={handleEditCategoryOpen}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <img
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            filter: 'brightness(75%)',
            borderRadius: 5,
          }}
          src={category.imageUrl}
          alt=""
        />
        <Typography className={classes.categoryChipName}>{category.name}</Typography>
      </Paper>
    </section>
  );
};

export default withStyles(styles)(Category);
