import {createContext} from 'react';

export const ConfirmModalContext = createContext([() => {}]);
export const ConfirmModalProvider = ConfirmModalContext.Provider;

export const OpenContext = createContext([() => {}]);
export const OpenProvider = OpenContext.Provider;

export const CertLevelsContext = createContext([() => {}]);
export const CertLevelsProvider = CertLevelsContext.Provider;

export const CollectionsContext = createContext([() => {}]);
export const CollectionsProvider = CollectionsContext.Provider;

export const SharedCollectionsContext = createContext([() => {}]);
export const SharedCollectionsProvider = SharedCollectionsContext.Provider;

export const LinksContext = createContext([() => {}]);
export const LinksProvider = LinksContext.Provider;

export const ExpiringContext = createContext([() => {}]);
export const ExpiringProvider = ExpiringContext.Provider;

export const TagsContext = createContext([() => {}]);
export const TagsProvider = TagsContext.Provider;

export const DataSrcsContext = createContext([() => {}]);
export const DataSrcsProvider = DataSrcsContext.Provider;

export const FormStateContext = createContext([() => {}]);
export const FormStateProvier = FormStateContext.Provider;

export const CategoriesContext = createContext([() => {}]);
export const CategoriesProvier = CategoriesContext.Provider;

export const UsersContext = createContext([() => {}]);
export const UsersProvider = UsersContext.Provider;

export const UserContext = createContext([() => {}]);
export const UserProvider = UserContext.Provider;

export const TypesContext = createContext([() => {}]);
export const TypesProvider = TypesContext.Provider;

export const CommentsContext = createContext([() => {}]);
export const CommentsProvider = CommentsContext.Provider;

export const DeleteCommentContext = createContext([() => {}]);
export const DeleteCommentProvider = DeleteCommentContext.Provider;

export const CommentIdContext = createContext([() => {}]);
export const CommentIdProvider = CommentIdContext.Provider;

export const NewImageContext = createContext([() => {}]);
export const NewImageProvider = NewImageContext.Provider;

export const SearchContext = createContext([() => {}]);
export const SearchProvider = SearchContext.Provider;

export const FeaturedContext = createContext([() => {}]);
export const FeaturedProvider = FeaturedContext.Provider;

export const UpgradeRequestsContext = createContext([() => {}]);
export const UpgradeRequestsProvider = UpgradeRequestsContext.Provider;

export const RecentlyViewedLinksContext = createContext([() => {}]);
export const RecentlyViewedLinksProvider = RecentlyViewedLinksContext.Provider;

export const ArchivedLinksContext = createContext([() => {}]);
export const ArchivedLinksProvider = ArchivedLinksContext.Provider;

export const ProfileCollectionsContext = createContext([() => {}]);
export const ProfileCollectionsProvider = ProfileCollectionsContext.Provider;

export const PinnedCommentsContext = createContext([() => {}]);
export const PinnedCommentsProvider = PinnedCommentsContext.Provider;

export const SubscribedCollectionContext = createContext([() => {}]);
export const SubscribedCollectionProvider = SubscribedCollectionContext.Provider;
