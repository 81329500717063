import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {
  LinksContext,
  RecentlyViewedLinksContext,
  UserContext,
  UsersContext,
} from '../../../../context';
import {get} from '../../../../requests';
import styles from '../../../../styles';
import SmallLinkRow from '../../../../components/SmallLinkRow';

const RecentlyViewedLinks = props => {
  const {classes} = props;

  const [links] = useContext(LinksContext);
  const [user] = useContext(UserContext);
  const [users] = useContext(UsersContext);
  const [recentlyViewedLinks, setRecentlyViewedLinks] = useContext(RecentlyViewedLinksContext);

  useEffect(() => {
    const fetchData = async () => {
      const recentlyViewed = await get('links/recently-viewed');
      if (recentlyViewed) {
        setRecentlyViewedLinks(recentlyViewed);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, users, links]);

  return (
    <>
      <List className={classes.base} style={{minHeight: 447}}>
        <ListItem>
          <ListItemText
            classes={{primary: classes.headingFont}}
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 24,
            }}
          >
            recently viewed
          </ListItemText>
          <Button className={classes.viewAllButton} component={Link} to="/recently-viewed">
            view all
          </Button>
        </ListItem>
        <Divider />
        <Table>
          <TableBody>
            {recentlyViewedLinks && recentlyViewedLinks.length > 0 && users ? (
              recentlyViewedLinks
                .slice(0, 5)
                .map(link => <SmallLinkRow key={link.id} link={link} classes={classes} />)
            ) : (
              <TableRow>
                <TableCell
                  style={{
                    height: '323px',
                  }}
                >
                  <Typography
                    className={classes.linkRowBoldText}
                    style={{
                      margin: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    no links visited yet...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </List>
    </>
  );
};

export default withStyles(styles)(RecentlyViewedLinks);
