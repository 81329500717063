import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import {SearchContext} from '../../context';
import {get} from '../../requests';
import {SearchTags} from '../../shared/Constants';
import styles from '../../styles';
import SearchResultView from './SearchResultView';

const SearchResults = props => {
  const {classes} = props;

  const [searchContext] = useContext(SearchContext);
  const [searchString, setSearchString] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [viewAll, setViewAll] = useState(true);
  const [viewAllUsers, setViewAllUsers] = useState(false);
  const [viewAllLinks, setViewAllLinks] = useState(false);
  const [viewAllCollections, setViewAllCollections] = useState(false);
  const [resultsPrefixText, setResultsPrefixText] = useState('search');

  const handleViewAllByTag = tag => {
    setViewAll(false);
    setResultsPrefixText(tag.toLowerCase());
    if (tag === SearchTags.PEOPLE) {
      setViewAllUsers(true);
    } else if (tag === SearchTags.LINKS) {
      setViewAllLinks(true);
    } else {
      setViewAllCollections(true);
    }
  };

  useEffect(() => {
    if (searchString) {
      const fetchData = async () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
          setSearchTimeout(null);
        }
        const timeout = setTimeout(async () => {
          const newSearchResults = await get(`search/${searchString}`);
          if (newSearchResults) {
            newSearchResults.users = newSearchResults.users.filter(u => u.imageBase64 != null);
            setSearchResults(newSearchResults);
            setViewAll(true);
            setViewAllUsers(false);
            setViewAllLinks(false);
            setViewAllCollections(false);
          }
        }, 50);
        setSearchTimeout(timeout);
      };
      fetchData();
    } else {
      setSearchResults(null);
    }
  }, [searchString]);

  useEffect(() => {
    if (searchContext) {
      setSearchString(searchContext.searchString);
      setViewAll(searchContext.viewAll);
      setViewAllLinks(!searchContext.viewAll);
      setViewAllUsers(!searchContext.viewAll);
      setViewAllCollections(!searchContext.viewAll);
    }
  }, [searchContext]);

  return (
    <section>
      <Typography className={classes.searchResultsHeader} noWrap>
        {viewAll ? 'search' : resultsPrefixText} results for{' '}
        <span style={{fontWeight: 'bold'}}>{searchString}</span>
      </Typography>

      {Array.from(Object.values(SearchTags)).map(
        (tag, index) =>
          tag !== 'All' && (
            <div key={tag}>
              <SearchResultView
                tag={tag}
                viewAll={viewAll}
                viewAllUsers={viewAllUsers}
                viewAllLinks={viewAllLinks}
                viewAllCollections={viewAllCollections}
                searchResults={searchResults}
                handleViewAllByTag={handleViewAllByTag}
              />
            </div>
          )
      )}
    </section>
  );
};

export default withStyles(styles)(SearchResults);
