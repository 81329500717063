/* eslint-disable no-unused-vars */
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import React, {useContext, useEffect, useState} from 'react';
import {giveUserFeedback} from '../../../giveUserFeedback/giveUserFeedback';

import {LinksContext} from '../../../../context';
import {get, post} from '../../../../requests';
import styles from '../../../../styles';

const UpgradeRequestForm = props => {
  const snackbar = useSnackbar();
  const {link, onClose, classes} = props;
  const [links, setLinks] = useContext(LinksContext);
  const [currentLevel, setCurrentLevel] = useState('');
  const [reason, setReason] = useState('');
  const [currentView, setCurrentView] = useState('edit');
  const [levelChange, setLevelChange] = useState('Functional to Certified');
  const [newLevel, setNewLevel] = useState('');
  const [firstLevel, setFirstLevel] = useState('');
  const [secondLevel, setSecondLevel] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [requestedLevel, setRequestedLevel] = useState('');

  const handleNext = () => {
    setCurrentView('confirm');
  };

  const handleBack = () => {
    setCurrentView('edit');
  };

  const handleUndo = () => {
    return;
  };

  const handleSave = () => {
    setSaveButtonDisabled(true);
    post('upgrade-request', {link: link.id, requestedLevel, reason})
      .then(response => {
        const requestedLink =
          links && links.length ? links.find(link => link.id === response.link) : null;
        giveUserFeedback(snackbar, `${requestedLink.name} certification requested`);
      })
      .then(get('links').then(setLinks));
    onClose();
  };

  const handleFirst = () => {
    setNewLevel(firstLevel);
    setLevelChange(`${currentLevel} to ${firstLevel}`);
  };

  const handleSecond = () => {
    setNewLevel(secondLevel);
    setLevelChange(`${currentLevel} to ${secondLevel}`);
  };

  useEffect(() => {
    if (link.certificationLevel === 1) {
      setCurrentLevel('Certified');
      setFirstLevel('Self-Serve');
      setSecondLevel('Functional');
    } else if (link.certificationLevel === 2) {
      setCurrentLevel('Functional');
      setFirstLevel('Self-Serve');
      setSecondLevel('Certified');
    } else {
      setCurrentLevel('Self-Serve');
      setFirstLevel('Functional');
      setSecondLevel('Certified');
    }
  }, [link]);

  useEffect(() => {
    if (newLevel === 'Certified') {
      setRequestedLevel('OneStor');
    } else if (newLevel === 'Functional') {
      setRequestedLevel('Functional');
    } else {
      setRequestedLevel('SelfServe');
    }
  }, [newLevel]);

  useEffect(() => {
    setNextButtonDisabled(newLevel === '' || reason.length === 0);
  }, [newLevel, reason, currentLevel]);

  return (
    <Grid container style={{}}>
      <Grid item xs={12} className={classes.header}>
        <Typography style={{textAlign: 'center'}} className={classes.titleFont}>
          edit level
        </Typography>
      </Grid>

      {currentView === 'edit' ? (
        <>
          <Grid item xs={12}>
            <Typography className={classes.labelFont}>Name</Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              value={link.name}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.labelFont}>Current Level</Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              value={currentLevel}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.labelFont}>New Level *</Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  className={
                    newLevel === firstLevel ? classes.positiveButton : classes.negativeButton
                  }
                  onClick={handleFirst}
                >
                  {firstLevel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    newLevel === secondLevel ? classes.positiveButton : classes.negativeButton
                  }
                  onClick={handleSecond}
                >
                  {secondLevel}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.labelFont}>Reason *</Typography>
            <TextField
              multiline
              margin="dense"
              minRows={3}
              fullWidth
              onChange={event => setReason(event.target.value)}
              value={reason}
              variant="outlined"
            />
          </Grid>

          <Grid container justifyContent="center">
            <Grid item>
              <Button
                className={classes.positiveButton}
                onClick={handleNext}
                disabled={nextButtonDisabled}
              >
                next
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4}>
            <Typography className={classes.labelFont}>Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.paragraphFont}>{link.name}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.labelFont}>Level Change</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.paragraphFont}>{levelChange}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.labelFont}>Reason</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.paragraphFont}>{reason}</Typography>
          </Grid>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button className={classes.negativeButton} onClick={handleBack}>
                back
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.positiveButton}
                onClick={handleSave}
                disabled={saveButtonDisabled}
              >
                request
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(UpgradeRequestForm);
