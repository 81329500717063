import React, {useContext, useEffect, useState} from 'react';

import {Button, Grid, TextField} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import {get, post} from '../../../requests';

import {CommentsContext} from '../../../context';
import styles from '../../../styles';
import Comment from '../Comment';

const ParentComment = props => {
  const {comment, classes} = props;

  const [, setComments] = useContext(CommentsContext);

  const [showChildren, setShowChildren] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const handleCancel = () => setReplyText('');
  const handleSave = async () => {
    setSaveButtonDisabled(true);
    await post(`link/${comment.link}/comment`, {
      commentText: replyText,
      parentCommentId: comment.id,
    });
    const fetchedComments = await get(`link/${comment.link}/comments`);
    setComments(fetchedComments);
    setReplyText('');
  };

  useEffect(() => {
    setSaveButtonDisabled(replyText === '');
  }, [replyText]);

  return (
    <>
      <Comment
        comment={comment}
        type="parent"
        setShowChildren={setShowChildren}
        showChildren={showChildren}
      />
      {showChildren && comment
        ? comment.childComments.map(c => <Comment comment={c} type="child" />)
        : null}
      {showChildren && comment ? (
        <Grid container style={{paddingRight: '20px', marginBottom: '20px'}}>
          <Grid item xs={12}>
            <TextField
              multiline
              margin="dense"
              variant="outlined"
              minRows={3}
              fullWidth
              className={classes.textField}
              value={replyText}
              onChange={e => setReplyText(e.target.value)}
              style={{
                backgroundColor: '#FFFFFF',
                borderRadius: '5px',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.positiveButton}
              disabled={saveButtonDisabled}
              onClick={handleSave}
            >
              save
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className={classes.negativeButton} onClick={handleCancel}>
              cancel
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default withStyles(styles)(ParentComment);
