/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {Box, Button, Divider, Grid, Switch, TextField, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {
  CategoriesContext,
  DataSrcsContext,
  FormStateContext,
  LinksContext,
  NewImageContext,
  TagsContext,
  TypesContext,
  UserContext,
  UsersContext,
} from '../../context';
import {get, post, postImage, put} from '../../requests';
import styles from '../../styles';
import ImageUpload from '../ImageUpload';
import {giveUserFeedback} from '../giveUserFeedback/giveUserFeedback';

const AddLinkForm = props => {
  const {
    classes,
    edit,
    link,
    onClose,
    setDisableFormModalSaveButton,
    errorMessage,
    setErrorMessage,
  } = props;

  const formData = new FormData();
  const snackbar = useSnackbar();
  const [categories, setCategories] = useContext(CategoriesContext);
  const [formState, setFormState] = useContext(FormStateContext);
  const [links, setLinks] = useContext(LinksContext);
  const [types] = useContext(TypesContext);
  const [user] = useContext(UserContext);
  const [users] = useContext(UsersContext);
  const [newImage, setNewImage] = useContext(NewImageContext);
  const [tags, setTags] = useContext(TagsContext);
  const [dataSources, setDataSources] = useContext(DataSrcsContext);

  const [newTag, setNewTag] = useState();
  const [tagIds, setTagIds] = useState(edit ? link.tags : []);
  const [publisher, setPublisher] = useState(edit ? link.publisher : user.id);

  const [newDataSource, setNewDataSource] = useState();
  const [dataSourceIds, setDataSourceIds] = useState(edit ? link.dataSources : []);
  const [name, setName] = useState(edit ? link.name : '');
  const [url, setUrl] = useState(edit ? link.url : '');
  const [toggle, setToggle] = useState(edit ? {checked: link.private} : {checked: false});
  const [description, setDescription] = useState(edit ? link.description : '');

  const [categoryIds, setCategoryIds] = useState(edit ? link.categories : []);

  const [typeId, setTypeId] = useState(edit ? link.type : null);
  const [dataName, setDataName] = useState([]);

  const [file, setFile] = useState(null);
  const [displayFile, setDisplayFile] = useState(null);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const handleUndo = () => {
    return;
  };

  const userOptions = users.map((option, index) => ({
    value: option.id,
    text: option.name,
  }));

  const typeOptions = types.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const excludedCategories = ['all', 'archive'];
  const includedCategories = categories.filter(
    category => excludedCategories.indexOf(category.name.toLowerCase()) === -1
  );

  const categoryOptions = includedCategories.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const tagOptions = tags.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const dataSourceOptions = dataSources.map(option => ({
    value: option.id,
    text: option.name,
  }));

  useEffect(() => {
    if (!edit) {
      setFormState({
        name,
        url,
        description,
        publisher,
        private: toggle.checked,
        certificationLevel: 3,
        categories: categoryIds,
        dataSources: dataSourceIds,
        tags: tagIds,
        type: typeId,
      });
    }
  }, [
    setFormState,
    name,
    toggle,
    url,
    description,
    dataName,
    categoryIds,
    dataSourceIds,
    publisher,
    tagIds,
    typeId,
  ]);

  useEffect(() => {
    if (
      url === '' ||
      name === '' ||
      publisher === '' ||
      typeId === null ||
      description === '' ||
      categoryIds.length === 0
    ) {
      setDisableSaveButton(true);
      if (typeof setDisableFormModalSaveButton === 'function') {
        setDisableFormModalSaveButton(true);
      }
    } else {
      setDisableSaveButton(false);
      if (typeof setDisableFormModalSaveButton === 'function') {
        setDisableFormModalSaveButton(false);
      }
    }
  }, [url, name, publisher, typeId, description, categoryIds]);

  useEffect(() => {
    if (newTag) {
      const fetchData = async () => {
        const fetchedTags = await get('tags?type=Tag');
        setTags(fetchedTags);
      };
      fetchData();
    }
    if (newDataSource) {
      const fetchData = async () => {
        const fetchedDataSources = await get('tags?type=DataSource');
        setDataSources(fetchedDataSources);
      };
      fetchData();
    }
  }, [newTag, newDataSource]);

  const handleFileUpload = event => {
    const newDisplayFile = URL.createObjectURL(event.target.files[0]);
    setDisplayFile(newDisplayFile);
    const addedFile = event.target.files[0];
    setFile(addedFile);
  };

  const setImage = () => {
    formData.append('file', file);
    setNewImage(formData);
  };

  useEffect(() => {
    if (!edit) setImage();
  }, [file]);

  const handleSave = () => {
    put(`link/${link.id}`, {
      name,
      url,
      description,
      publisher,
      private: toggle.checked,
      certificationLevel: 3,
      categories: categoryIds,
      dataSources: dataSourceIds,
      tags: tagIds,
      type: typeId,
    })
      .then(response => {
        switch (response) {
          case 409:
            setErrorMessage('This link already exists. Please try a different URL.');
            break;
          default:
            if (response.id) {
              if (link.imgUrl !== file) {
                const setNewFile = async () => {
                  const newFormData = new FormData();
                  await newFormData.append('file', file);
                  postImage(`link/${link.id}/image`, newFormData);
                };
                setNewFile();
              }
              onClose();
              giveUserFeedback(snackbar, `Link edited: ${response.name}`);
            }
            break;
        }
      })
      .then(get('links').then(setLinks));
  };

  const handleChecked = value => event => {
    setToggle({...toggle, [value]: event.target.checked});
  };

  const handleTypeChange = (e, {value}) => {
    setTypeId(value);
  };

  const handlePublisherChange = (e, {value}) => {
    setPublisher(value);
  };

  const handleCategoryChange = (e, {value}) => {
    if (value) {
      setCategoryIds(value);
    }
  };

  const handleTagChange = (e, {value}) => {
    if (value) {
      setTagIds(value.filter(option => Number.isInteger(option)));
    }
  };

  const handleTagAddition = async (e, { value }) => {
    const addedTag = await post('tag', { name: camelcase(value), type: 1 });
    setNewTag(addedTag);
    setTagIds((prevTagIds) => [...prevTagIds, addedTag.id]);
  };

  const handleDataSourceChange = (e, {value}) => {
    if (value) {
      setDataSourceIds(value.filter(option => Number.isInteger(option)));
    }
  };

  const handleDataSourceAddition = (e, {value}) => {
    const fetchData = async () => {
      const addedDataSource = await post('tag', {name: value, type: 3});
      setNewDataSource(addedDataSource);
    };
    fetchData();
  };

  const camelcase = (value) => {
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Grid container style={{padding: '0 30px 20px 30px'}}>
      <Grid item xs={12} className={classes.header}>
        <Typography
          style={{display: 'inline-block', marginRight: 20}}
          className={classes.titleFont}
        >
          {edit ? 'edit' : 'post'} link
        </Typography>
        <Typography style={{display: 'inline-block'}} className={classes.blueNote} p={5}>
          *indicates a required field
        </Typography>

        <Divider style={{marginTop: 20}} className={classes.dividerFull} />
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography className={classes.labelFont}> URL * </Typography>
            <Typography className={classes.errorFont} style={{paddingLeft: '5px'}}>
              {errorMessage}
            </Typography>
          </div>
          <TextField
            required
            className={classes.textField}
            margin="dense"
            id="outlined-full-width"
            placeholder="URL"
            value={url}
            fullWidth
            onChange={event => setUrl(event.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Typography className={classes.labelFont}> Name* </Typography>
          <TextField
            required
            className={classes.textField}
            margin="dense"
            id="outlined-full-width"
            placeholder="Link title"
            value={name}
            fullWidth
            onChange={event => setName(event.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography className={classes.labelFont}>Publisher*</Typography>
              <Dropdown
                required
                className={classes.formControl}
                options={userOptions}
                placeholder="Select Publisher"
                search
                selection
                fluid
                value={publisher}
                onChange={handlePublisherChange}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.labelFont}>Type*</Typography>
              <Dropdown
                required
                className={classes.formControl}
                options={typeOptions}
                placeholder="Select Type"
                search
                selection
                fluid
                value={typeId}
                onChange={handleTypeChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} style={{marginBottom: 10}}>
          <Typography className={classes.labelFont}>Preview Image</Typography>
          <ImageUpload file={file} setFile={setFile} imageUrl={link ? link.imageUrl : null} />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography className={classes.labelFont}>Description*</Typography>
          <TextField
            required
            multiline
            margin="dense"
            id="outlined-full-width"
            placeholder="Purpose, target audience, related business workflows or commentary on data sources, warnings and highlights"
            value={description}
            fullWidth
            onChange={event => setDescription(event.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.labelFont}>Categories*</Typography>
          <Dropdown
            className={classes.formControl}
            options={categoryOptions}
            placeholder="Select Categories"
            search
            selection
            fluid
            multiple
            value={categoryIds}
            onChange={handleCategoryChange}
          />

          <Typography className={classes.labelFont}>Data Sources</Typography>
          <Dropdown
            className={classes.formControl}
            options={dataSourceOptions}
            placeholder="Select Data Sources"
            search
            selection
            fluid
            allowAdditions
            multiple
            additionLabel="+ Add new Data Source: "
            value={dataSourceIds}
            onAddItem={handleDataSourceAddition}
            onChange={handleDataSourceChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography className={classes.labelFont}>Private Link</Typography>
          <Box style={{display: 'flex', alignItems: 'flex-start'}}>
            <Typography
              style={{
                fontSize: 14,
                color: '#545963',
                fontWeight: 'fontWeightLight',
              }}
            >
              This link will be hidden from the public and only visible to you
            </Typography>
            <Switch
              style={{alignSelf: 'flex-end'}}
              checked={toggle.checked}
              onChange={handleChecked('checked')}
              value="checked"
              classes={{
                root: classes.toggle,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.labelFont}>Tags</Typography>

          <Dropdown
            className={classes.formControl}
            options={tagOptions}
            placeholder="Select Tags"
            search
            selection
            fluid
            allowAdditions
            multiple
            additionLabel="+ Add new tag: "
            value={tagIds}
            onAddItem={handleTagAddition}
            onChange={handleTagChange}
          />
        </Grid>
      </Grid>
      {edit ? (
        <Grid item>
          <Button
            disabled={disableSaveButton}
            className={classes.positiveButton}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

AddLinkForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddLinkForm);
