/* eslint-disable no-unused-vars */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import InactiveLink from '../../components/InactiveLink';
import {ExpiringContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';

const InactiveLinkPage = props => {
  const {classes} = props;

  const [expiringLinks] = useContext(ExpiringContext);
  const [newExpiringLinks, setNewExpiringLinks] = useState(expiringLinks);

  useEffect(() => {
    get('links/expiring').then(expiring => setNewExpiringLinks(expiring));
  }, [expiringLinks]);

  const unarchivedExpiring = newExpiringLinks.filter(link => link.archived === false);

  return (
    <section>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{
          paddingBottom: '15px',
        }}
      >
        <Typography className={classes.titleFont}>inactive links</Typography>
      </Box>

      <Paper
        style={{
          margin: '15px',
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.tableHeaderText}>inactive</Typography>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>level</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>updated</Typography>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unarchivedExpiring
              ? unarchivedExpiring.map(item => <InactiveLink key={item.id} link={item} />)
              : null}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
};

export default withStyles(styles)(InactiveLinkPage);
