/* eslint-disable no-unused-vars */
import {Grid, MenuItem, OutlinedInput, Select, TextField, Typography} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {
  CategoriesContext,
  DataSrcsContext,
  TagsContext,
  TypesContext,
  UsersContext,
} from '../../../context';
import styles from '../../../styles';
import DateToggle from './DateToggle';

const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const CustomizedExpansionPanels = props => {
  const {
    classes,
    filterUpdate,
    dropdownUpdate,
    dateUpdate,
    customDateUpdate,
    filter,
    isSmart,
    edit,
  } = props;
  const [expanded, setExpanded] = useState('');
  const [users] = useContext(UsersContext);
  const [categories] = useContext(CategoriesContext);
  const [tags] = useContext(TagsContext);
  const [types] = useContext(TypesContext);
  const [dataSources] = useContext(DataSrcsContext);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const userOptions = users.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const typeOptions = types.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const levelOptions = [
    {value: 0, text: 'All'},
    {value: 1, text: 'Certified'},
    {value: 2, text: 'Functional'},
    {value: 3, text: 'Self Serve'},
  ];

  const categoryOptions = categories.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const tagOptions = tags.map(option => ({
    value: option.id,
    text: option.name,
  }));

  const dataSourceOptions = dataSources.map(option => ({
    value: option.id,
    text: option.name,
  }));

  return !isSmart && edit ? null : (
    <div>
      {/*General Panel*/}
      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography className={classes.blueLabel}>+ general</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            <Grid item sm={1}>
              <Typography className={classes.labelFont} style={{marginTop: 15}}>
                {' '}
                Name
              </Typography>
            </Grid>

            <Grid item sm={4}>
              <Select
                value={filter.nameIncludes}
                input={<OutlinedInput name="nameIncludes" />}
                margin="dense"
                className={classes.formControl}
                onChange={filterUpdate('nameIncludes')}
              >
                <MenuItem value={true}>includes</MenuItem>
                <MenuItem value={false}>excludes</MenuItem>
              </Select>
            </Grid>
            <Grid item sm={7}>
              <TextField
                className={classes.formControl}
                id="outlined-full-width"
                margin="dense"
                placeholder="text in link title"
                onChange={filterUpdate('name')}
                fullWidth
                value={filter.name}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={4}>
              <Typography className={classes.labelFont}>Publisher</Typography>
              <Dropdown
                className={classes.formControl}
                options={userOptions}
                placeholder="Select Publisher"
                search
                fluid
                clearable
                selection
                value={filter.publisher}
                onChange={dropdownUpdate('publisher')}
              />
            </Grid>

            <Grid item sm={4}>
              <Typography className={classes.labelFont}>Type</Typography>
              <Dropdown
                className={classes.formControl}
                options={typeOptions}
                placeholder="Select Type"
                search
                fluid
                clearable
                selection
                value={filter.type}
                onChange={dropdownUpdate('type')}
              />
            </Grid>

            <Grid item sm={4}>
              <Typography className={classes.labelFont}>Level</Typography>
              <Dropdown
                className={classes.formControl}
                options={levelOptions}
                placeholder="Select Level"
                search
                fluid
                selection
                value={filter.level}
                onChange={dropdownUpdate('level')}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {/*Dates Panel*/}
      <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className={classes.blueLabel}>+ dates</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DateToggle
            filter={filter}
            edit
            filterUpdate={filterUpdate}
            dateUpdate={dateUpdate}
            customDateUpdate={customDateUpdate}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {/*Tags Panel*/}
      <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography className={classes.blueLabel}>+ tags</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item sm={12}>
              <Typography className={classes.labelFont}>Categories</Typography>
              <Dropdown
                className={classes.formControl}
                options={categoryOptions}
                placeholder="Select Categories"
                search
                fluid
                clearable
                selection
                multiple
                value={filter.categories}
                onChange={dropdownUpdate('categories')}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography className={classes.labelFont}>Data Sources</Typography>
              <Dropdown
                className={classes.formControl}
                options={dataSourceOptions}
                placeholder="Select Data Sources"
                search
                fluid
                clearable
                selection
                multiple
                value={filter.dataSources}
                onChange={dropdownUpdate('dataSources')}
              />
            </Grid>

            <Grid item sm={12}>
              <Typography className={classes.labelFont}>Tags</Typography>
              <Dropdown
                className={classes.formControl}
                options={tagOptions}
                placeholder="Select Tags"
                search
                fluid
                clearable
                selection
                multiple
                value={filter.tags}
                onChange={dropdownUpdate('tags')}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default withStyles(styles)(CustomizedExpansionPanels);
