export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AADREGISTRATION_APPID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE],
};
