import {Typography, withStyles} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {useParams} from 'react-router';
import LinkDetails from '../../components/LinkDetails';
import {LinksContext, UserContext} from '../../context';
import styles from '../../styles';

const LinkPage = props => {
  const {classes} = props;
  let {linkid} = useParams();
  const [links] = useContext(LinksContext);
  const [user] = useContext(UserContext);
  const [link, setLink] = useState(props.link ?? undefined);

  if (link === undefined) {
    let tempLink = links.find(link => link.id === parseInt(linkid));

    if (tempLink && (!tempLink.private || tempLink.publisher === user.id)) {
      setLink(tempLink);
    } else {
      setLink(null);
    }
  }

  return (
    <section>
      {link ? (
        <LinkDetails link={link} />
      ) : (
        <Typography
          className={classes.labelFont}
          style={{width: '80%', margin: 'auto', padding: '2em'}}
        >
          Link cannot be accessed, it may have been deleted or is private. Contact whoever shared
          the link with you.
        </Typography>
      )}
    </section>
  );
};

export default withStyles(styles)(LinkPage);
