/* eslint-disable no-unused-vars */

import {TableCell, TableRow, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {giveUserFeedback} from '../../../components/giveUserFeedback/giveUserFeedback';

import {LinksContext, UpgradeRequestsContext, UsersContext} from '../../../context';

import LinkIcon from '../../../components/LinkIcon';
import styles from '../../../styles';

import{
  InfoOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@material-ui/icons'

import {get, post} from '../../../requests';

const UpgradeRequest = props => {
  const snackbar = useSnackbar();
  const {upgradeRequest, classes} = props;

  const [link, setLink] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [requester, setRequester] = useState(null);
  const [dateAndRequester, setDateAndRequester] = useState('');
  const [levelFrom, setLevelFrom] = useState('');
  const [levelTo, setLevelTo] = useState('');

  const [hover, setHover] = useState(false);
  const [hoverOpenIcon, setHoverOpenIcon] = useState(false);
  const [hoverApproveIcon, setHoverApproveIcon] = useState(false);
  const [hoverDeclineIcon, setHoverDeclineIcon] = useState(false);

  const [links, setLinks] = useContext(LinksContext);
  const [upgradeRequests, setUpgradeRequests] = useContext(UpgradeRequestsContext);
  const [users] = useContext(UsersContext);

  const handleUndo = () => {
    return;
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleMouseEnterOpenIcon = () => setHoverOpenIcon(true);
  const handleMouseLeaveOpenIcon = () => setHoverOpenIcon(false);
  const handleMouseEnterApproveIcon = () => setHoverApproveIcon(true);
  const handleMouseLeaveApproveIcon = () => setHoverApproveIcon(false);
  const handleMouseEnterDeclineIcon = () => setHoverDeclineIcon(true);
  const handleMouseLeaveDeclineIcon = () => setHoverDeclineIcon(false);

  const openInNewTab = () => {
    let url;
    try {
      url = new URL(link.url);
    } catch {
      url = new URL(`http://${link.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${link.id}/view`);
  };

  const handleApprove = () => {
    // Filter out everything but those that are in the 'requested' state.
    // TODO: only request 'requested' requests after CO-514 is done
    post(`upgrade-request/${upgradeRequest.id}/approve`)
      .then(link => {
        const requestLink = links.find(l => l.id === link.link);
        giveUserFeedback(snackbar, `${requestLink.name} approved`);
        get('upgrade-requests').then(response => {
          setUpgradeRequests(response.filter(ur => ur.status === 'Requested'));
        });
      })
      .then(() => get('links').then(setLinks));
  };

  const handleDecline = () => {
    // Filter out everything but those that are in the 'requested' state.
    // TODO: only request 'requested' requests after CO-514 is done
    post(`upgrade-request/${upgradeRequest.id}/deny`)
      .then(link => {
        const requestLink = links.find(l => l.id === link.link);
        giveUserFeedback(snackbar, `${requestLink.name} denied`);
        return link;
      })
      .then(() => {
        get('upgrade-requests').then(response => {
          const filteredRequests = response.filter(ur => ur.status === 'Requested');
          setUpgradeRequests(response.filter(ur => ur.status === 'Requested'));
        });
      })
      .then(() => get('links').then(setLinks));
  };

  useEffect(() => {
    const foundLink = links.find(l => l.id === upgradeRequest.link);
    if (foundLink) {
      setLink(foundLink);
      if (foundLink.certificationLevel === 1) {
        setLevelFrom('OneStor Certified');
      } else if (foundLink.certificationLevel === 2) {
        setLevelFrom('Func. Certified');
      } else {
        setLevelFrom('Self Serve');
      }
    }
    const foundUser = users.find(u => u.id === foundLink.publisher);
    if (foundUser) {
      setPublisher(foundUser);
    }
    const foundRequester = users.find(u => u.id === upgradeRequest.requestedBy);
    if (foundRequester) {
      setRequester(foundRequester);
    }

    const date = moment.utc(upgradeRequest.requestDate).local().format('MMM D, Y');
    setDateAndRequester(`${date} by ${foundRequester.displayName}`);

    if (upgradeRequest.requestedLevel === 'OneStor') {
      setLevelTo('OneStor Certified');
    } else if (upgradeRequest.requestedLevel === 'Functional') {
      setLevelTo('Func. Certified');
    } else {
      setLevelTo('Self Serve');
    }
  }, [upgradeRequest, links, users]);

  return (
    link &&
    publisher && (
      <TableRow
        hover
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classes.tableRow}
      >
        <TableCell>
          <LinkIcon linkType={link.type} />
        </TableCell>
        <TableCell>
          <Typography className={classes.linkRowBoldText}>{link.name}</Typography>
          <Typography className={classes.linkRowRegularText}>{publisher.displayName}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.linkRowRegularText}>
            <span style={{fontWeight: 'bold'}}>{levelFrom}</span> to{' '}
            <span style={{fontWeight: 'bold'}}>{levelTo}</span>
          </Typography>
          <Typography className={classes.linkRowRegularText}>{dateAndRequester}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.linkRowRegularText}>{upgradeRequest.reason}</Typography>
        </TableCell>
        <TableCell
          style={{
            width: '100px',
          }}
        >
          {hover &&
            (hoverOpenIcon ? (
              <InfoOutlined
                className={classes.linkListIcon}
                color = '#009DD9'
                onClick={openInNewTab}
                onMouseLeave={handleMouseLeaveOpenIcon}
              />
            ) : (
              <InfoOutlined className={classes.linkListIcon} onMouseEnter={handleMouseEnterOpenIcon} />
            ))}
        </TableCell>

        <TableCell
          style={{
            width: '100px',
          }}
        >
          {hover &&
            (hoverApproveIcon ? (
              <CheckOutlined
                className={classes.linkListIcon}
                color='#009DD9'
                onClick={handleApprove}
                onMouseLeave={handleMouseLeaveApproveIcon}
              />
            ) : (
              <CheckOutlined
                className={classes.linkListIcon}
                onMouseEnter={handleMouseEnterApproveIcon}
              />
            ))}
        </TableCell>

        <TableCell
          style={{
            width: '100px',
          }}
        >
          {hover &&
            (hoverDeclineIcon ? (
              <CloseOutlined
                className={classes.linkListIcon}
                color='#009DD9'
                onClick={handleDecline}
                onMouseLeave={handleMouseLeaveDeclineIcon}
              />
            ) : (
              <CloseOutlined
                className={classes.linkListIcon}
                onMouseEnter={handleMouseEnterDeclineIcon}
              />
            ))}
        </TableCell>
      </TableRow>
    )
  );
};

export default withStyles(styles)(UpgradeRequest);
