import {Grid, Menu, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import moment from 'moment';
import React, {useState} from 'react';
import styles from '../../../../styles';
import DatePicker from './DatePicker';

const gothamFontFamily = ['Gotham A', 'Gotham B', 'GothamBookMedium'];

const StyledToggleButton = withStyles({
  root: {
    width: '150px',
    color: '#8B8E98',
    fontFamily: gothamFontFamily,
    '&$selected': {
      color: '#FFFFFF',
      backgroundColor: '#0266B2',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#0266B2',
      },
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#0266B2',
    },
  },
  selected: {
    fontFamily: gothamFontFamily,
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#0266B2',
  },
})(ToggleButton);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    overflowY: 'unset',
    overflowX: 'unset',
  },
  list: {
    padding: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function DateToggle(props) {
  const {classes, dateUpdate, customDateUpdate, filter, edit} = props;

  //upload
  const [pickedUpload, setPickedUpload] = useState(
    edit && filter.upload !== null ? filter.upload.range : () => ['bold']
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [customUploadDateStart, setCustomUploadDateStart] = useState(
    edit && filter.upload !== null ? filter.upload.start : null
  );
  const [customUploadDateEnd, setCustomUploadDateEnd] = useState(
    edit && filter.upload !== null ? filter.upload.end : null
  );

  //update
  const [pickedUpdate, setPickedUpdate] = useState(
    edit && filter.update !== null ? filter.update.range : () => ['bold']
  );
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [customUpdateDateStart, setCustomUpdateDateStart] = useState(
    edit && filter.update !== null ? filter.update.start : null
  );
  const [customUpdateDateEnd, setCustomUpdateDateEnd] = useState(
    edit && filter.update !== null ? filter.update.end : null
  );

  const handleDateClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };

  const handleUpload = (event, value) => {
    if (value === 'Custom') handleDateClick(event);
    else {
      dateUpdate('upload', 'range', value);
    }
    setPickedUpload(value);
  };

  const handleUpdate = (event, value) => {
    if (value === 'none') handleDateClick2(event);
    else {
      dateUpdate('update', 'range', value);
    }
    setPickedUpdate(value);
  };

  const handleClose = () => {
    customDateUpdate('upload', customUploadDateStart, customUploadDateEnd);
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    customDateUpdate('update', customUpdateDateStart, customUpdateDateEnd);
    setAnchorEl2(null);
  };

  return (
    <Grid container>
      <Typography className={classes.labelFont}>Uploaded</Typography>

      <Grid container style={{marginBottom: 20, padding: 30}} justifyContent="center">
        <ToggleButtonGroup exclusive value={pickedUpload} onChange={handleUpload} size="medium">
          <StyledToggleButton value="Day">Today</StyledToggleButton>
          <StyledToggleButton value="Week">This Week</StyledToggleButton>
          <StyledToggleButton value="Month">This Month</StyledToggleButton>
          <StyledToggleButton value="Year">This Year</StyledToggleButton>
          {/* <StyledToggleButton
            value="Custom"
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
          >
            {!customUploadDateStart
              ? 'Custom'
              : moment(customUploadDateStart).format('MMM Do YYYY') +
                '-' +
                moment(customUploadDateEnd).format('MMM Do YYYY')}
          </StyledToggleButton> */}

          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <DatePicker
              customDatePropStart={setCustomUploadDateStart}
              customDatePropEnd={setCustomUploadDateEnd}
            />
          </StyledMenu>
        </ToggleButtonGroup>
      </Grid>

      <Typography className={classes.labelFont}>Updated</Typography>

      <Grid container style={{marginBottom: 20, padding: 30}} justifyContent="center">
        <ToggleButtonGroup exclusive value={pickedUpdate} onChange={handleUpdate} size="medium">
          <StyledToggleButton value="Day">Today</StyledToggleButton>
          <StyledToggleButton value="Week">This Week</StyledToggleButton>
          <StyledToggleButton value="Month">This Month</StyledToggleButton>
          <StyledToggleButton value="Year">This Year</StyledToggleButton>
          {/* <StyledToggleButton
            value="Custom"
            aria-owns={anchorEl2 ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={handleDateClick2}
          >
            {!customUpdateDateStart
              ? 'Custom'
              : moment(customUpdateDateStart).format('MMM Do YYYY') +
                '-' +
                moment(customUpdateDateEnd).format('MMM Do YYYY')}
          </StyledToggleButton> */}

          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
          >
            <DatePicker
              customDatePropStart={setCustomUpdateDateStart}
              customDatePropEnd={setCustomUpdateDateEnd}
            />
          </StyledMenu>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(DateToggle);
