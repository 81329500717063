import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@material-ui/core';
import{
  LibraryAddOutlined,
  MoreHorizOutlined,
  InfoOutlined,
} from '@material-ui/icons'
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {clsx} from 'clsx';
import React, {useContext, useEffect, useState} from 'react';
import {CollectionsContext, UsersContext} from '../../context';
import {post} from '../../requests';
import styles from '../../styles';
import CollectionList from '../CollectionList';
import ContextMenu from '../ContextMenu';
import LinkDetails from '../LinkDetails';
import LinkIcon from '../LinkIcon';

const LinkTile = props => {
  const {link, classes, owner, collectionId, isFunctionalGroup} = props;
  const [collections] = useContext(CollectionsContext);
  const [users] = useContext(UsersContext);
  const [publisher, setPublisher] = useState('');
  const [openDetails, setOpenDetails] = useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [collectionsToAdd, setCollectionsToAdd] = useState([]);
  const [openAddToCollection, setOpenAddToCollection] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [clientX, setClientX] = useState(window.innerWidth / 2);
  const [clientY, setClientY] = useState(window.innerHeight / 2);
  const [isSmartCollection, setIsSmartCollection] = useState(true);

  const openContextMenu = Boolean(anchorElement);

  useEffect(() => {
    if (link && users) {
      const foundPublisher = users.find(u => u.id === link.publisher);
      setPublisher(foundPublisher);
    }
  }, [link, users]);

  useEffect(() => {
    if (collectionId && collections) {
      const collection = collections.find(c => c.id === collectionId);
      if (typeof collection !== 'undefined') {
        setIsSmartCollection(collection.smart);
      } else {
        setIsSmartCollection(true);
      }
    }
  }, [collections, collectionId]);

  const getCertificationChip = level => {
    switch (level) {
      case 0:
        return null;
      case 1:
        return <Chip size="small" label="OneStor Certified" className={classes.oneStorCertified} />;
      case 2:
        return (
          <Chip
            size="small"
            label="Functionally Certified"
            className={classes.functionallyCertified}
          />
        );
      case 3:
        return null;
      default:
        return null;
    }
  };

  const getCardMedia = () => {
    if (link.imageUrl !== null) {
      return (
        <CardMedia
          component="img"
          alt="Link Image"
          height="125"
          image={link.imageUrl}
          title=""
          className={classes.img}
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: '5px',
            filter: link.archived ? 'grayscale(100%)' : 'none',
          }}
        />
      );
    }
    return (
      <LinkIcon
        linkType={link.type}
        large
        archived={link.archived}
        style={{
          borderRadius: '5px',
        }}
      />
    );
  };

  const handleDetailsClick = e => {
    e.stopPropagation();
    setOpenDetails(true);
  };

  const handleAddToCollection = e => {
    e.stopPropagation();
    setOpenAddToCollection(true);
  };

  const handleMoreActions = e => {
    e.stopPropagation();
    setClientX(e.clientX);
    setClientY(e.clientY);
    setAnchorElement(e.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorElement(null);
  };

  const handleOpenInNewWindow = () => {
    let url;
    try {
      url = new URL(link.url);
    } catch {
      url = new URL(`http://${link.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${link.id}/view`);
  };

  return (
    <>
      <Grid item>
        <Card
          container
          elevation={0}
          className={clsx({
            [classes.linkCard]: true,
            [classes.linkCardMenuOpen]: !!anchorElement,
          })}
          onClick={handleOpenInNewWindow}
        >
          <CardActionArea
            classes={{
              root: classes.linkCardButton,
              focusHighlight: classes.focusHighlight,
            }}
          >
            <CardContent>
              <Typography noWrap className={classes.linkCardTitle}>
                {link.name}
              </Typography>
              <Typography noWrap className={classes.linkCardPublisherName}>
                {publisher.name}
              </Typography>
            </CardContent>
            {getCardMedia()}
            <div className={classes.linkCardActionIcons}>
              <CardActions style={{padding: '10px 0', width: 147, height: 40}}>
                {getCertificationChip(link.certificationLevel)}
              </CardActions>

              <InfoOutlined
                title="details"
                className={classes.linkCardActionIcon}
                onClick={e => handleDetailsClick(e)}
              />
              <LibraryAddOutlined
                title="add to collection"
                className={classes.linkCardActionIcon}
                onClick={e => handleAddToCollection(e)}
              />
              <MoreHorizOutlined
                title="actions"
                className={classes.linkCardActionIcon}
                onClick={e => handleMoreActions(e)}
              />
            </div>
          </CardActionArea>
        </Card>
      </Grid>

      <Dialog
        style={{paddingRight: 0}}
        fullWidth
        maxWidth="lg"
        open={openDetails}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDetails(false);
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title" style={{padding: 0}}>
          <IconButton
            aria-label="Close"
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              zIndex: 2,
            }}
            onClick={() => setOpenDetails(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: 12}}>
          <LinkDetails link={link} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddToCollection}
        onClose={() => setOpenAddToCollection(false)}
      >
        <DialogTitle id="confirmation-dialog-title">
          <IconButton
            aria-label="Close"
            style={{position: 'absolute', top: 0, right: 0}}
            onClick={() => setOpenAddToCollection(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: 12}}>
          <CollectionList
            link={link}
            setAddCollections={setCollectionsToAdd}
            onClose={() => setOpenAddToCollection(false)}
          />
        </DialogContent>
      </Dialog>
      <Popover
        open={openContextMenu}
        onClose={handleMoreActionsClose}
        anchorEl={anchorElement}
        anchorReference="anchorPosition"
        anchorPosition={{top: clientY, left: clientX}}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <ContextMenu
          isFunctionalGroup={isFunctionalGroup}
          isSmartCollection={isSmartCollection}
          collectionId={collectionId}
          owner={owner}
          rowInfo={link}
          onClose={handleMoreActionsClose}
        />
      </Popover>
    </>
  );
};

export default withStyles(styles)(LinkTile);
