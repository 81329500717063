import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useState} from 'react';
import styles from '../../styles';

const defaultProps = {
  dialogTitle: 'Confirm?',
  dialogText: 'Are you sure?',
  dialogButtonText: 'yes',
};

const ConfirmModal = props => {
  const {onClose, confirm, dialogTitle, dialogText, dialogButtonText, classes} = props;
  const [open, setOpen] = useState(true);

  const handleConfirm = () => {
    onClose();
    setOpen(false);
  };

  const handleCancel = () => {
    confirm(false);
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          // handle close
        }
      }}
    >
      <DialogTitle className={classes.confirmTitle}>{dialogTitle}</DialogTitle>
      <DialogContent className={classes.confirmContent}>
        <DialogContentText className={classes.confirmContentText}>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button className={classes.negativeButton} onClick={handleCancel}>
              cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.positiveButton}
              variant="contained"
              size="medium"
              onClick={handleConfirm}
            >
              {dialogButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = defaultProps;

export default withStyles(styles)(ConfirmModal);
