/* eslint-disable no-unused-vars */

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {MoreHoriz} from '@material-ui/icons';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';

import {LinksContext, PinnedCommentsContext, UsersContext} from '../../context';
import {get, post} from '../../requests';

import styles from '../../styles';
import LinkIcon from '../LinkIcon';

const PinnedComment = props => {
  const {comment, classes, preview} = props;

  const [hover, setHover] = useState(false);
  const [pinnedComments, setPinnedComments] = useContext(PinnedCommentsContext);
  const [users] = useContext(UsersContext);
  const [links] = useContext(LinksContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [link, setLink] = useState(null);
  const [date, setDate] = useState('');
  const [pinnedUser, setPinnedUser] = useState(null);
  const [linkUser, setLinkUser] = useState(null);
  const [truncatedText, setTruncatedText] = useState('');

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  useEffect(() => {
    if (links) {
      const foundLink = links.find(l => l.id === comment.link);
      if (foundLink) {
        setLink(foundLink);
        setDate(moment.utc(comment.pinnedDate).local().format('MMM D, Y'));
      }
    }
  }, [comment, links]);

  useEffect(() => {
    if (users) {
      const foundUser = users.find(u => u.id === comment.pinnedUserId);
      if (foundUser) {
        setPinnedUser(foundUser);
      }
    }
  }, [users, comment]);

  useEffect(() => {
    if (link && users) {
      const foundUser = users.find(u => u.id === link.publisher);
      if (foundUser) {
        setLinkUser(foundUser);
      }
    }
  }, [link, users, comment]);

  useEffect(() => {
    if (comment) {
      setTruncatedText(comment.commentText.slice(0, 150));
    }
  }, [comment]);

  const handleUnpin = () => {
    post(`link/${link.id}/comment/${comment.id}/unpin`).then(() => {
      get('comments/pinned').then(setPinnedComments);
    }); // add snackbar
  };

  return (
    comment &&
    link &&
    linkUser &&
    pinnedUser && (
      <>
        <TableRow
          hover
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={classes.tableRow}
        >
          <TableCell
            style={{
              width: '20px',
              paddingRight: 0,
              paddingLeft: 30,
            }}
          >
            <LinkIcon linkType={link.type} />
          </TableCell>

          {preview && (
            <TableCell style={{paddingLeft: 30}}>
              <Typography noWrap className={classes.linkRowBoldText}>
                {link.name}
              </Typography>
              <Typography
                noWrap
                style={{
                  maxWidth: 500,
                  borderLeft: '2px solid #EEEEF0',
                  paddingLeft: 11,
                  marginTop: 5,
                  textOverflow: 'ellipsis',
                }}
                className={classes.commentRowRegularText}
              >
                {truncatedText}
              </Typography>
            </TableCell>
          )}
          {!preview && (
            <TableCell>
              <Typography className={classes.linkRowBoldText}>{link.name}</Typography>
              <Typography className={classes.commentRowRegularText}>{linkUser.name}</Typography>
            </TableCell>
          )}
          {!preview && (
            <TableCell>
              <Typography className={classes.commentRowRegularText}>
                {date} by {pinnedUser.name}
              </Typography>
            </TableCell>
          )}
          {!preview && (
            <TableCell style={{maxWidth: 320}}>
              <Typography className={classes.commentRowRegularText}>
                {comment.commentText}
              </Typography>
            </TableCell>
          )}
          {!preview && (
            <TableCell
              style={{
                width: '200px',
              }}
            >
              {hover && (
                <Grid item>
                  <Button
                    className={classes.positiveButtonSmall}
                    onClick={e => {
                      e.stopPropagation();
                      handleUnpin();
                    }}
                  >
                    unpin
                  </Button>
                </Grid>
              )}
            </TableCell>
          )}
          <TableCell align="right">
            {preview && hover ? (
              <div style={{paddingRight: 30}}>
                <IconButton
                  className={classes.linkListIcon}
                  aria-label="More"
                  size="small"
                  onClick={handleOpen}
                >
                  <MoreHoriz />
                </IconButton>
              </div>
            ) : null}
          </TableCell>
        </TableRow>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleUnpin}>unpin</MenuItem>
        </Menu>
      </>
    )
  );
};

export default withStyles(styles)(PinnedComment);
