import React from 'react';

import {Grid, Paper, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import styles from '../../../../styles';

const AdminStatsCard = props => {
  const {value, label, classes} = props;

  return (
    <Grid item>
      <Paper className={classes.statsCard}>
        <Typography className={classes.statsValueText}>{value}</Typography>
        <Typography className={classes.statsLabelText}>{label}</Typography>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(AdminStatsCard);
