import React, {useContext} from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import PinnedComment from '../../../components/PinnedComment';
import {PinnedCommentsContext} from '../../../context';
import styles from '../../../styles';

const PinnedCommentsResults = props => {
  const [pinnedComments] = useContext(PinnedCommentsContext);
  const {classes} = props;

  return (
    <section>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{
          paddingBottom: '15px',
        }}
      >
        <Typography className={classes.titleFont}>pinned comments</Typography>
      </Box>
      <Paper
        style={{
          margin: '15px',
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>details</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>comment</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pinnedComments
              ? pinnedComments.map(comment => <PinnedComment key={comment.id} comment={comment} />)
              : null}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
};

export default withStyles(styles)(PinnedCommentsResults);
