import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import LinksResults from '../../components/LinksResults';
import {
  CategoriesContext,
  CommentIdProvider,
  DeleteCommentProvider,
  LinksContext,
} from '../../context';
import {remove} from '../../requests';

const Collection = ({current}) => {
  const [links] = useContext(LinksContext);
  const [categories] = useContext(CategoriesContext);

  const [commentId, setCommentId] = useState([]);
  const [linksToDisplay, setLinksToDisplay] = useState([]);

  const isCollection = typeof current.isFunctionalGroup === 'undefined';

  useEffect(() => {
    if (isCollection) {
      // collectionLinks is an array of link IDs, so we need to
      // take the links from the context that match these IDs.
      if (current.links && current.links.length > 0 && links && links.length > 0) {
        const foundLinks = links.filter(link => current.links.includes(link.id));
        setLinksToDisplay(foundLinks);
      }
    } else {
      if (links && links.length > 0 && categories && categories.length > 0) {
        const categoryLinks = links.filter(link => link.categories.includes(current.id));
        setLinksToDisplay(categoryLinks);
      }
    }
  }, [current, links, categories, isCollection]);

  const [deleteDialog, setDeleteDialog] = useState(false);

  const memoizedDeleteDialog = useMemo(() => [deleteDialog, setDeleteDialog], [deleteDialog]);

  const memoizedCommentId = useMemo(() => [commentId, setCommentId], [commentId]);

  const handleConfirmDelete = () => {
    const [comment, link] = commentId;
    const deleteComment = async () => {
      await remove('link', `${link}/comment/${comment}`);
    };
    deleteComment();
    setDeleteDialog(false);
  };

  const handleCancel = () => {
    setDeleteDialog(false);
  };

  return (
    <section>
      <CommentIdProvider value={memoizedCommentId}>
        <DeleteCommentProvider value={memoizedDeleteDialog}>
          <LinksResults current={current} links={linksToDisplay} />
        </DeleteCommentProvider>
      </CommentIdProvider>
      <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={deleteDialog}>
        <DialogActions>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" size="medium" onClick={handleConfirmDelete}>
                Yes Delete Comment
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCancel}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Collection;
