/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {InfoOutlined, LibraryAddOutlined, MoreHorizOutlined} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {CollectionsContext, UserContext, UsersContext} from '../../context';
import {get, post} from '../../requests';
import {SearchTags} from '../../shared/Constants';
import styles from '../../styles';
import CollectionList from '../CollectionList';
import ContextMenu from '../ContextMenu';
import LinkDetails from '../LinkDetails';
import LinkIcon from '../LinkIcon';

const LinkSearchResult = props => {
  const {link, classes, collectionId, isFunctionalGroup, searchTag} = props;

  const snackbar = useSnackbar();
  const [users] = useContext(UsersContext);
  const [user] = useContext(UserContext);
  const [collections, setCollections] = useContext(CollectionsContext);
  const [linkPublisher, setLinkPublisher] = useState(null);
  const [certLevel, setCertLevel] = useState('');
  const [dateAndUpdater, setDateAndUpdater] = useState('');
  const [hover, setHover] = useState(false);
  const [addCollections, setAddCollections] = useState([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [clientX, setClientX] = useState(window.innerWidth / 2);
  const [clientY, setClientY] = useState(window.innerHeight / 2);
  const [hoverOpenIcon, setHoverOpenIcon] = useState(false);
  const [hoverAddToCollectionIcon, setHoverAddToCollectionIcon] = useState(false);
  const [hoverMoreIcon, setHoverMoreIcon] = useState(false);
  const [isSmartCollection, setIsSmartCollection] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const open = Boolean(anchorElement);
  const allCollectionIds = collections.map(c => c.id);
  const owner = allCollectionIds.includes(collectionId);

  const handleUndo = () => {};
  const navigate = useNavigate();

  const giveUserFeedback = text => {
    snackbar.showMessage(
      `${text}`, // Display Message
      <CloseIcon style={{color: 'white'}} />, // Handler Text
      () => handleUndo() // Handle function
    );
  };

  const addCollectionsAction = () => {
    if (addCollections.length) {
      const collectionIds = addCollections ? addCollections.join(',') : null;
      post(`link/${link.id}/collections?collectionIds=${collectionIds}`)
        .then(response => {
          if (response.id && addCollections.length === 1) {
            const singleCollection = collections.find(c => c.id === addCollections[0]);
            giveUserFeedback(`added ${link.name} to ${singleCollection.name}`);
          } else if (response.id && addCollections.length > 1) {
            giveUserFeedback(`added ${link.name} to ${addCollections.length} collections`);
          }
        })
        .then(() => {
          get('collections').then(setCollections);
        });
    }
    setOpenDialogBox(false);
  };

  function openInNewTab() {
    let url;
    switch (searchTag) {
      case SearchTags.LINKS:
        try {
          url = new URL(link.url);
        } catch {
          url = new URL(`http://${link.url}`);
        }
        window.open(url.toString(), 'URL');
        post(`link/${link.id}/view`);
        break;
      case SearchTags.COLLECTIONS:
        const rootUrl = window.location.origin;
        try {
          url = new URL(`${rootUrl}/collections/${link.id}`);
        } catch {
          url = new URL(rootUrl);
        }
        //window.open(url.toString(), 'URL');
        navigate(`/collections/${link.id}`);
        break;
      default:
        break;
    }
  }

  function openAddCollectionDialog() {
    setOpenDialogBox(true);
  }

  function internalOnMouseEnter(e, l) {
    setHover(true);
  }

  function internalOnMouseLeave(e) {
    setHover(false);
  }

  const handleClick = () => setShowPreview(true);
  const handleClosePreview = () => setShowPreview(false);

  const handleRightClick = e => {
    e.preventDefault();
    setClientX(e.clientX);
    setClientY(e.clientY);
    setAnchorElement(e.currentTarget);
  };

  const handleRightClickClose = () => {
    setAnchorElement(null);
  };

  function handleAction(e, action) {
    switch (action) {
      case 'openInNewTab':
        openInNewTab();
        break;
      case 'addToCollection':
        openAddCollectionDialog();
        break;
      default:
    }
  }

  function handleMouseEnterOpenIcon() {
    setHoverOpenIcon(true);
  }

  function handleMouseLeaveOpenIcon() {
    setHoverOpenIcon(false);
  }

  function handleMouseEnterAddToCollectionIcon() {
    setHoverAddToCollectionIcon(true);
  }

  function handleMouseLeaveAddToCollectionIcon() {
    setHoverAddToCollectionIcon(false);
  }

  function handleMouseEnterMoreIcon() {
    setHoverMoreIcon(true);
  }

  function handleMouseLeaveMoreIcon() {
    setHoverMoreIcon(false);
  }

  useEffect(() => {
    if (users && link) {
      const publisher = users.find(
        u => u.id === (searchTag === SearchTags.COLLECTIONS ? link.owner : link.publisher)
      );
      setLinkPublisher(publisher);
      let updater = publisher;
      if (
        typeof link.updatedBy !== 'undefined' &&
        link.updatedBy !== null &&
        link.updatedBy !== 0
      ) {
        updater = users.find(u => u.id === link?.updatedBy);
      }
      switch (link.certificationLevel) {
        case 1:
          setCertLevel('OneStor Certified');
          break;
        case 2:
          setCertLevel('Functionally Certified');
          break;
        default:
          break;
      }
      const date = moment.utc(link.lastUpdatedAt).local().format('MMM D, Y');
      setDateAndUpdater(`${date} by ${updater.displayName}`);
    }
  }, [link, users]);

  useEffect(() => {
    if (collectionId && collections) {
      const collection = collections.find(c => c.id === collectionId);
      if (collection) {
        setIsSmartCollection(collection.smart);
      } else {
        // Collection couldn't be found in list of user's collections, so mark this link as being
        // in a smart collection so that the user can't be prompted to remove it.
        setIsSmartCollection(true);
      }
    }
  }, [collections, collectionId]);

  return (
    link &&
    linkPublisher && (
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDialogBox}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialogBox();
            }
          }}
        >
          <DialogTitle id="confirmation-dialog-title">
            <IconButton
              aria-label="Close"
              style={{position: 'absolute', top: 0, right: 0}}
              onClick={() => setOpenDialogBox(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{padding: 12}}>
            <CollectionList
              link={link}
              setAddCollections={setAddCollections}
              onClose={() => setOpenDialogBox(false)}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          style={{paddingRight: 0}}
          fullWidth
          maxWidth="lg"
          open={showPreview}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClosePreview();
            }
          }}
        >
          <DialogTitle id="confirmation-dialog-title" style={{padding: 0}}>
            <IconButton
              className={classes.details}
              aria-label="Close"
              style={{
                position: 'absolute',
                top: 6,
                right: 6,
                zIndex: 2,
              }}
              onClick={handleClosePreview}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <LinkDetails link={link} />
          </DialogContent>
        </Dialog>

        <Popover
          open={open}
          onContextMenu={e => {
            e.preventDefault();
          }}
          onClose={handleRightClickClose}
          anchorEl={anchorElement}
          anchorReference="anchorPosition"
          anchorPosition={{top: clientY, left: clientX}}
          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          <ContextMenu
            isFunctionalGroup={isFunctionalGroup}
            isSmartCollection={isSmartCollection}
            collectionId={collectionId}
            owner={owner}
            rowInfo={link}
            onClose={handleRightClickClose}
          />
        </Popover>

        <TableRow
          hover
          onMouseEnter={e => internalOnMouseEnter(e, link)}
          onMouseLeave={e => internalOnMouseLeave(e)}
          onClick={e => {
            e.stopPropagation();
            handleAction(e, 'openInNewTab');
          }}
          onContextMenu={handleRightClick}
          className={classes.tableRow}
        >
          <TableCell
            style={{
              maxWidth: '100px',
              width: '100px',
              paddingLeft: '25px',
              borderBottom: 'none',
            }}
          >
            <LinkIcon linkType={link.type} archived={link.archived} />
          </TableCell>
          <TableCell
            style={{
              paddingLeft: 0,
              borderBottom: 'none',
            }}
          >
            <Typography className={classes.linkRowBoldText}>{link.name}</Typography>
            <Typography className={classes.linkRowRegularText}>
              {linkPublisher.displayName}
            </Typography>
          </TableCell>
          <TableCell className={classes.linkRowRegularText} style={{borderBottom: 'none'}}>
            {certLevel}
          </TableCell>
          <TableCell className={classes.linkRowRegularText} style={{borderBottom: 'none'}}>
            {dateAndUpdater}
          </TableCell>
          <TableCell
            style={{
              maxWidth: '190px',
              width: '190px',
              padding: 0,
              borderBottom: 'none',
              display: searchTag === SearchTags.LINKS ? 'table-cell' : 'none',
            }}
          >
            {hover &&
              (hoverOpenIcon ? (
                <InfoOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                    color: '#009DD9',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    handleClick();
                  }}
                  onMouseLeave={handleMouseLeaveOpenIcon}
                />
              ) : (
                <InfoOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                  }}
                  onMouseEnter={handleMouseEnterOpenIcon}
                />
              ))}
            {hover &&
              (hoverAddToCollectionIcon ? (
                <LibraryAddOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                    color: '#009DD9',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    handleAction(e, 'addToCollection');
                  }}
                  onMouseLeave={handleMouseLeaveAddToCollectionIcon}
                />
              ) : (
                <LibraryAddOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                  }}
                  onMouseEnter={handleMouseEnterAddToCollectionIcon}
                />
              ))}
            {hover &&
              (hoverMoreIcon ? (
                <MoreHorizOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                    color: '#009DD9',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    handleRightClick(e);
                  }}
                  onMouseLeave={handleMouseLeaveMoreIcon}
                />
              ) : (
                <MoreHorizOutlined
                  className={classes.linkListIcon}
                  style={{
                    margin: 10,
                    marginRight: 20,
                  }}
                  onMouseEnter={handleMouseEnterMoreIcon}
                />
              ))}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  );
};

export default withStyles(styles)(LinkSearchResult);
