import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import {UsersContext} from '../../context';
import styles from '../../styles';
import SmallLinkRow from '../SmallLinkRow';

const UserFavouritedLinks = props => {
  const {user, links, classes, isUser} = props;

  const [users] = useContext(UsersContext);

  return (
    <>
      {user && (
        <List className={classes.base} style={{minHeight: 447}}>
          <ListItem>
            <ListItemText
              classes={{
                primary: classes.headingFont,
              }}
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 24,
              }}
            >
              {isUser ? 'favourites' : `${user.displayName}'s favourites`}
            </ListItemText>
            <Button
              className={classes.viewAllButton}
              component={Link}
              to={`/${user.id}/favourites`}
            >
              view all
            </Button>
          </ListItem>
          <Divider />

          <Table
            style={{
              width: '100%',
              overflowX: 'auto',
            }}
          >
            <TableBody>
              {links && links.length > 0 && users ? (
                links
                  .slice(0, 5)
                  .map(link => <SmallLinkRow key={link.id} link={link} classes={classes} />)
              ) : (
                <TableRow>
                  <TableCell
                    style={{
                      height: '323px',
                    }}
                  >
                    <Typography
                      className={classes.linkRowBoldText}
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                      }}
                    >
                      no links favourited yet...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </List>
      )}
    </>
  );
};

export default withStyles(styles)(UserFavouritedLinks);
