import {Box, Divider, Paper, Table, TableBody, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import LinkSearchResult from '../../../components/LinkSearchResult';
import {SearchTags} from '../../../shared/Constants';
import styles from '../../../styles';
import UserSearchResult from '../UserSearchResult';

const SearchResultView = props => {
  const {
    tag,
    viewAll,
    classes,
    searchResults,
    viewAllLinks,
    viewAllUsers,
    viewAllCollections,
    handleViewAllByTag,
  } = props;
  let searchTagResults;

  if (searchResults) {
    if (tag === SearchTags.PEOPLE) {
      searchTagResults = searchResults.users;
    } else if (tag === SearchTags.LINKS) {
      searchTagResults = searchResults.links;
    } else if (tag === SearchTags.COLLECTIONS) {
      searchTagResults = searchResults.collections;
    }
  }

  const handleClick = () => {
    handleViewAllByTag(tag);
  };

  return (
    <section>
      {tag === SearchTags.PEOPLE ? (
        <div style={viewAll || viewAllUsers ? {} : {display: 'none'}}>
          <div style={viewAll ? {} : {display: 'none'}}>
            <Divider className={classes.dividerFull} />
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography className={classes.searchResultsSubHeader}>
                people ({(searchResults && searchResults.users.length) || 0})
              </Typography>
              <Typography className={classes.searchResultsViewAll} onClick={handleClick}>
                view all
              </Typography>
            </Box>
          </div>

          <Paper
            style={{
              padding: '15px',
            }}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              style={
                viewAllUsers
                  ? {
                      overflow: 'hidden',
                      maxHeight: null,
                    }
                  : {
                      overflow: 'hidden',
                      maxHeight: '245px',
                    }
              }
            >
              {searchResults && searchResults.users && searchResults.users.length > 0 ? (
                searchResults.users.map(user => (
                  <Box key={user.id}>
                    <UserSearchResult user={user} key={user.id} />
                  </Box>
                ))
              ) : (
                <Typography className={classes.searchResultsNoResultsText}>No Results</Typography>
              )}
            </Box>
          </Paper>
        </div>
      ) : (
        <div
          style={
            viewAll ||
            (tag === SearchTags.LINKS && viewAllLinks) ||
            (tag === SearchTags.COLLECTIONS && viewAllCollections)
              ? {}
              : {display: 'none'}
          }
        >
          <div style={viewAll ? {} : {display: 'none'}}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              style={{
                paddingTop: '30px',
              }}
            >
              <Typography className={classes.searchResultsSubHeader}>
                {tag.toLowerCase()} ({(searchResults && searchTagResults?.length) || 0})
              </Typography>
              <Typography className={classes.searchResultsViewAll} onClick={handleClick}>
                view all
              </Typography>
            </Box>
          </div>

          <Paper
            style={{
              padding: '15px',
            }}
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            {searchResults && searchTagResults && searchTagResults.length > 0 ? (
              <Table className={classes.Table}>
                <TableBody>
                  {searchTagResults
                    .sort((a, b) => a.certificationLevel - b.certificationLevel)
                    .map(link => (
                      <LinkSearchResult key={link.id} link={link} searchTag={tag} />
                    ))}
                </TableBody>
              </Table>
            ) : (
              <div style={{textAlign: 'center', width: '100%'}}>
                <Typography className={classes.searchResultsNoResultsText}>No Results</Typography>
              </div>
            )}
          </Paper>
        </div>
      )}
    </section>
  );
};

export default withStyles(styles)(SearchResultView);
