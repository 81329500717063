/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Collapse,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LinksContext, UserContext, UsersContext} from '../../../context';
import {post} from '../../../requests';
import styles from '../../../styles';

const CollectionDropdown = ({
  path,
  classes,
  collection,
  name,
  setDetailsLink,
  setOpenDetails,
  clearSearch,
  isNested,
}) => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [users] = useContext(UsersContext);
  const [links] = useContext(LinksContext);

  const [open, setOpen] = useState(false);

  const numberToDisplay = 5;

  const [trimmedItems, setTrimmedItems] = useState(null);

  const openLink = link => {
    let url;
    try {
      url = new URL(link.url);
    } catch {
      url = new URL(`http://${link.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${link.id}/view`);
  };

	//Previously used to show link properties page - may be useful if we want to reintroduce this feature through a button
  const handleDetailsClick = click => {
    setDetailsLink(click);
    setOpenDetails(true);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (links && collection && collection.links.length > 0) {
      const items = collection.links
        .map(cl => {
          const link = links.find(l => l.id === cl);
          if (typeof link === 'undefined' || link === null) {
            return null;
          }
          return link;
        })
        .filter(i => i !== null)
        .slice(0, numberToDisplay + 1);
      // +1 in the above so that the 'show all' works properly.

      setTrimmedItems(items);
    }
  }, [links, collection]);

  const routePath = () => {
    switch (path) {
      case 'favourites':
        return `/${user.id}/favourites`;
      case 'recently-viewed':
        return '/recently-viewed';
      case 'links':
        return `/${user.id}/links`;
      default:
        if (collection.name === name) return `/collections/${collection.id}`;
    }
  };

  function clearAndNavigate() {
    clearSearch();
    navigate(routePath());
  }

  const showMore = (
    <Grow
      key={name}
      in={open}
      style={{transformOrigin: '0 0 0'}}
      {...(open ? {timeout: 1800} : {})}
    >
      <ListItem className={classes.nested} button component={Link} to={routePath()}>
        <ListItemText className={classes.showMoreButton} disableTypography primary="view all" />
      </ListItem>
    </Grow>
  );

  return (
    <List className={classes.dropList} style={{margin: isNested ? 'unset' : ''}}>
      <ListItem onClick={clearAndNavigate} key={name} button className={classes.navText}>
        <ListItemText
          primary={
            <Typography className={isNested ? classes.nestedNavText : classes.navText}>
              {name}
            </Typography>
          }
        />
        <ListItemSecondaryAction style={{paddingRight: 8}}>
          <IconButton edge="end" aria-label="collection links" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {trimmedItems && trimmedItems.length
            ? trimmedItems.map((link, index) => {
                if (index === numberToDisplay) return showMore;
                const timing = 600 + index * 400;
                const linkUser = users ? users.find(u => u.id === link.publisher) : null;
                return (
                  <Grow
                    key={link.id}
                    in={open}
                    style={{transformOrigin: '0 0 0'}}
                    {...(open ? {timeout: timing} : {})}
                  >
                    <ListItem
                      key={link.id}
                      button
                      className={classes.nested}
                      onClick={() => openLink(link)}
                    >
                      <ListItemText
                        className={classes.dropdownLinkName}
                        color="inherit"
                        primary={
                          <Typography className={classes.dropdownLinkName} noWrap>
                            {link.name ? link.name : ''}
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.dropdownUserName} noWrap>
                            {linkUser ? linkUser.name : ''}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grow>
                );
              })
            : null}
        </List>
      </Collapse>
    </List>
  );
};

export default withStyles(styles)(CollectionDropdown);
