/* eslint-disable react/prop-types */
import {Card, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';
import CollectionGridItems from '../../../components/CollectionGridItems';
import {ProfileCollectionsContext} from '../../../context';
import RecentlyViewedLinks from './RecentlyViewedLinks';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0 2px 18px 2px rgba(103,138,154,0.07)',
  },
});

const CollectionGrid = props => {
  // eslint-disable-next-line no-unused-vars
  const [collections] = useContext(ProfileCollectionsContext);
  const {classes} = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
        spacing={4}
      >
        <Grid key={0} item md={12} lg={6} xl={6}>
          <Card className={classes.card}>
            <RecentlyViewedLinks />
          </Card>
        </Grid>
        {collections
          ? collections.map((collection, index) => (
              <Grid key={index.toString()} item md={12} lg={6} xl={6}>
                <Card className={classes.card}>
                  <CollectionGridItems
                    name={collection.name}
                    collectionLinks={collection.links}
                    owner={collection.owner}
                    id={collection.id}
                    collectionFilter={collection.filter}
                    privateLink={collection.private}
                    isSmart={collection.smart}
                    isShared={collection.isShared}
                  />
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CollectionGrid);
