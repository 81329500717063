/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import {List} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import AddCollection from '../../components/AddCollection';
import UserFavouritedLinks from '../../components/UserFavouritedLinks';
import UserPublishedLinks from '../../components/UserPublishedLinks';
import {FeaturedContext, UserContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';
import CollectionGrid from './CollectionGrid';
import FeaturedCarousel from './FeaturedCarousel';

const Home = props => {
  const {classes} = props;
  const [featuredLinks] = useContext(FeaturedContext);
  const [user] = useContext(UserContext);
  const [userFavourites, setUserFavourites] = useState(null);
  const [userUploaded, setUserUploaded] = useState(null);

  const getProfileData = () => {
    if (user) {
      get('links/favourite', user.id).then(setUserFavourites);
      get('links/uploaded', user.id).then(setUserUploaded);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [user]);

  return (
    <section>
      <List>
        <ListItem>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item style={{marginBottom: 15, marginTop: 10}}>
              <Typography align="center" className={classes.titleFont}>
                {`featured (${featuredLinks ? featuredLinks.length : 0})`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <FeaturedCarousel />
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item sm={1} />
            <Grid item sm={10}>
              <Typography
                align="center"
                className={classes.titleFont}
                style={{paddingTop: 0, paddingBottom: '21px'}}
              >
                collections
              </Typography>
            </Grid>
            <Grid item sm={1} style={{paddingTop: 0, paddingBottom: '21px'}}>
              <AddCollection left />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container justifyContent="flex-start" spacing={4}>
            <Grid key={1} item xs={6}>
              <Card className={classes.card}>
                <UserFavouritedLinks isUser user={user} links={userFavourites} />
              </Card>
            </Grid>
            <Grid key={0} item xs={6}>
              <Card className={classes.card}>
                <UserPublishedLinks isUser user={user} links={userUploaded} />
              </Card>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <CollectionGrid />
        </ListItem>
      </List>
    </section>
  );
};

export default withStyles(styles)(Home);
