/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {Link} from 'react-router-dom';

import styles from '../../../styles';

const UserSearchResult = props => {
  const {user, classes} = props;

  return (
    <section>
      {user && user.imageBase64 && (
        <React.Fragment>
          <Link to={`/profile/${user.id}`}>
            <img
              src={user.imageBase64}
              width="160"
              height="160"
              className={classes.searchResultUserImage}
              alt=""
            />
            <Typography className={classes.searchResultUserName}>
              {user && user.displayName && user.displayName}
            </Typography>
          </Link>
        </React.Fragment>
      )}
    </section>
  );
};

export default withStyles(styles)(UserSearchResult);
