/* eslint-disable react/prop-types */
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import AddCollection from '../AddCollection';
import { get, post, remove } from '../../requests';

import styles from '../../styles';

import {LinksContext, UserContext, SubscribedCollectionContext} from '../../context';

import SmallLinkRow from '../SmallLinkRow';

const CollectionGridItems = props => {
  const {
    classes,
    name,
    id,
    collectionLinks,
    privateLink,
    collectionFilter,
    isSmart,
    owner,
    userProfileCollection,
    isShared,
  } = props;

  const [links] = useContext(LinksContext);
  const [user] = useContext(UserContext);
  const [subscribedCollections, setSubscribedCollections] = useContext(SubscribedCollectionContext);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const numberToDisplay = 5;

  const [trimmedItems, setTrimmedItems] = useState(null);

  useEffect(() => {
    setIsSubscribed(checkSubscribed())
  }, [subscribedCollections]);

  const checkSubscribed = () => {
		if(!subscribedCollections) return false;
    if (subscribedCollections.find(subscription => { return subscription.id === id; })) return true;
      return false;
  }

  const updateSubscriptions = () => {
    get(`users/${user.id}/subscriptions`).then((response) => {
      setSubscribedCollections(response);
    });
  }

  const handleSubscribeButton = () => {
    if (!isSubscribed) {
      post(`collection/${id}/subscription`).then(() => updateSubscriptions());
    } else {
      remove(`collection/${id}/subscription`).then(() => updateSubscriptions());
    }
  }

  useEffect(() => {
    if (user && links && collectionLinks) {
      const items = collectionLinks
        .map(cl => {
          const link = links.find(l => l.id === cl);
          if (typeof link === 'undefined' || link === null) {
            return null;
          }
          return (
            <SmallLinkRow key={cl} link={link} classes={classes} owner={owner} collectionId={id} />
          );
        })
        .filter(i => i !== null)
        .slice(0, numberToDisplay);
      setTrimmedItems(items);
    }
  }, [user, links, collectionLinks, classes, id, owner]);

  return (
    <>
      <List className={classes.base} style={{minHeight: 447}}>
        <ListItem>
          <ListItemText
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 24,
              minWidth: '250px',
            }}
          >
            <Typography noWrap={true} className={classes.headingFont}>
              {name}
            </Typography>
          </ListItemText>
          <AddCollection
            edit
            collectionFilter={collectionFilter}
            id={id}
            linkName={name}
            privateLink={privateLink}
            isSmart={isSmart}
            isShared={isShared}
          />

          <Button className={classes.viewAllButton} component={Link} to={`/collections/${id}`}>
            view all
          </Button>
          <Button onClick={handleSubscribeButton} className={classes.viewAllButton}>
                {isSubscribed ? 'unsubscribe' : 'subscribe'}
          </Button>
        </ListItem>
        <Divider />
        <Table>
          <TableBody>
            {trimmedItems && trimmedItems.length > 0 ? (
              trimmedItems
            ) : (
              <>
                <TableRow>
                  <TableCell style={{paddingBottom: 150, paddingTop: 110, border: 'none'}}>
                    <Typography
                      className={classes.linkRowBoldText}
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                      }}
                    >
                      no links added yet...
                    </Typography>
                    <Link to="/browse">
                      <Button
                        className={classes.positiveButton}
                        style={{
                          margin: 'auto',
                          display: 'block',
                          marginTop: '30px',
                        }}
                      >
                        start adding links
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow />
              </>
            )}
          </TableBody>
        </Table>
      </List>
    </>
  );
};

CollectionGridItems.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(CollectionGridItems);
