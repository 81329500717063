import {Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import styles from '../../../styles';

const SnackbarInnerConfirm = props => {
  const {primaryMessage, secondaryMessage, classes} = props;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item style={{paddingLeft: 16}}>
        <div
          style={{
            borderRadius: '20px',
            backgroundColor: 'rgba(51,57,72,0.4)',
            width: '40px',
            height: '40px',
          }}
        >
          <div style={{position: 'relative', left: 8, top: 7}}>
            <CheckOutlinedIcon 
                color="#FFFFFF"
            />
          </div>
        </div>
      </Grid>
      <Grid item>
        <Typography noWrap={true} className={classes.snackbarPrimary}>
          {primaryMessage}
        </Typography>
        <Typography noWrap={true} className={classes.snackbarSecondary}>
          {secondaryMessage}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SnackbarInnerConfirm);
