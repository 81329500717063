/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import List from '@material-ui/core/List';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import ParentComment from './ParentComment';
import PinnedComment from './PinnedComment';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    width: '100%',
    maxWidth: 500,
    backgroundColor: '#FAFAFF',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 600,
    paddingBottom: 0,
    marginBottom: 100,
    overflowX: 'none',
  },
  ul: {
    backgroundColor: 'tomato',
    zIndex: 1,
  },
  pinnedComment: {
    margin: 0,
    padding: 0,
    backgroundColor: '#FAFAFF',
  },
  pinnedChild: {
    backgroundColor: '#FAFAFF',
  },
}));

const CommentList = props => {
  const classes = useStyles();
  const {comments} = props;

  const [pinnedComments, setPinnedComments] = useState([]);
  const [remappedComments, setRemappedComments] = useState([]);

  useEffect(() => {
    if (comments) {
      setPinnedComments(comments.filter(c => c.pinned === true));

      const parentComments = comments.filter(c => c.parentComment === 0);
      parentComments.forEach(parent => {
        /* eslint-disable-next-line no-param-reassign */
        parent.childComments = comments.filter(c => c.parentComment === parent.id);
      });
      setRemappedComments(parentComments);
    }
  }, [comments]);

  return (
    <List className={classes.root}>
      {pinnedComments && pinnedComments.length > 0
        ? pinnedComments.map(comment => <PinnedComment comment={comment} key={comment.id} />)
        : null}

      {remappedComments && remappedComments.length > 0
        ? remappedComments.map(comment => <ParentComment comment={comment} key={comment.id} />)
        : null}
    </List>
  );
};

export default CommentList;
