import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {CollectionsContext, UsersContext} from '../../context';
import {post} from '../../requests';
import styles from '../../styles';
import ContextMenu from '../ContextMenu';
import LinkDetails from '../LinkDetails';
import LinkIcon from '../LinkIcon';

const SmallLinkRow = props => {
  const {link, classes, owner, collectionId} = props;

  const [collections] = useContext(CollectionsContext);

  const [users] = useContext(UsersContext);

  const [linkUpdater, setLinkUpdater] = useState(null);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [updatedByString, setUpdatedByString] = useState('');
  const [moreIconHover, setMoreIconHover] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [clientX, setClientX] = useState(window.innerWidth / 2);
  const [clientY, setClientY] = useState(window.innerHeight / 2);
  const [isSmartCollection, setIsSmartCollection] = useState(false);

  const open = Boolean(anchorElement);
  const [showPreview, setShowPreview] = useState(false);

  const handleRightClick = e => {
    e.preventDefault();
    setClientX(e.clientX);
    setClientY(e.clientY);
    setAnchorElement(e.currentTarget);
  };

  const handleRightClickClose = () => setAnchorElement(null);
  const handleMoreIconMouseEnter = () => setMoreIconHover(true);
  const handleMoreIconMouseLeave = () => setMoreIconHover(false);
  const handleClick = e => {
    e.stopPropagation();
    let url;
    try {
      url = new URL(link.url);
    } catch {
      url = new URL(`http://${link.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${link.id}/view`);
    // setShowPreview(true);
  };
  const handleClosePreview = () => setShowPreview(false);

  useEffect(() => {
    if (collectionId && collections) {
      const collection = collections.find(c => c.id === collectionId);
      if (typeof collection !== 'undefined') {
        setIsSmartCollection(collection.smart);
      } else {
        setIsSmartCollection(true);
      }
    }
  }, [collectionId, collections]);

  useEffect(() => {
    if (link && users) {
      let updater = users.find(u => u.id === link.publisher);
      if (
        typeof link.updatedBy !== 'undefined' &&
        link.updatedBy !== null &&
        link.updatedBy !== 0
      ) {
        updater = users.find(u => u.id === link.updatedBy);
      }
      setLinkUpdater(updater.displayName);
      setUpdatedDate(moment.utc(link.lastUpdatedAt).local().format('MMM D, Y'));
    }
  }, [link, users]);

  useEffect(() => {
    if (linkUpdater && updatedDate) {
      setUpdatedByString(`Updated ${updatedDate} by ${linkUpdater}`);
    }
  }, [linkUpdater, updatedDate]);

  return (
    <>
      <TableRow
        className={classes.tableRow}
        onContextMenu={handleRightClick}
        onClick={(e) => handleClick(e)}
        hover
      >
        <TableCell align="left" style={{paddingLeft: 15, paddingRight: 15, border: 'none'}}>
          <LinkIcon linkType={link.type} archived={link.archived} />
        </TableCell>

        <TableCell
          align="left"
          style={{
            paddingLeft: 0,
            paddingRight: 15,
            border: 'none',
            maxWidth: '200px',
          }}
        >
          <Typography noWrap className={classes.linkRowBoldText} style={{maxWidth: '390px'}}>
            {link.name}
          </Typography>
          <Typography className={classes.linkRowRegularText}>{updatedByString}</Typography>
        </TableCell>

        <TableCell align="right" style={{paddingLeft: 15, paddingRight: 15, border: 'none'}}>
          {moreIconHover ? (
            <MoreHorizOutlinedIcon
              className={classes.linkListIcon}
              color="#009DD9"
              onClick={e => {
                e.stopPropagation();
                handleRightClick(e);
              }}
              onMouseOver={e => {
                e.stopPropagation();
              }}
              onFocus={e => {
                e.stopPropagation();
              }}
              onMouseEnter={handleMoreIconMouseEnter}
              onMouseLeave={handleMoreIconMouseLeave}
            />
          ) : (
            <MoreHorizOutlinedIcon
              className={classes.linkListIcon}
              onClick={handleRightClick}
              onMouseOver={e => {
                e.stopPropagation();
              }}
              onFocus={e => {
                e.stopPropagation();
              }}
              onMouseEnter={handleMoreIconMouseEnter}
              onMouseLeave={handleMoreIconMouseLeave}
            />
          )}
        </TableCell>
      </TableRow>
      <Dialog
        style={{paddingRight: 0}}
        fullWidth
        maxWidth="lg"
        open={showPreview}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosePreview();
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title" style={{padding: 0}}>
          <IconButton
            className={classes.details}
            aria-label="Close"
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              zIndex: 2,
            }}
            onClick={handleClosePreview}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LinkDetails link={link} />
        </DialogContent>
      </Dialog>
      <Popover
        open={open}
        onContextMenu={e => e.preventDefault()}
        onClose={handleRightClickClose}
        anchorEl={anchorElement}
        anchorReference="anchorPosition"
        anchorPosition={{top: clientY, left: clientX}}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <ContextMenu
          owner={owner}
          collectionId={collectionId}
          rowInfo={link}
          onClose={handleRightClickClose}
          isSmartCollection={isSmartCollection}
        />
      </Popover>
    </>
  );
};

export default withStyles(styles)(SmallLinkRow);
