import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarInnerConfirm from './SnackbarInnerConfirm';

export const giveUserFeedback = (snackbar, primaryMessage, secondaryMessage) => {
  snackbar.showMessage(
    <SnackbarInnerConfirm
      primaryMessage={primaryMessage}
      secondaryMessage={secondaryMessage ? secondaryMessage : null}
    />, // Display Message
    <CloseIcon style={{color: '#FFFFFF'}} /> // Optional Action Handler Button
    // () => handleUndo() // Optional Revert Action Handler Function
  );
};
