import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect} from 'react';

import {ArchivedLinksContext, UsersContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';

import LinksResults from '../../components/LinksResults';

const ArchivedLinksPage = () => {
  const [archivedLinks, setArchivedLinks] = useContext(ArchivedLinksContext);
  const [users] = useContext(UsersContext);

  useEffect(() => {
    const fetchData = async () => {
      const links = await get('links/archived');
      if (links) {
        setArchivedLinks(links);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return <LinksResults links={archivedLinks} title="archived links" />;
};

export default withStyles(styles)(ArchivedLinksPage);
