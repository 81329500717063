import {withStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';

import {LinksContext} from '../../context';
import styles from '../../styles';

import LinksResults from '../../components/LinksResults';

const AllLinksPage = () => {
  const [links] = useContext(LinksContext);

  return <LinksResults links={links} title="all links" />;
};

export default withStyles(styles)(AllLinksPage);
