import {msalInstance} from '..';
import {loginRequest} from '../config';

const getActiveAccountToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  if (!activeAccount) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
      return msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: accounts[0],
      });
    } else {
      console.log('No active accounts');
      return null;
    }
  }

  return msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account: activeAccount,
  });
};

export const fetchMany = (...rest) => {
  rest.map(actions => {
    const [request, action] = actions;
    return request.then(action);
  });
};

export const post = async (...payload) => {
  const [group, body] = payload;
  const token = await getActiveAccountToken();
  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json-patch+json',
      Authorization: `Bearer ${token.accessToken}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BUFFET_WEBAPI_BASEURI}/${group}`, options)
    .then(response => {
      if (!response.ok) return response.status;
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => console.error(error));
};

export const get = async (...payload) => {
  const [group, id] = payload;
  const interpolatedId = id ? `/${id}` : '';
  const token = await getActiveAccountToken();
  const options = {
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
  return fetch(`${process.env.REACT_APP_BUFFET_WEBAPI_BASEURI}/${group}${interpolatedId}`, options)
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => console.error(error));
};

export const remove = async (...payload) => {
  const [group, id] = payload;
  const interpolatedId = id ? `/${id}` : '';
  const token = await getActiveAccountToken();
  const options = {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json-patch+json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BUFFET_WEBAPI_BASEURI}/${group}${interpolatedId}`, 
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const put = async (group, body) => {
  const token = await getActiveAccountToken();
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json-patch+json',
      Authorization: `Bearer ${token.accessToken}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await msalInstance
      .acquireTokenSilent({
        scopes: [process.env.REACT_APP_API_SCOPE],
      })
      .then(response => {
        return fetch(`${process.env.REACT_APP_BUFFET_WEBAPI_BASEURI}/${group}`, options);
      });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(`Error: ${error.message}`);
  }
};

export const postImage = async (...payload) => {
  const [group, body] = payload;
  const token = await getActiveAccountToken();
  const options = {
    method: 'post',
    body,
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BUFFET_WEBAPI_BASEURI}/${group}`,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return console.error(error);
  }
};
