/* eslint-disable react/prop-types */
import omit from 'lodash/omit';
import moment from 'moment';
import React from 'react';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const OPEN_UP = 'up';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

const defaultProps = {
  customDatePropStart: null,
  customDatePropEnd: null,
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: true,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: 'right',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,
  openDirection: OPEN_UP,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: true,
  isDayBlocked: () => false,
  isOutsideRange: () => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',

  stateDateWrapper: date => date,
};

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({startDate, endDate}) {
    const {stateDateWrapper} = this.props;
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate),
    });

    const formattedStart = moment(startDate).toISOString();
    const formattedEnd = moment(endDate).toISOString();

    // eslint-disable-next-line react/destructuring-assignment
    this.props.customDatePropStart(`${formattedStart}`);
    this.props.customDatePropEnd(`${formattedEnd}`);
  }

  onFocusChange(focusedInput) {
    this.setState({focusedInput});
  }

  render() {
    const {focusedInput, startDate, endDate} = this.state;

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'stateDateWrapper',
      'customDatePropStart',
      'customDatePropEnd',
    ]);

    return null;
    // <div>
    //     <DateRangePicker
    //         {...props}
    //         onDatesChange={this.onDatesChange}
    //         onFocusChange={this.onFocusChange}
    //         focusedInput={focusedInput}
    //         startDate={startDate}
    //         endDate={endDate}
    //     />
    // </div>
  }
}

DatePicker.defaultProps = defaultProps;

export default DatePicker;
