/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import{
  ImageOutlined,
  CloseOutlined,
  ArchiveOutlined,
  EditOutlined,
  StarBorderOutlined,
  ThumbUpAltOutlined,
  MoreHorizOutlined,
} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import ContextMenu from '../ContextMenu';
import SquareAvatar from '../SquareAvatar';

import styles from '../../styles';

import {
  ArchivedLinksContext,
  CategoriesContext,
  CommentsContext,
  DataSrcsContext,
  LinksContext,
  TagsContext,
  UserContext,
  UsersContext,
} from '../../context';
import CommentList from '../CommentList';

import {get, post, remove} from '../../requests';
import AddLinkForm from '../AddLinkForm';
import ConfirmModal from '../ConfirmModal';

const LinkDetails = props => {
  const {link: linkDetails, classes} = props;
  const location = useLocation();
  const [user, setUser] = useContext(UserContext);
  const [users] = useContext(UsersContext);
  const [links, setLinks] = useContext(LinksContext);
  const [allDataSources] = useContext(DataSrcsContext);
  const [allTags] = useContext(TagsContext);
  const [allCategories] = useContext(CategoriesContext);
  const [archivedLinks, setArchivedLinks] = useContext(ArchivedLinksContext);
  const [comments, setComments] = useContext(CommentsContext);

  const [publisherName, setPublisherName] = useState('');
  const [linkPublisher, setLinkPublisher] = useState(null);
  const [updaterName, setUpdaterName] = useState('');

  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [moreIconHover, setMoreIconHover] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [clientX, setClientX] = useState(window.innerWidth / 2);
  const [clientY, setClientY] = useState(window.innerHeight / 2);
  const [newCommentText, setNewCommentText] = useState('');

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmUnarchive, setShowConfirmUnarchive] = useState(false);
  const [favourited, setFavourited] = useState(false);
  const [liked, setLiked] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [editedLink, setEditedLink] = useState(null);
  const [currentLink, setCurrentLink] = useState(linkDetails);
  const [postNewCommentButtonDisabled, setPostNewCommentButtonDisabled] = useState(true);
  const [path, setPath] = useState('');

  const status = {
    archived: currentLink.archived ?? false,
    userIsAdmin: user.isAdmin,
    userIsAuthor: user.id === currentLink.publisher,
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedLink = await get(`link/${linkDetails.id}`);
      setCurrentLink(fetchedLink);
    };
    fetchData();
  }, [linkDetails]);

  const handleContext = e => {
    e.preventDefault();
    setClientX(e.clientX);
    setClientY(e.clientY);
    setAnchorElement(e.currentTarget);
  };
  const open = Boolean(anchorElement);

  const handleContextClose = () => {
    setAnchorElement(null);
    get('links').then(fetchedlinks => setLinks(fetchedlinks));
  };
  const handleMoreIconMouseEnter = () => setMoreIconHover(true);
  const handleMoreIconMouseLeave = () => setMoreIconHover(false);

  const handleCommentChange = event => {
    setNewCommentText(event.target.value);
  };

  const handleEditLink = () => {
    setOpenEdit(true);
  };

  const getAllLinkComments = () => {
    const linkCommentsString = `link/${currentLink.id}/comments`;
    const fetchComments = async () => {
      const fetchedComments = await get(linkCommentsString);
      setComments(fetchedComments);
    };
    fetchComments();
  };

  const handlePostNewComment = () => {
    setPostNewCommentButtonDisabled(true);
    const groupString = `link/${currentLink.id}/comment`;
    const postData = async () => {
      await post(groupString, {commentText: newCommentText});
      getAllLinkComments();
      setNewCommentText('');
    };
    postData();
  };

  useEffect(() => {
    setPostNewCommentButtonDisabled(newCommentText === '');
  }, [newCommentText]);

  useEffect(() => {
    if (editedLink) {
      const fetchData = async () => {
        const fetchedLinks = await get('links');
        setLinks(fetchedLinks);
        const fetchedArchivedLinks = await get('links/archived');
        setArchivedLinks(fetchedArchivedLinks);
      };
      fetchData();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [editedLink]);

  useEffect(() => {
    const foundUser = users.find(x => x.id === currentLink.publisher);
    setLinkPublisher(foundUser);
    setPublisherName(foundUser.name);
    if (
      typeof currentLink.updatedBy !== 'undefined' &&
      currentLink.updatedBy !== null &&
      currentLink.updatedBy !== 0
    ) {
      const updater = users.find(u => u.id === currentLink.updatedBy);
      setUpdaterName(updater.name);
    } else {
      setUpdaterName(foundUser.name);
    }
  }, [users, currentLink]);

  useEffect(() => {
    const linkCategories = allCategories.filter(
      item => currentLink.categories.indexOf(item.id) !== -1
    );
    setCategories(linkCategories);

    const linkDataSources = allDataSources.filter(
      item => currentLink.dataSources.indexOf(item.id) !== -1
    );
    setDataSources(linkDataSources);

    const linkTags = allTags.filter(item => currentLink.tags.indexOf(item.id) !== -1);
    setTags(linkTags);
  }, [allDataSources, currentLink, allCategories, allTags]);

  useEffect(() => {
    setComments([]);
    const fetchData = async () => {
      const fetchedUser = await post('users');
      setUser(fetchedUser);

      const fetchedUsers = await get('users');
      setAllUsers(fetchedUsers);
    };
    fetchData();
    getAllLinkComments();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const {userFavourites, userLikes} = currentLink;
    const isAFavourite = userFavourites.find(l => l === user.id);
    if (isAFavourite) setFavourited(true);
    const isLiked = userLikes.find(l => l === user.id);
    if (isLiked) setLiked(true);
  }, [user, currentLink]);

  const setUserLiked = () => {
    const value = liked ? 'unlike' : 'like';
    const fetchData = async () => {
      const responseLink = await post(`link/${currentLink.id}/${value}`);
      const {userLikes} = responseLink;
      const isLiked = userLikes.find(l => l === user.id);
      if (isLiked) setLiked(true);
      else setLiked(false);
      const newLinks = await get('links');
      setLinks(newLinks);
    };
    fetchData();
  };

  const setUserFavourited = () => {
    const value = favourited ? 'unfavourite' : 'favourite';
    const fetchData = async () => {
      const responseLink = await post(`link/${currentLink.id}/${value}`);
      const {userFavourites} = responseLink;
      const isFavourited = userFavourites.find(f => f === user.id);
      if (isFavourited) setFavourited(true);
      else setFavourited(false);
      const newLinks = await get('links');
      setLinks(newLinks);
    };
    fetchData();
  };

  const handlePreDelete = () => {
    setShowConfirmDelete(true);
  };

  const handleDelete = () => {
    const fetchData = async () => {
      const deleteLink = await remove('link', currentLink.id);
      setEditedLink(deleteLink);
    };
    fetchData();
  };

  const handlePreUnarchive = () => {
    setShowConfirmUnarchive(true);
  };

  const handleUnarchive = () => {
    const fetchData = async () => {
      const unarchiveLink = post(`/link/${currentLink.id}/unarchive`, {});
      setEditedLink(unarchiveLink);
    };
    fetchData();
  };

  const handleOpenInNewWindow = () => {
    let url;
    try {
      url = new URL(linkDetails.url);
    } catch {
      url = new URL(`http://${linkDetails.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${linkDetails.id}/view`);
  };

  useEffect(() => {
    if (linkPublisher) {
      const userProfileRoute = `/profile/${linkPublisher.id}`;
      const tempPath =
        location === userProfileRoute ? linkPublisher.id : `/profile/${linkPublisher.id}`;
      setPath(tempPath);
    }
  }, [linkPublisher, location]);

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        {/* Link Info Group */}
        <Grid
          item
          xs={7}
          style={{padding: 50, paddingTop: 35, boxShadow: '9px 2px 27px 11px rgba(0,0,0,0.08)'}}
        >
          <Grid container style={{alignItems: 'center'}}>
            <Grid item xs={6}>
              <List dense style={{padding: 0}}>
                <ListItem style={{padding: 0}}>
                  <SquareAvatar user={linkPublisher} />
                  <Typography className={classes.labelFont} component={Link} to={path}>
                    {publisherName}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            {status.archived ? (
              <Grid item xs={6} style={{width: '100%', textAlign: 'right'}}>
                <IconButton
                  onClick={setUserFavourited}
                  style={{marginRight: 10}}
                  color="primary"
                  className={classes.button}
                  aria-label="Add to favourites"
                >
                  {favourited ? <StarBorderOutlined /> : <StarBorderOutlined />}
                </IconButton>
                <Button
                  className={classes.positiveButtonSmall}
                  aria-label="Open in new window"
                  onClick={handleOpenInNewWindow}
                >
                  open link
                </Button>
              </Grid>
            ) : (
              <Grid item xs={6} style={{width: '100%', textAlign: 'right'}}>
                {(status.userIsAdmin || status.userIsAuthor) && (
                  <EditOutlined
                    onClick={handleEditLink}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      marginRight: '15px',
                    }}
                  />
                )}

                {liked ? (
                  <ThumbUpAltOutlined
                    onClick={setUserLiked}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      marginRight: '15px',
                      color: '#009DD9'
                    }}
                  />
                ) : (
                  <ThumbUpAltOutlined
                    onClick={setUserLiked}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      marginRight: '15px',
                    }}
                  />
                )}

                {favourited ? (
                  <StarBorderOutlined
                    onClick={setUserFavourited}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      marginRight: '15px',
                      color: '#000000'
                    }}
                  />
                ) : (
                  <StarBorderOutlined
                    onClick={setUserFavourited}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      marginRight: '15px',
                      color: '#009DD9'
                    }}
                  />
                )}

                {moreIconHover ? (
                  <MoreHorizOutlined
                    onClick={handleContext}
                    onMouseEnter={handleMoreIconMouseEnter}
                    onMouseLeave={handleMoreIconMouseLeave}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                      color: '#009DD9'
                    }}
                  />
                ) : (
                  <MoreHorizOutlined
                    onClick={handleContext}
                    onMouseEnter={handleMoreIconMouseEnter}
                    onMouseLeave={handleMoreIconMouseLeave}
                    className={classes.linkListIcon}
                    style={{
                      boxShadow: '0 2px 18px 2px rgba(103,138,154,0.14)',
                    }}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper style={{marginTop: 20, marginBottom: 20, height: 285}}>
                {currentLink.imageUrl ? (
                  <picture>
                    <img className={classes.previewImg} alt="complex" src={currentLink.imageUrl} />
                  </picture>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: 285,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      backgroundColor: '#F5F6FA',
                    }}
                  >
                    <ImageOutlined style={{padding: '30px 0 10px 0', borderRadius: 5}} />
                  </div>
                )}
              </Paper>
            </Grid>
            {status.archived ? <ArchiveOutlined /> : null}
            <Grid item xs={12}>
              <Typography className={classes.linkNameText}>{currentLink.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              {status.archived ? (
                <Typography className={classes.linkUpdatedText}>
                  Archived on {moment(currentLink.createdAt).format('MMM DD, YYYY')} by{' '}
                  {publisherName}
                </Typography>
              ) : (
                <Typography className={classes.linkUpdatedText}>
                  Updated on {moment(currentLink.createdAt).format('MMM DD, YYYY')} by {updaterName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.paragraphFont} style={{marginTop: '10px'}}>
                {currentLink.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.blackLabel} style={{marginTop: 20}}>
                categories:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {categories.map((item, i) => [
                <Typography
                  key={item.id}
                  className={classes.blueLabel}
                  style={{display: 'inline-block'}}
                >
                  {i > 0 && ', '}
                  {item.name}
                </Typography>,
              ])}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.blackLabel} style={{marginTop: 20}}>
                data sources:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {dataSources.map((item, i) => [
                <Typography
                  key={item.id}
                  className={classes.blueLabel}
                  style={{display: 'inline-block'}}
                >
                  {i > 0 && ', '}
                  {item.name}
                </Typography>,
              ])}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.blackLabel} style={{marginTop: 20}}>
                tags:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {tags.map((item, i) => [
                <Typography
                  key={item.id}
                  className={classes.blueLabel}
                  style={{display: 'inline-block'}}
                >
                  {i > 0 && ', '}
                  {item.name}
                </Typography>,
              ])}
            </Grid>
            {status.archived ? (
              <Grid container style={{paddingTop: 40, justifyContent: 'space-between'}}>
                <div style={{width: '45%'}}>
                  {showConfirmUnarchive && (
                    <ConfirmModal
                      onClose={handleUnarchive}
                      confirm={setShowConfirmUnarchive}
                      dialogTitle="Unarchive Link?"
                      dialogText="By unarchiving this Link. It will be moved back to its original category. Are you sure you want to proceed?"
                      dialogButtonText="yes, Unarchive link"
                    />
                  )}
                  <Button
                    className={classes.positiveButton}
                    style={{
                      width: '100%',
                    }}
                    onClick={handlePreUnarchive}
                  >
                    unarchive
                  </Button>
                </div>
                {(status.userIsAdmin || status.userIsAuthor) && (
                  <div style={{width: '45%'}}>
                    {showConfirmDelete && (
                      <ConfirmModal
                        onClose={handleDelete}
                        confirm={setShowConfirmDelete}
                        dialogTitle="Delete Link?"
                        dialogText="Deleting this Link will remove it from any collection it belongs to. Are you sure you want to proceed?"
                        dialogButtonText="yes, delete link"
                      />
                    )}
                    <Button
                      className={classes.negativeButton}
                      style={{width: '100%'}}
                      onClick={handlePreDelete}
                    >
                      delete
                    </Button>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} style={{paddingTop: 40, justifyContent: 'center'}}>
                <Button
                  className={classes.positiveButton}
                  style={{width: '100%'}}
                  aria-label="Open in new window"
                  onClick={handleOpenInNewWindow}
                >
                  open link
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Commnets Group */}
        <Grid item xs={5} style={{paddingTop: 40, paddingRight: 20, backgroundColor: '#FAFAFF'}}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.headingFont}
                style={{marginBottom: '10px', paddingLeft: '10px'}}
              >
                comments ({comments.length})
              </Typography>
            </Grid>
            <Grid item xs={12} style={{height: '100px', maxHeight: '100px'}}>
              <CommentList comments={comments} />
            </Grid>
            <Grid item xs={12}>
              <Paper
                style={{
                  position: 'absolute',
                  bottom: 42,
                  boxShadow: '0 2px 0px 0px #FAFAFF',
                  boxSizing: 'border-box',
                  border: '2px solid #EEEEF0',
                  backgroundColor: '#FAFAFF',
                }}
              >
                <InputBase
                  type="text"
                  value={newCommentText}
                  style={{
                    position: 'relative',
                    width: 375,
                    height: 47,
                    backgroundColor: '#FFFFFF',
                    color: '#8B8E98',
                    fontSize: '14px',
                    lineHeight: '16px',
                    fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
                    borderRadius: '5px',
                  }}
                  placeholder="   Add comment..."
                  inputProps={{'aria-label': '', style: {paddingLeft: '10px'}}}
                  onChange={e => handleCommentChange(e)}
                />
                <Button
                  onClick={handlePostNewComment}
                  disabled={postNewCommentButtonDisabled}
                  style={{
                    position: 'relative',
                    right: 0,
                    height: 47,
                    textTransform: 'none',
                    color: '#0266B2',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '16px',
                    backgroundColor: '#FFFFFF',
                    fontFamily: ['Gotham Narrow A', 'Gotham Narrow B', 'GothamNarrowBook'],
                  }}
                >
                  post
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Edit Link  Group */}
      <Dialog
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        open={openEdit}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            // handle close here
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <IconButton
            aria-label="Close"
            style={{position: 'absolute', top: 0, right: 0}}
            onClick={() => setOpenEdit(false)}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <section>
            <AddLinkForm edit link={currentLink} onClose={() => setOpenEdit(false)} />
          </section>
        </DialogContent>
      </Dialog>

      {/* Context Menu Group */}
      <Popover
        open={open}
        onContextMenu={e => e.preventDefault()}
        onClose={handleContextClose}
        anchorEl={anchorElement}
        anchorReference="anchorPosition"
        anchorPosition={{top: clientY, left: clientX}}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <ContextMenu rowInfo={currentLink} onClose={handleContextClose} alreadyPreview />
      </Popover>
    </>
  );
};

export default withStyles(styles)(LinkDetails);
