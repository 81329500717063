/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, {useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LinkDetails from '../components/LinkDetails';
import {
  CategoriesContext,
  CollectionsContext,
  CollectionSharesContext,
  LinksContext,
  ProfileCollectionsContext,
  UserContext,
} from '../context';
import Admin from '../pages/Admin';
import AllLinksPage from '../pages/AllLinksPage';
import ArchivedLinksPage from '../pages/ArchivedLinksPage';
import Categories from '../pages/Categories';
import Collection from '../pages/Collection';
import Home from '../pages/Home';
import InactiveLinksPage from '../pages/InactiveLinksPage';
import LinkPage from '../pages/LinkPage';
import PinnedCommentsPage from '../pages/PinnedCommentsPage';
import Profile from '../pages/Profile';
import RecentlyViewedPage from '../pages/RecentlyViewedPage';
import SearchResults from '../pages/SearchResults';
import UpgradeRequestPage from '../pages/UpgradeRequestPage';
import UserFavouritesList from '../pages/UserFavouritesList';
import UserLinksList from '../pages/UserLinksList';

const RoutesComponent = () => {
  const [categories] = useContext(CategoriesContext);
  const [collections] = useContext(CollectionsContext);
  const [profileCollections] = useContext(ProfileCollectionsContext);
  const [links] = useContext(LinksContext);
  const [user] = useContext(UserContext);

  const GuardedRoute = ({user, children}) => {
    if (user.userLevel !== 'Admin') {
      return <Navigate to="/home" replace />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/browse" element={<Categories />} />
      <Route path="/profile/:user" element={<Profile />} />
      <Route path="/:user/links" element={<UserLinksList />} />
      <Route path="/:user/favourites" element={<UserFavouritesList />} />
      <Route path="/search" element={<SearchResults />} />
      <Route
        path="/admin"
        element={
          <GuardedRoute user={user}>
            <Admin />
          </GuardedRoute>
        }
      />
      <Route path="/link/:linkid" element={<LinkPage />} />
      <Route path="/requests" element={<UpgradeRequestPage />} />
      <Route path="/recently-viewed" element={<RecentlyViewedPage />} />
      <Route path="/links/inactive" element={<InactiveLinksPage />} />
      <Route path="/categories/all" element={<AllLinksPage />} />
      <Route path="/links/:linkid" element={<LinkDetails />} />
      <Route path="/categories/archive" element={<ArchivedLinksPage />} />
      <Route path="/pinned-comments" element={<PinnedCommentsPage />} />
      {collections && collections.length
        ? collections.map((collection, index) => {
            return (
              <Route
                key={index.toString()}
                path={`/collections/${collection.id}`}
                element={<Collection current={collection} />}
              />
            );
          })
        : null}
      {categories && categories.length
        ? categories.map((functionalGroup, index) => {
            const newFunctionalGroup = <Collection current={functionalGroup} />;
            return (
              <Route
                key={index.toString()}
                path={`/categories/${functionalGroup.name
                  .replace(/[^\w\s-]/g, '')
                  .replace(/\s+/g, '-')
                  .trim()
                  .toLowerCase()}`}
                element={newFunctionalGroup}
              />
            );
          })
        : null}
      {profileCollections && profileCollections.length
        ? profileCollections.map((collection, index) => {
            const newCollection = <Collection current={collection} />;
            return (
              <Route
                key={collection.id}
                path={`/collections/${collection.id}`}
                element={newCollection}
              />
            );
          })
        : null}
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default RoutesComponent;
