import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect} from 'react';

import {LinksContext, RecentlyViewedLinksContext, UsersContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';

import LinksResults from '../../components/LinksResults';

const RecentlyViewedPage = () => {
  const [recentlyViewedLinks, setRecentlyViewedLinks] = useContext(RecentlyViewedLinksContext);
  const [users] = useContext(UsersContext);
  const [links] = useContext(LinksContext);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedLinks = await get('links/recently-viewed');
      if (fetchedLinks) {
        setRecentlyViewedLinks(fetchedLinks);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, links]);

  return <LinksResults links={recentlyViewedLinks} current={{name: "recently viewed links", isFunctionalGroup: false}} />;
};

export default withStyles(styles)(RecentlyViewedPage);
