import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import styles from '../../../styles';

import Comment from '../Comment';

const PinnedComment = props => {
  const {comment} = props;

  return <Comment comment={comment} type="pinned" />;
};

export default withStyles(styles)(PinnedComment);
