import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useState} from 'react';
import styles from '../../../styles';
import CollectionDropdown from '../CollectionDropdown';

const CollectionDropdownContainer = ({
  classes,
  collections,
  name,
  setDetailsLink,
  setOpenDetails,
  clearSearch,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  function clearAndNavigate() {
    if (collections) {
      setOpen(!open);
    } else {
      clearSearch();
    }
  }

  return (
    <List className={classes.dropList}>
      <ListItem onClick={clearAndNavigate} key={name} button className={classes.navText}>
        <ListItemText primary={<Typography className={classes.navText}>{name}</Typography>} />
        <ListItemSecondaryAction style={{paddingRight: 8}}>
          <IconButton edge="end" aria-label="collection links" onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {collections && collections.length
            ? collections.map(collection => {
                return (
                  <div key={collection.id}>
                    <ListItem className={classes.nested}>
                      <CollectionDropdown
                        key={collection.id}
                        collection={collection}
                        name={collection.name}
                        clearSearch={clearSearch}
                        setDetailsLink={setDetailsLink}
                        setOpenDetails={setOpenDetails}
                        isNested={true}
                      />
                    </ListItem>
                  </div>
                );
              })
            : null}
        </List>
      </Collapse>
    </List>
  );
};

export default withStyles(styles)(CollectionDropdownContainer);
