/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';
import LinkTile from '../../../components/LinkTile';
import {FeaturedContext} from '../../../context';
import styles from '../../../styles';
import {AutoRotatingCarousel} from './Carousel';

const FeaturedCarousel = props => {
  const {classes} = props;
  let breakpoints = [1100, 1535, 1700];

  function chunkArray(unformattedArray, chunkSize) {
    const unformattedLength = unformattedArray.length;
    const reformattedArray = [];

    for (let index = 0; index < unformattedLength; index += chunkSize) {
      const newChunk = unformattedArray.slice(index, index + chunkSize);
      reformattedArray.push(newChunk);
    }

    return reformattedArray;
  }

  const [chunk, setChunk] = useState(4);
  const [featuredLinks] = useContext(FeaturedContext);
  const [linksArray, setLinksArray] = useState(
    featuredLinks && featuredLinks.length ? chunkArray(featuredLinks, chunk) : null
  );
  const numberOfFeatured = featuredLinks && featuredLinks.length ? featuredLinks.length : null;
  const [contextWidth, setContentWidth] = useState(window.innerWidth);

  function debounce(fn, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setContentWidth(window.innerWidth);
    }, 400);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    if (contextWidth < breakpoints[0]) setChunk(1);
    if (contextWidth >= breakpoints[0] && contextWidth < breakpoints[1]) setChunk(2);
    if (contextWidth >= breakpoints[1] && contextWidth < breakpoints[2]) setChunk(3);
    if (contextWidth > breakpoints[2]) setChunk(4);
  }, [contextWidth]);

  useEffect(() => {
    setLinksArray(featuredLinks && featuredLinks.length ? chunkArray(featuredLinks, chunk) : null);
  }, [chunk]);

  useEffect(() => {
    setLinksArray(featuredLinks && featuredLinks.length ? chunkArray(featuredLinks, chunk) : null);
  }, [featuredLinks]);
  /*
  Array Chunking function that accepts an array of n items and a number that creates
  new arrays of that numbers size. ex:
  */

  return (
    <div style={{margin: '0 auto'}}>
      <AutoRotatingCarousel
        open
        landscape={false}
        autoplay={false}
        label="Get started"
        numberOfFeatured={numberOfFeatured}
      >
        {linksArray ? (
          linksArray.map(panelList => (
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              style={{
                padding: '24px',
              }}
              key={panelList}
            >
              {panelList.map(link => (
                <LinkTile key={link.id} link={link} classes={classes} />
              ))}
            </Grid>
          ))
        ) : (
          <></>
        )}
      </AutoRotatingCarousel>
    </div>
  );
};

export default withStyles(styles)(FeaturedCarousel);
