import {useEffect, useContext, useState} from 'react';
import {UserContext} from '../context';

export const usePreferences = () => {
  const [user] = useContext(UserContext);
  const [userPreferences, setUserPreferences] = useState({view: 'tile'});

  function getUserPreferences() {
    const storageKey = `${user.name}_${user.id}`;
    setUserPreferences(JSON.parse(window.localStorage.getItem(storageKey)));
  }

  function setPreference(view) {
    if (view === 'list' || view === 'tile') {
      setUserPreferences({view: view});
    }
  }

  function updatePreferences() {
    const storageKey = `${user.name}_${user.id}`;
    window.localStorage.setItem(storageKey, JSON.stringify(userPreferences));
  }

  useEffect(() => {
    getUserPreferences();
  }, []);

  useEffect(() => {
    updatePreferences();
  }, [userPreferences]);

  return [userPreferences, setPreference];
};
