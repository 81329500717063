import {Divider, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';

import {get} from '../../../requests';
import styles from '../../../styles';

import AdminStatsCard from './AdminStatsCard';

const AdminStatistics = props => {
  const {classes} = props;

  const defaultStats = {
    allTimeActiveLinks: '-',
    commentsAdded: '-',
    linksUploaded: '-',
    linksViewed: '-',
    uniqueUsers: '-',
  };

  const [range, setRange] = useState('today');
  const [stats, setStats] = useState(defaultStats);

  const rangeOptions = [
    {
      value: 'today',
      text: 'today',
    },
    {
      value: 'thisweek',
      text: 'this week',
    },
    {
      value: 'thismonth',
      text: 'this month',
    },
    {
      value: 'thisyear',
      text: 'this year',
    },
    {
      value: 'alltime',
      text: 'all time',
    },
  ];

  /* eslint-disable-next-line no-unused-vars */
  const handleRangeChange = (e, {value}) => {
    if (value) {
      setRange(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const updatedStats = await get(`stats/${range}`);
      setStats(updatedStats);
    };
    fetchData();
  }, [range]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          paddingRight: '25px',
        }}
      >
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography
            align="center"
            className={classes.titleFont}
            style={{
              marginBottom: 29,
            }}
          >
            onestor statistics
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            alignSelf: 'right',
          }}
        >
          <Dropdown
            className={classes.formControl}
            selection
            direction="right"
            options={rangeOptions}
            value={range}
            onChange={handleRangeChange}
          />
        </Grid>
      </Grid>

      <Divider className={classes.dividerFull} />

      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        style={{
          marginBottom: 30,
        }}
      >
        <AdminStatsCard value={stats.uniqueUsers} label="Unique Users" />
        <AdminStatsCard value={stats.linksUploaded} label="Links Uploaded" />
        <AdminStatsCard value={stats.linksViewed} label="Views" />
        <AdminStatsCard value={stats.commentsAdded} label="Comments" />
        <AdminStatsCard value={stats.allTimeActiveLinks} label="Total Active Links" />
      </Grid>
    </>
  );
};

export default withStyles(styles)(AdminStatistics);
