/* eslint-disable no-unused-vars */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext, useEffect, useState} from 'react';

import {LinksContext, UpgradeRequestsContext, UserContext} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';

import UpgradeRequest from './UpgradeRequest';

const UpgradeRequestPage = props => {
  const {classes} = props;
  const [currentUser] = useContext(UserContext);
  const [links] = useContext(LinksContext);
  const [upgradeRequests, setUpgradeRequests] = useContext(UpgradeRequestsContext);
  const [filteredUpgradeRequests, setFilteredUpgradeRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const newUpgradeRequests = await get('upgrade-requests');
      if (newUpgradeRequests) {
        // Filter out everything but those that are in the 'requested' state.
        // TODO: only request 'requested' requests after CO-514 is done
        setUpgradeRequests(newUpgradeRequests.filter(ur => ur.status === 'Requested'));
      }
    };
    fetchData();
  }, [setUpgradeRequests]);

  useEffect(() => {
    if (currentUser.isFunctionalAdmin) {
      const newUpgradeRequests = upgradeRequests.filter(ur => {
        if (ur.requestedLevel === 'OneStor') {
          return false;
        }
        const link = links.find(l => l.id === ur.link);
        if (link.certificationLevel === 1) {
          return false;
        }
        return true;
      });
      setFilteredUpgradeRequests(newUpgradeRequests);
    } else {
      setFilteredUpgradeRequests(upgradeRequests);
    }
  }, [currentUser, upgradeRequests, links]);

  return (
    <section>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{
          paddingBottom: '15px',
        }}
      >
        <Typography className={classes.titleFont}>manage requests</Typography>
      </Box>

      {/* <Box display="flex" flexDirection="row" justifyContent="center">
        <Typography>manage requests filter</Typography>
      </Box> */}

      <Paper
        style={{
          margin: '15px',
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>details</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeaderText}>request</Typography>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUpgradeRequests && filteredUpgradeRequests.length > 0
              ? filteredUpgradeRequests.map(ur => (
                  <UpgradeRequest key={ur.id} upgradeRequest={ur} />
                ))
              : null}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
};

export default withStyles(styles)(UpgradeRequestPage);
