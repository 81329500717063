/* eslint-disable no-unused-vars */

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {CertLevelsContext, ExpiringContext, UsersContext} from '../../context';
import {get, post} from '../../requests';
import {giveUserFeedback} from '../giveUserFeedback/giveUserFeedback';

import styles from '../../styles';
import ConfirmModal from '../ConfirmModal';
import LinkDetails from '../LinkDetails';
import LinkIcon from '../LinkIcon';

const InactiveLink = props => {
  const snackbar = useSnackbar();
  const {link, classes, preview} = props;

  const [hover, setHover] = useState(false);
  const [users] = useContext(UsersContext);
  const [certLevels] = useContext(CertLevelsContext);
  const [expiringLinks, setExpiringLinks] = useContext(ExpiringContext);
  const [linkUpdaterName, setLinkUpdaterName] = useState('');
  const [date, setDate] = useState('');
  const [level, setLevel] = useState('');
  const [inactivity, setInactivity] = useState('');
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleClick = () => {
    if (!showConfirmArchiveModal) {
      setShowPreview(true);
    }
  };
  const handleClosePreview = () => setShowPreview(false);

  const handlePreArchive = e => {
    e.stopPropagation();
    setShowConfirmArchiveModal(true);
  };

  const refreshLinks = () => get('links/expiring').then(setExpiringLinks);

  const handleArchive = () => {
    post(`/link/${link.id}/archive`, {})
      .then(response => {
        giveUserFeedback(snackbar, `archived ${response.name}`);
      })
      .then(refreshLinks);
    setShowConfirmArchiveModal(false);
  };

  useEffect(() => {
    if (users && users.length > 0 && certLevels && certLevels.length > 0 && link) {
      const certLevel = certLevels.find(x => x.id === link.certificationLevel).name;
      setLevel(certLevel);

      let linkUpdater = users.find(u => u.id === link.publisher);
      if (
        typeof link.updatedBy !== 'undefined' &&
        link.updatedBy !== null &&
        link.updatedBy !== 0
      ) {
        linkUpdater = users.find(u => u.id === link.updatedBy);
      }
      setLinkUpdaterName(linkUpdater.name);

      const formattedDate = moment.utc(link.lastUpdatedAt).local().format('MMM D, Y');
      setDate(formattedDate);

      const now = moment();
      const lastActivity = moment.utc(link.lastInteractionTime).local();
      const difference = moment.duration(now.diff(lastActivity));
      const duration =
        difference.asDays() > 30
          ? `${Math.floor(difference.asMonths())} months`
          : `${Math.floor(difference.asDays())} days`;
      setInactivity(duration);
    }
  }, [users, certLevels, link]);

  return (
    link && (
      <>
        <Dialog
          fullWidth
          maxWidth="md"
          open={showPreview}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClosePreview();
            }
          }}
        >
          <DialogTitle id="confirmation-dialog-title">
            <IconButton
              aria-label="Close"
              style={{position: 'absolute', top: 0, right: 0}}
              onClick={handleClosePreview}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <LinkDetails link={link} />
          </DialogContent>
        </Dialog>

        <TableRow
          hover
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          className={classes.tableRow}
        >
          {!preview && (
            <TableCell>
              <Typography className={classes.linkRowRegularText}>{inactivity}</Typography>
            </TableCell>
          )}
          <TableCell
            style={{
              width: '20px',
              paddingRight: 0,
            }}
          >
            <LinkIcon linkType={link.type} />
          </TableCell>
          <TableCell>
            <Typography className={classes.linkRowBoldText}>{link.name}</Typography>
            <Typography className={classes.linkRowRegularText}>
              {preview ? `${date} by ${linkUpdaterName}` : linkUpdaterName}
            </Typography>
          </TableCell>

          {!preview && (
            <TableCell>
              <Typography className={classes.linkRowRegularText}>{level}</Typography>
            </TableCell>
          )}

          {!preview && (
            <TableCell>
              <Typography className={classes.linkRowRegularText}>
                {date} by {linkUpdaterName}
              </Typography>
            </TableCell>
          )}

          {preview && (
            <TableCell align="right">
              <Typography className={classes.linkRowRegularText}>{inactivity}</Typography>
            </TableCell>
          )}

          {!preview && (
            <TableCell
              style={{
                width: '200px',
              }}
            >
              {hover && (
                <Grid item>
                  {showConfirmArchiveModal && (
                    <ConfirmModal
                      onClose={handleArchive}
                      confirm={setShowConfirmArchiveModal}
                      dialogTitle="Archive Link?"
                      dialogText="An archived Link will stay in its collections but will be hidden in search and browse results. Are you sure you want to proceed?"
                      dialogButtonText="Yes, Archive Link"
                    />
                  )}
                  <Button
                    className={classes.positiveButtonSmall}
                    onClick={e => {
                      e.stopPropagation();
                      handlePreArchive(e);
                    }}
                  >
                    archive
                  </Button>
                </Grid>
              )}
            </TableCell>
          )}
        </TableRow>
      </>
    )
  );
};

export default withStyles(styles)(InactiveLink);
