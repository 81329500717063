import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {
  CategoriesContext,
  LinksContext,
  UpgradeRequestsContext,
  UsersContext,
} from '../../../../context';
import styles from '../../../../styles';

const Activity = props => {
  const {activity, classes} = props;

  const [categories] = useContext(CategoriesContext);
  const [users] = useContext(UsersContext);
  const [links] = useContext(LinksContext);
  const [upgradeRequests] = useContext(UpgradeRequestsContext);
  const [time, setTime] = useState('');
  const [actor, setActor] = useState('');
  const [action, setAction] = useState('');
  const [target, setTarget] = useState('');
  const [extra, setExtra] = useState('');

  const activityActor = users && users.length ? users.find(u => u.id === activity.actor) : null;

  useEffect(() => {
    const handleActivityAction = (action, extra) => {
      const link = links && links.length ? links.find(l => l.id === activity.targetObject) : null;
      if (link) {
        setAction(action);
        setTarget(link.name);
        if (extra) setExtra(extra);
      }
    };

    if (activity) {
      setTime(moment.utc(activity.createdAt).local().format('h:mmA'));
    }
    if (users && links && categories && upgradeRequests) {
      setActor(activityActor.displayName);

      switch (activity.activityType) {
        case 'LinkEdited': {
          handleActivityAction('made an edit to');
          break;
        }
        case 'LinkArchived': {
          handleActivityAction('archived', 'due to inactivity');
          break;
        }
        case 'LinkAddedToCollection': {
          handleActivityAction('added', 'to their collection');
          break;
        }
        case 'LinkCommentedOn': {
          handleActivityAction('commented on');
          break;
        }
        case 'LinkCommentPinned': {
          handleActivityAction('pinned a comment on');
          break;
        }
        case 'LinkCommentDeleted': {
          handleActivityAction('deleted a comment on');
          break;
        }
        case 'CommentDeleted': {
          handleActivityAction('deleted your comment on');
          break;
        }
        case 'LinkCertificationLevelIncreased': {
          handleActivityAction('increased the certification level of');
          break;
        }
        case 'LinkCertificationLevelDecreased': {
          handleActivityAction('decreased the certification level of');
          break;
        }
        case 'UpgradeRequestApproved':
          {
            const upgradeRequest =
              upgradeRequests && upgradeRequests.length
                ? upgradeRequests.find(u => u.id === activity.targetObject)
                : null;
            if (upgradeRequest) {
              const link =
                links && links.length ? links.find(l => l.id === upgradeRequest.link) : null;
              if (link) {
                setAction('approved your upgrade request for');
                setTarget(link.name);
              }
            }
          }
          break;
        case 'UpgradeRequestDenied': {
          const upgradeRequest =
            upgradeRequests && upgradeRequests.length
              ? upgradeRequests.find(u => u.id === activity.targetObject)
              : null;
          if (upgradeRequest) {
            const link =
              links && links.length ? links.find(l => l.id === upgradeRequest.link) : null;
            if (link) {
              setAction('denied your upgrade request for');
              setTarget(link.name);
            }
          }
          break;
        }
        case 'LinkLiked': {
          handleActivityAction('liked');
          break;
        }
        case 'LinkFavourited': {
          handleActivityAction('favourited');
          break;
        }
        case 'LinkDeleted': {
          handleActivityAction('deleted');
          break;
        }
        case 'CategoryCreated': {
          const category =
            categories && categories.length
              ? categories.find(c => c.id === activity.targetObject)
              : null;
          // The category may have been deleted since it was created...
          if (typeof category !== 'undefined' && category !== null) {
            setAction('created the category');
            setTarget(category.name);
          }
          break;
        }
        case 'LinkFeatured': {
          handleActivityAction('featured');
          break;
        }
        case 'LinkUnfeatured': {
          handleActivityAction('unfeatured');
          break;
        }
        default:
          break;
      }
    }
  }, [users, links, categories, upgradeRequests, activity, activityActor]);

  return (
    action !== '' && (
      <>
        <div
          className={classes.tableRow}
          style={{
            padding: '15px 30px',
          }}
        >
          <Typography className={classes.lightGreyLabel}>{time}</Typography>
          <Typography className={classes.paragraphBlackFont}>
            <span style={{fontWeight: 'bold'}}>{actor}</span> {action}{' '}
            <span style={{fontWeight: 'bold'}}>{target}</span> {extra}
          </Typography>
        </div>
      </>
    )
  );
};

export default withStyles(styles)(Activity);
