import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import AppShell from './AppShell.jsx';
import { loginRequest, msalConfig } from './config';
import Unauthorized from './pages/Unauthorized/Unauthorized';

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={Unauthorized}
      loadingComponent={() => <p>Signing in</p>}
    >
      <AppShell />
    </MsalAuthenticationTemplate>
  </MsalProvider>,
  document.getElementById('root')
);
