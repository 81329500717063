/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import {withStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'material-ui-snackbar-provider';
import React, {useContext, useEffect, useState} from 'react';

import {
  CategoriesContext,
  CollectionsContext,
  FeaturedContext,
  LinksContext,
  UserContext,
} from '../../../context';
import {get, post, remove} from '../../../requests';
import styles from '../../../styles';
import AddLinkForm from '../../AddLinkForm';
import CollectionList from '../../CollectionList';
import LinkDetails from '../../LinkDetails';
import {giveUserFeedback} from '../../giveUserFeedback/giveUserFeedback';
import UpgradeRequestForm from './UpgradeRequestForm';

const ContextMenuActions = props => {
  const snackbar = useSnackbar();
  const [collections, setCollections] = useContext(CollectionsContext);
  const [categories, setCategories] = useContext(CategoriesContext);
  const [user, setUser] = useContext(UserContext);
  const [featuredLinks, setFeaturedLinks] = useContext(FeaturedContext);
  const [links, setLinks] = useContext(LinksContext);
  const [buttonText, setButtonText] = useState('close');
  const [actionButton, setActionButton] = useState(false);
  const {link, linkAction, onClose, collectionId} = props;
  const [view, setView] = useState(null);

  const openUrl = () => {
    let url;
    try {
      url = new URL(link.url);
    } catch {
      url = new URL(`http://${link.url}`);
    }
    window.open(url.toString(), 'URL');
    post(`link/${link.id}/view`);
    onClose();
  };

  const copyAction = () => {
    navigator.clipboard.writeText(link.url);
    giveUserFeedback(snackbar, `${link.name} copied to clipboard`);
    onClose();
  };

  const copyOnestorAction = () => {
    navigator.clipboard.writeText(window.location.host + `/link/${link.id}`);
    giveUserFeedback(snackbar, `${link.name} onestor link copied to clipboard`);
    onClose();
  };

  const refreshLinks = () => get('links').then(setLinks);
  const refreshUser = () => post('users').then(setUser);

  const removeLinkAction = () => {
    const group = `collection/${collectionId}`;
    const id = `link/${link.id}`;
    remove(group, id)
      .then(response => {
        get('collections').then(setCollections);
        return response;
      })
      .then(response => {
        get('links').then(setLinks);
        return response;
      })
      .then(response => {
        if (response.id) {
          giveUserFeedback(snackbar, `Removed ${link.name} from ${response.name}`);
        }
      });
    onClose();
  };

  const deleteLinkAction = () => {
    const group = 'link';
    remove(group, link.id)
      .then(response => {
        if (response.id) giveUserFeedback(snackbar, `deleted link: ${link.name}`);
      })
      .then(() => {
        get('links').then(setLinks);
      })
      .then(() => {
        get('tags?type=Category').then(setCategories);
      });
    onClose();
  };

  const favouriteAction = () => {
    post(`link/${link.id}/favourite`, {})
      .then(response => {
        if (response.id) giveUserFeedback(snackbar, `${link.name} favourited`);
      })
      .then(refreshUser)
      .then(refreshLinks);
    onClose();
  };

  const addToFeaturedLinks = () => {
    post(`link/${link.id}/feature`, {})
      .then(response => {
        if (response.id) giveUserFeedback(snackbar, `${link.name} added to featured list`);
      })
      .then(() => get('links/featured').then(setFeaturedLinks));
    onClose();
  };

  const removeFromFeaturedLinks = () => {
    post(`link/${link.id}/unfeature`, {})
      .then(response => {
        if (response.id) giveUserFeedback(snackbar, `${link.name} removed from featured list`);
      })
      .then(() => get('links/featured').then(setFeaturedLinks));
    onClose();
  };

  const unfavouriteAction = () => {
    post(`link/${link.id}/unfavourite`, {})
      .then(response => {
        if (response.id) giveUserFeedback(snackbar, `${link.name} unfavourited`);
      })
      .then(refreshUser)
      .then(refreshLinks);
    onClose();
  };

  const linkDetails = <LinkDetails link={link} />;

  const editLink = <AddLinkForm edit link={link} onClose={onClose} />;

  const upgradeRequest = <UpgradeRequestForm link={link} onClose={onClose} />;

  const collectionsList = <CollectionList linkId={link.id} onClose={onClose} link={link} />;

  const setActionView = () => {
    switch (linkAction) {
      case 'preview':
        return linkDetails;
      case 'openInNewTab':
        openUrl();
        break;
      case 'edit':
        return editLink;
      case 'addToCollection':
        return collectionsList;
      case 'addToFeaturedLinks':
        addToFeaturedLinks();
        break;
      case 'removeFromFeaturedLinks':
        removeFromFeaturedLinks();
        break;
      case 'unfavourite':
        unfavouriteAction();
        break;
      case 'favourite':
        favouriteAction();
        break;
      case 'copyLink':
        copyAction();
        break;
      case 'delete':
        deleteLinkAction();
        break;
      case 'remove':
        removeLinkAction();
        break;
      case 'editLevel':
        return upgradeRequest;
      case 'copyOnestor':
        return copyOnestorAction();
      default:
        onClose();
    }
    return null;
  };

  const actionCheck = async () => {
    if (linkAction === 'addToCollection') await setActionButton(true);
  };

  useEffect(() => {
    actionCheck();
    setView(setActionView());
  }, [linkAction]);

  return <section style={{overflow: 'hidden'}}>{view}</section>;
};

export default withStyles(styles)(ContextMenuActions);
