/* eslint-disable no-nested-ternary */
import React, {useContext, useEffect, useState} from 'react';

import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import {usePreferences} from '../../usePreferences';

import {SubscribedCollectionContext, UserContext} from '../../context';
import {get, post, remove} from '../../requests';
import {SearchTags} from '../../shared/Constants';
import styles from '../../styles';
import AddCollection from '../AddCollection';
import FormModal from '../FormModal';
import LinkSearchResult from '../LinkSearchResult';
import LinkTile from '../LinkTile';

import {LinkOutlined, ListOutlined, ViewModuleOutlined} from '@material-ui/icons';

const LinksResults = props => {
  const {current, links, classes, title} = props;
  const isCollection =
    typeof current !== 'undefined' && typeof current.isFunctionalGroup === 'undefined';

  const [userPreferences, setPreference] = usePreferences();

  const [user] = useContext(UserContext);

  const preferenceExists = () => {
    let exists = false;
    if (userPreferences === null) {
      return exists;
    }
    switch (userPreferences.view) {
      case 'tile':
        exists = true;
        break;
      case 'list':
        exists = false;
        break;
      default:
        exists = false;
        break;
    }
    return exists;
  };

  const preference = preferenceExists();

  const [linkTileMode, setLinkTileMode] = useState(preference);
  const [openPostLinkForm, setOpenPostLinkForm] = useState(false);
  const [subscribedCollections, setSubscribedCollections] = useContext(SubscribedCollectionContext);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleOpenPostLink = () => {
    setOpenPostLinkForm(true);
  };
  const handleClosePostLink = () => {
    setOpenPostLinkForm(false);
  };

  useEffect(() => {
    setIsSubscribed(checkSubscribed());
  }, [subscribedCollections]);

  const checkSubscribed = () => {
    if (!subscribedCollections) return false;
    if (
      subscribedCollections.find(subscription => {
        return subscription.id === current?.id;
      })
    )
      return true;
    return false;
  };

  const updateSubscriptions = () => {
    get(`users/${user.id}/subscriptions`).then(response => {
      setSubscribedCollections(response);
    });
  };

  const handleSubscribeButton = () => {
    if (!isSubscribed) {
      post(`collection/${current?.id}/subscription`).then(() => updateSubscriptions());
    } else {
      remove(`collection/${current?.id}/subscription`).then(() => updateSubscriptions());
    }
  };

  useEffect(() => {
    if (userPreferences && userPreferences.view === 'list') setLinkTileMode(false);
    if (userPreferences && userPreferences.view === 'tile') setLinkTileMode(true);
  }, [userPreferences, setPreference]);

  function getIconDisplay() {
    if (linkTileMode) {
      return (
        <ListOutlined
          className={classes.linkListIcon}
          style={{
            margin: '15px',
            boxShadow: '0 2px 18px 2px rgba(103,138,154,0.07)',
          }}
          onClick={() => setPreference('list')}
        />
      );
    } else {
      return (
        <ViewModuleOutlined
          className={classes.linkListIcon}
          style={{
            margin: '15px',
            boxShadow: '0 2px 18px 2px rgba(103,138,154,0.07)',
          }}
          onClick={() => setPreference('tile')}
        />
      );
    }
  }

  function getMainDisplay() {
    if (!links || links.length === 0) {
      return (
        <Paper
          className={classes.generalPaper}
          style={{
            margin: '15px',
            paddingTop: '90px',
            paddingBottom: '90px',
          }}
        >
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <LinkOutlined className={classes.linkListIcon} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.headingFont}>no links yet</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.paragraphFont}>
                Be the first to add a link to this category.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleOpenPostLink}
                className={classes.positiveButtonLarge}
                style={{
                  marginTop: '30px',
                  marginRight: '15px',
                }}
              >
                post link
              </Button>
            </Grid>
          </Grid>
        </Paper>
      );
    } else if (linkTileMode) {
      return (
        <>
          <Grid
            container
            display="flex"
            spacing={2}
            style={{
              margin: '15px',
            }}
          >
            {links
              ?.sort((a, b) => a.certificationLevel - b.certificationLevel)
              .map(link => (
                <LinkTile key={link.id} link={link} />
              ))}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Paper
            className={classes.generalPaper}
            style={{
              margin: '15px',
            }}
          >
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell
                    style={{
                      paddingLeft: 0,
                    }}
                  >
                    <Typography className={classes.tableHeaderText}>name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableHeaderText}>level</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableHeaderText}>updated</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {links
                  ?.sort((a, b) => a.certificationLevel - b.certificationLevel)
                  .map(link => (
                    <LinkSearchResult
                      key={link.id}
                      link={link}
                      collectionId={current.id}
                      searchTag={SearchTags.LINKS}
                    />
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      );
    }
  }

  return (
    <>
      {openPostLinkForm && (
        <FormModal open={openPostLinkForm} onClose={handleClosePostLink} confirm />
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{
          paddingTop: '15px',
          paddingBottom: '15px',
          alignItems: 'center',
        }}
      >
        <Typography className={classes.titleFont}>{current?.name ?? title}</Typography>
        {isCollection && (
          <div
            style={{display: 'flex', justifyContent: 'flex-end'}}
            className={classes.editCollectionSection}
          >
            <AddCollection
              edit
              collectionFilter={current?.filter}
              id={current?.id}
              linkName={current?.name}
              privateLink={current?.private}
              isSmart={current?.smart}
              isShared={current?.isshared}
            />
            <Button onClick={handleSubscribeButton} className={classes.viewAllButton}>
              {isSubscribed ? 'unsubscribe' : 'subscribe'}
            </Button>
            {getIconDisplay()}
          </div>
        )}
      </Box>

      {getMainDisplay()}
    </>
  );
};

export default withStyles(styles)(LinksResults);
