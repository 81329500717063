/* eslint-disable react/prop-types */
import {Button, Divider, List, ListItem, ListItemText, Table, TableBody} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import PinnedComment from '../../../components/PinnedComment';
import {PinnedCommentsContext} from '../../../context';
import styles from '../../../styles';

const PinnedCommentsContainer = props => {
  const {classes} = props;

  const [pinnedComments] = useContext(PinnedCommentsContext);
  const listSize = 5;

  return (
    <>
      <List className={classes.base}>
        <ListItem>
          <ListItemText classes={{primary: classes.headingFont}}> pinned comments </ListItemText>
          <Button component={Link} to="/pinned-comments" className={classes.viewAllButton}>
            view all
          </Button>
        </ListItem>

        <Divider />

        {pinnedComments
          ? pinnedComments.slice(0, listSize).map(comment => (
              <Table key={comment.id} style={{width: '100%', overflowX: 'auto'}}>
                <TableBody>
                  <PinnedComment preview comment={comment} />
                </TableBody>
              </Table>
            ))
          : null}
      </List>
    </>
  );
};

export default withStyles(styles)(PinnedCommentsContainer);
