import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context';
import ContextMenuActions from './ContextMenuActions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflowY: 'none',
  },
  ul: {
    padding: 0,
    margin: 0,
  },
  details: {
    backgroundColor: '#FAFAFF',
  },
  listItem: {
    fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
    color: '#646975',
    fontSize: '14px',
    margin: '5px',
    marginTop: '7px',
  },
}));

export default function ContextMenu(props) {
  const [user] = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [linkAction, setLinkAction] = useState('');
  const {rowInfo: link, onClose, isSmartCollection, collectionId, owner, alreadyPreview} = props;
  const {publisher, id: linkId} = link;
  const {userLevel, id: userId, linkFavourites} = user;
  const classes = useStyles();
  const isAdmin = userLevel === 'Admin';
  const isPublisher = userId === publisher;
  const [details, setDetails] = useState(false);
  const [sizeType, setSizeType] = useState('md');

  const [isLinkFavourited, setIsLinkFavourited] = useState(linkFavourites.includes(linkId));

  useEffect(() => {
    const userFavourites = user.linkFavourites;
    const newFavouriteStatus = userFavourites.includes(linkId);
    setIsLinkFavourited(newFavouriteStatus);
  }, [user, linkId]);

  const handleAction = (e, action) => {
    if (action === 'preview') setDetails(true);
    setLinkAction(action);
    setOpen(true);
  };

  const handelSetCollection = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    if (details) setSizeType('lg');
    if (linkAction === 'addToCollection') setSizeType('sm');
  }, [linkAction, details]);

  return (
    <div className={classes.root}>
      <List dense component="ul" className={classes.ul}>
        <ListItem disabled={alreadyPreview} button onClick={e => handleAction(e, 'preview')}>
          <Typography className={classes.listItem}>preview</Typography>
        </ListItem>
        <ListItem button onClick={e => handleAction(e, 'openInNewTab')}>
          <Typography className={classes.listItem}>open in new tab</Typography>
        </ListItem>
        {isPublisher || isAdmin ? (
          <ListItem button onClick={e => handleAction(e, 'edit')}>
            <Typography className={classes.listItem}>edit</Typography>
          </ListItem>
        ) : null}
        <ListItem button onClick={e => handleAction(e, 'addToCollection')}>
          <Typography className={classes.listItem}>add to collection</Typography>
        </ListItem>
        {isAdmin &&
          (link.featured ? (
            <ListItem button onClick={e => handleAction(e, 'removeFromFeaturedLinks')}>
              <Typography className={classes.listItem}>remove from featured links</Typography>
            </ListItem>
          ) : (
            <ListItem button onClick={e => handleAction(e, 'addToFeaturedLinks')}>
              <Typography className={classes.listItem}>add to featured links</Typography>
            </ListItem>
          ))}
        {isLinkFavourited ? (
          <ListItem button onClick={e => handleAction(e, 'unfavourite')}>
            <Typography className={classes.listItem}>unfavourite</Typography>
          </ListItem>
        ) : (
          <ListItem button onClick={e => handleAction(e, 'favourite')}>
            <Typography className={classes.listItem}>favourite</Typography>
          </ListItem>
        )}
        <ListItem button onClick={e => handleAction(e, 'copyLink')}>
          <Typography className={classes.listItem}>copy link</Typography>
        </ListItem>
        <ListItem button onClick={e => handleAction(e, 'copyOnestor')}>
          <Typography className={classes.listItem}>copy preview link</Typography>
        </ListItem>
        {isAdmin || isPublisher ? (
          <ListItem button onClick={e => handleAction(e, 'delete')}>
            <Typography className={classes.listItem}>delete</Typography>
          </ListItem>
        ) : null}
        {owner && !isSmartCollection ? (
          <ListItem button onClick={e => handleAction(e, 'remove')}>
            <Typography className={classes.listItem}>remove from collection</Typography>
          </ListItem>
        ) : null}
        {/* {
          isAdmin ? (
            <ListItem
              disabled
              button
              onClick={e => handleAction(e, 'archive')}
            >
              <ListItemText primary="archive" />
            </ListItem>
          ) : null
        } */}
        {
          <ListItem button onClick={e => handleAction(e, 'editLevel')}>
            <Typography className={classes.listItem}>edit level</Typography>
          </ListItem>
        }
      </List>
      <Dialog
        style={details ? {paddingRight: 0} : null}
        fullWidth
        maxWidth={sizeType}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handelSetCollection();
          }
        }}
      >
        {details ? null : (
          <DialogTitle id="confirmation-dialog-title">
            <IconButton
              className={details ? classes.details : null}
              aria-label="Close"
              style={{
                position: 'absolute',
                top: 6,
                right: 6,
                zIndex: 2,
              }}
              onClick={handelSetCollection}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent style={details ? {padding: 12} : null}>
          {details ? (
            <IconButton
              className={classes.details}
              aria-label="Close"
              style={{
                position: 'absolute',
                top: 6,
                right: 6,
                zIndex: 2,
              }}
              onClick={handelSetCollection}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <ContextMenuActions
            link={link}
            linkAction={linkAction}
            onClose={handelSetCollection}
            collectionId={collectionId}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
