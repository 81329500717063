/* eslint-disable react/prop-types */
import {Button, Divider, List, ListItem, ListItemText, Table, TableBody} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import InactiveLink from '../../../components/InactiveLink';
import {ExpiringContext} from '../../../context';
import styles from '../../../styles';

const InactiveLinksContainer = props => {
  const {classes} = props;

  const [expLinks] = useContext(ExpiringContext);
  const listSize = 5;

  return (
    <>
      <List className={classes.base}>
        <ListItem>
          <ListItemText classes={{primary: classes.headingFont}}> inactive links </ListItemText>
          <Button component={Link} to="/links/inactive" className={classes.viewAllButton}>
            view all
          </Button>
        </ListItem>

        <Divider />

        {expLinks
          ? expLinks.slice(0, listSize).map(link => (
              <Table key={link.id} style={{width: '100%', overflowX: 'auto'}}>
                <TableBody>
                  <InactiveLink preview key={link.id} link={link} />
                </TableBody>
              </Table>
            ))
          : null}
      </List>
    </>
  );
};

InactiveLinksContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InactiveLinksContainer);
