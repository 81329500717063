/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import ConfirmModal from '../../../components/ConfirmModal';
import ImageUpload from '../../../components/ImageUpload';
import {CategoriesContext, FormStateProvier, NewImageProvider} from '../../../context';
import {get, post, postImage, put, remove} from '../../../requests';
import styles from '../../../styles';

const AddCategory = props => {
  const {open, setOpen, category, file, setFile, imageUrl, isFunctionalGroup, classes, edit} =
    props;

  const [formState, setFormState] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(open);
  const [updatedCategoryName, setUpdatedCategoryName] = useState(
    category && category.name ? category.name : ''
  );
  const [editedCategory, setEditedCategory] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [categories, setCategories] = useContext(CategoriesContext);

  const memoizedFormState = useMemo(() => [formState, setFormState], [formState]);
  const memoizedNewImage = useMemo(() => [newImage, setNewImage], [newImage]);

  const formData = new FormData();

  const handlePreCancel = () => {
    setShowConfirmCancelModal(true);
  };

  const handlePreDelete = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleDelete = () => {
    const fetchData = async () => {
      const deletedCategory = await remove('category', category.id);
      setEditedCategory(deletedCategory);
    };
    fetchData();
  };

  const handleSave = () => {
    setSaveButtonDisabled(true);
    const fetchData = async () => {
      let updatedCategory = {};
      if (edit) {
        updatedCategory = await put(`category/${category.id}`, {
          name: updatedCategoryName,
          type: category.type,
          isFunctionalGroup: category.isFunctionalGroup,
        });
      } else {
        updatedCategory = await post('tag', {
          name: updatedCategoryName,
          type: 2,
          isFunctionalGroup,
        });
      }
      setEditedCategory(updatedCategory);
    };
    fetchData();
  };

  const handleCancel = () => {
    setOpen(false);
    setSaveButtonDisabled(false);
  };

  const setImage = () => {
    formData.append('file', file);
    setNewImage(formData);
  };

  useEffect(() => {
    if (editedCategory) {
      const fetchData = async () => {
        if (newImage) {
          await postImage(`category/${editedCategory.id}/image`, newImage);
        }
        const fetchedCategories = await get('tags?type=Category');
        setCategories(fetchedCategories);
        setShowAddCategoryModal(false);
        setOpen(false);
        setSaveButtonDisabled(false);
      };
      fetchData();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [editedCategory]);

  useEffect(() => {
    if (file) {
      setImage();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [file]);

  useEffect(() => {
    setSaveButtonDisabled(!((file || imageUrl) && updatedCategoryName !== ''));
  }, [file, updatedCategoryName, imageUrl]);

  return (
    <>
      {showConfirmCancelModal && (
        <ConfirmModal
          onClose={handleCancel}
          setOpen={setShowConfirmCancelModal}
          confirm={setShowConfirmCancelModal}
          dialogTitle="cancel changes?"
          dialogText="do you wish to cancel your changes?"
          dialogButtonText="yes"
        />
      )}
      <Dialog
        maxWidth="sm"
        open={showAddCategoryModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCancel();
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <IconButton
            aria-label="Close"
            style={{position: 'absolute', top: 0, right: 0}}
            onClick={handlePreCancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormStateProvier value={memoizedFormState}>
            <NewImageProvider value={memoizedNewImage}>
              <Grid container justifyContent="center">
                <Grid item xs={12} style={{padding: 20, paddingTop: 0}}>
                  {edit ? (
                    <Typography
                      className={classes.headingFont}
                      style={{textAlign: 'center', marginBottom: 20}}
                    >
                      edit category
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.headingFont}
                      style={{textAlign: 'center', marginBottom: 20}}
                    >
                      add category
                    </Typography>
                  )}

                  <Typography className={classes.labelFont}>Name</Typography>
                  <TextField
                    style={{marginBottom: 20}}
                    inputProps={{
                      style: {
                        fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
                      },
                    }}
                    id="outlined-full-width"
                    placeholder="Category title"
                    value={updatedCategoryName}
                    fullWidth
                    onChange={event => setUpdatedCategoryName(event.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Typography className={classes.labelFont}>Background Image</Typography>
                  <Grid container justifyContent="center">
                    <ImageUpload
                      file={file}
                      setFile={setFile}
                      imageUrl={imageUrl}
                      style={{textAlign: 'center'}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </NewImageProvider>
          </FormStateProvier>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="center" style={{paddingBottom: 40}}>
            {edit && (
              <Grid item>
                {showConfirmDeleteModal && (
                  <ConfirmModal
                    onClose={handleDelete}
                    confirm={setShowConfirmDeleteModal}
                    dialogTitle="Delete Category?"
                    dialogText="By deleting this category, you will be deleting it from all Links associated with it. Are you sure you want to proceed?"
                    dialogButtonText="yes, delete category"
                  />
                )}
                <Button className={classes.negativeButton} onClick={handlePreDelete}>
                  delete
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                className={classes.positiveButton}
                onClick={handleSave}
                disabled={saveButtonDisabled}
                style={
                  !edit
                    ? {
                        width: '400px',
                      }
                    : {}
                }
              >
                save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(AddCategory);
