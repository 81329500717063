/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'material-ui-snackbar-provider';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext, CollectionsContext, SharedCollectionsContext, SubscribedCollectionContext } from '../../context';
import { get, post, put, remove } from '../../requests';
import styles from '../../styles';
import ConfirmModal from '../ConfirmModal';
import CustomizedExpansionPanel from './CustomizedExpansionPanel';
import { giveUserFeedback } from '../giveUserFeedback/giveUserFeedback';
import MultipleSelectChip from './MultipleSelectChip/MultipleSelectChip';

const AddCollection = props => {
  const snackbar = useSnackbar();
  const { edit, classes, privateLink, linkName, id, collectionFilter, isSmart, isShared } = props;
  const [user] = useContext(UserContext);
  const [collections, setCollections] = useContext(CollectionsContext);
  const [sharedCollections, setSharedCollections] = useContext(SharedCollectionsContext);
  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [name, setName] = useState(edit ? linkName : '');
  const [togglePrivate, setTogglePrivate] = useState(
    edit ? { checked: privateLink } : { checked: false }
  );
  const [toggleShared, setToggleShared] = useState(edit ? { checked: isShared } : { checked: false });
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [sharedToUsers, setSharedToUsers] = useState([]);
  const initialState = {
    nameIncludes: true,
    name: '',
    type: null,
    level: 0,
    publisher: null,
    upload: null,
    update: null,
    categories: [],
    dataSources: [],
    tags: [],
  };

  const [filter, setFilter] = useState(
    edit
      ? {
        ...collectionFilter,
        categories: collectionFilter.categories || [],
        dataSources: collectionFilter.dataSources || [],
        tags: collectionFilter.tags || [],
      }
      : initialState
  );

  const [formState, setFormState] = useState({
    name,
    private: togglePrivate.checked,
    isShared: toggleShared.checked,
    sharedToUsers: [],
    links: [],
  });

  const openAddCollection = () => {
    setOpen(true);
  };

  const closeAddCollection = () => {
    setConfirmModal(false);
    handleReset();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    handleReset();
  };

  const handleDelete = () => {
    remove(`collection`, id)
      .then(response => {
        if (response.id) {
          snackbar.showMessage(
            `Deleted collection : ${response.name}`, // Display Message
            <CloseIcon style={{ color: 'white' }} />, // Handler Text
            () => handleUndo() // Handle function
          );
        }
      })
      .then(() => get('collections').then(setCollections));
    setOpen(false);
    setShowConfirmDeleteModal(false);
  };

  const handleUndo = () => {
    return;
  };

  const handleSave = async () => {
    let errorOccurred = false;
    try {
      let response;
      if (toggleShared.checked && sharedToUsers.length === 0) {
        errorOccurred = true;
        giveUserFeedback(snackbar, 'Share Collection: Please select users');
        return;
      }

      if (edit) {
        response = await put(`collection/${id}`, formState);
      } else {
        response = await post('collections', formState);
      }

      if (response.id) {
        get(`users/${user.id}/shared-collections`).then(response => {
          setSharedCollections(response);
        });

        const action = edit
          ? `Edited collection: ${response.name}`
          : `Collection created: ${response.name}`;
        giveUserFeedback(snackbar, action);
      }
      const collections = await get('collections');
      setCollections(collections);
    } catch (error) {
      // Handle error if any of the promises above fails
      console.error('An error occurred:', error);
    } finally {
      if (!errorOccurred) {
				get('collections').then(setCollections);
        setOpen(false);
      }
    }
  };

  const handleFilter = field => event => {
    setFilter({ ...filter, [field]: event.target.value });
  };

  const handleDateFilter = (action, field, value) => {
    if (value === null) {
      setFilter({ ...filter, date: null });
    }
    setFilter({ ...filter, date: { ...filter.date, [action]: { [field]: value } } });
  };
  const handleCustomDateFilter = (action, start, end) => {
    setFilter({ ...filter, date: { ...filter.date, [action]: { start, end } } });
  };

  const handleDropdownFilter =
    field =>
      (event, { value }) => {
        if (value === '') value = 0; //to deal with type filter changing to 1 upon empty string
        setFilter({ ...filter, [field]: value });
      };

  const handleCheckedPrivate = value => event => {
    setTogglePrivate({ ...togglePrivate, [value]: event.target.checked });
  };

  const handleCheckedShared = value => event => {
    setToggleShared({ ...toggleShared, [value]: event.target.checked });
    if (!event.target.checked) {
      setSharedToUsers([]);
    }
  };

  const handleReset = () => {
    if (edit) {
      setName(linkName);
      setTogglePrivate({ checked: privateLink });
      setToggleShared({ checked: isShared });
    } else {
      setName(' ');
      setTogglePrivate({ checked: false });
      setToggleShared({ checked: false })
      setFilter(initialState);
    }
  };

  const clearFilters = () => {
    setFilter({ ...initialState, type: 0 });
  };

  useEffect(() => {
    setFormState({
      name,
      private: togglePrivate.checked,
      isShared: toggleShared.checked,
      sharedToUsers: sharedToUsers.map(e => e.id),
    });
    if (JSON.stringify(filter) !== JSON.stringify(initialState)) {
      setFormState({
        name,
        private: togglePrivate.checked,
        isShared: toggleShared.checked,
        filter,
        sharedToUsers: sharedToUsers.map(e => e.id),
      });
    }
  }, [name, togglePrivate, toggleShared, confirmModal, filter, sharedToUsers]);

  useEffect(() => {
    if (edit) {
      setName(linkName);
      setTogglePrivate({ checked: privateLink });
      setToggleShared({ checked: isShared });
      setFilter({
        ...collectionFilter,
        date: { update: collectionFilter.update, upload: collectionFilter.upload },
        categories: collectionFilter.categories || [],
        dataSources: collectionFilter.dataSources || [],
        tags: collectionFilter.tags || [],
      });
    }
  }, [linkName, collectionFilter, privateLink, isShared]);

  return (
    <>
      {edit ? (
        <Button onClick={openAddCollection} className={classes.editCollectionButton}>
          edit
        </Button>
      ) : (
        <Button onClick={openAddCollection} className={classes.addCollectionButton}>
          add
        </Button>
      )}
      <Dialog
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            // Handlere here
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography
            style={{ display: 'inline-block', marginRight: 20 }}
            className={classes.titleFont}
          >
            {edit ? 'edit' : 'add'} collection
          </Typography>
          <Divider style={{ marginTop: 20, marginBottom: 0 }} className={classes.dividerFull} />
          <IconButton
            aria-label="Close"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleCancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ overflowY: 'unset' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.labelFont}>Collection Name *</Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                id="outlined-full-width"
                style={{ margin: 0 }}
                placeholder="Collection title"
                value={name}
                fullWidth
                error={name === ''}
                type="text"
                onChange={event => setName(event.target.value.replace(/^\s+/g, ''))}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography className={classes.labelFont}>Private Collection</Typography>
                  <Typography className={classes.paragraphFont}>
                    Private collections can only be viewed by you and will be hidden from the
                    public.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Switch
                  style={{ alignSelf: 'flex-end' }}
                  checked={togglePrivate.checked}
                  onChange={handleCheckedPrivate('checked')}
                  value={togglePrivate.checked}
                  classes={{
                    root: classes.toggle,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography className={classes.labelFont}>Share Collection</Typography>
                  <Typography className={classes.paragraphFont}>
                    Collections can be shared to specific users.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Switch
                  style={{ alignSelf: 'flex-end' }}
                  checked={toggleShared.checked}
                  onChange={handleCheckedShared('checked')}
                  value={toggleShared.checked}
                  classes={{
                    root: classes.toggle,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                />
              </Grid>
            </Grid>
            {!toggleShared.checked ? null : (
              <Grid item xs={12}>
                <MultipleSelectChip
                  collectionId={id}
                  sharedToUsers={sharedToUsers}
                  setSharedToUsers={setSharedToUsers}
                />
              </Grid>
            )}
            {!isSmart && edit ? null : (
              <>
                <Grid item xs={10} style={{ marginBottom: 30 }}>
                  <Typography className={classes.labelFont}> Automatic Curation </Typography>
                  <Typography className={classes.paragraphFont}>
                    Adding filters creates a collection that automatically curates links to your
                    preferences. You will not be able to add or remove links manually to this type
                    of collection.
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ marginBottom: 30 }}>
                  <Button onClick={clearFilters}>
                    <Typography className={classes.blueLink}>clear all filters</Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          <CustomizedExpansionPanel
            filterUpdate={handleFilter}
            dropdownUpdate={handleDropdownFilter}
            filter={filter}
            isSmart={isSmart}
            edit={edit}
            dateUpdate={handleDateFilter}
            customDateUpdate={handleCustomDateFilter}
          />
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              {edit ? (
                <Grid item>
                  {showConfirmDeleteModal && (
                    <ConfirmModal
                      onClose={handleDelete}
                      confirm={setShowConfirmDeleteModal}
                      dialogTitle="Delete Collection?"
                      dialogText="Are you sure you want to proceed?"
                      dialogButtonText="yes, delete collection"
                    />
                  )}
                  <Button
                    onClick={() => setShowConfirmDeleteModal(true)}
                    className={classes.negativeButton}
                  >
                    Delete
                  </Button>
                </Grid>
              ) : (
                <Button onClick={handleCancel} className={classes.negativeButton}>
                  Cancel
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={!!(name === ' ' || name === '')}
                onClick={handleSave}
                className={classes.positiveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {confirmModal ? (
          <ConfirmModal onClose={closeAddCollection} confirm={setConfirmModal} />
        ) : null}
      </Dialog>
    </>
  );
};

export default withStyles(styles)(AddCollection);
