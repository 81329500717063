import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import{
  NotificationsNoneOutlined,
  NotificationImportantOutlined,
} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../context';
import {get, post} from '../../../requests';
import styles from '../../../styles';
import Activity from './Activity';

const ActivityWidget = props => {
  const {classes} = props;

  const [user, setUser] = useContext(UserContext);
  const [activities, setActivities] = useState([]);
  const [displayedActivities, setDisplayedActivities] = useState([]);
  const [hasNewActivities, setHasNewActivities] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [excludeAdminActivities, setExcludeAdminActivities] = useState(false);
  const [markAsSeenTimeout, setMarkAsSeenTimeout] = useState(null);

  const open = Boolean(anchorEl);

  const handleOnClick = e => {
    setAnchorEl(e.currentTarget);
    const markAsSeen = async () => {
      const updatedUser = await post('user/activitiesSeen');
      if (updatedUser) {
        setUser(updatedUser);
      }
    };
    setMarkAsSeenTimeout(setTimeout(markAsSeen, 5000));
  };

  const handleActivityListClose = () => {
    setAnchorEl(null);
    clearTimeout(markAsSeenTimeout);
    setMarkAsSeenTimeout(null);
  };

  const handleCheckExcludeAdminActivities = () => {
    setExcludeAdminActivities(!excludeAdminActivities);
  };

  useEffect(() => {
    // Get the user's activities.
    const fetchData = async () => {
      const fetchedActivities = await get('activities');
      if (fetchedActivities) {
        setActivities(fetchedActivities);
      }
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    if (user && activities && activities.length > 0) {
      // Sort the activities by date descending, then check to see if the
      // first one came after the user's activitiesLastRead field.
      activities.sort((a, b) => moment.utc(a.createdAt).isBefore(moment.utc(b.createdAt)));
      const firstActivityCreatedAt = moment.utc(activities[0].createdAt);
      const userActivitiesSeen = moment.utc(user.activitiesLastRead);
      if (userActivitiesSeen.isBefore(firstActivityCreatedAt)) {
        setHasNewActivities(true);
      } else {
        setHasNewActivities(false);
      }
    }
  }, [activities, user]);

  useEffect(() => {
    if (excludeAdminActivities) {
      setDisplayedActivities(activities.filter(a => !a.isAdminActivity));
    } else {
      setDisplayedActivities(activities);
    }
  }, [activities, excludeAdminActivities]);

  return (
    <>
      <Popover
        style={{
          maxHeight: '90%',
        }}
        open={open}
        onClose={handleActivityListClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper
          style={{
            width: '328px',
          }}
        >
          {user && user.isAdmin && (
            <FormControlLabel
              labelPlacement="end"
              label={<Typography className={classes.paragraphFont}>Hide Admin Activity</Typography>}
              style={{
                padding: '15px 30px 0',
              }}
              control={
                <Checkbox
                  checked={excludeAdminActivities}
                  onChange={handleCheckExcludeAdminActivities}
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checkbox,
                    colorPrimary: classes.checkbox,
                    colorSecondary: classes.checkbox,
                  }}
                  style={{
                    color: '#0266B2',
                  }}
                  className={classes.checkbox}
                />
              }
            />
          )}
          <List className={classes.base}>
            {displayedActivities && displayedActivities.length > 0 ? (
              displayedActivities.map((activity, index) => {
                if (index === 0) {
                  return (
                    <React.Fragment key={`frag-${activity.id}`}>
                      <Typography
                        key={`date-${activity.id}`}
                        className={classes.blueLabel}
                        style={{
                          padding: '15px 30px',
                        }}
                      >
                        {moment.utc(activity.createdAt).local().format('MMM D, Y')}
                      </Typography>
                      <Activity key={activity.id} activity={activity} />
                    </React.Fragment>
                  );
                }
                if (
                  index !== displayedActivities.length - 1 &&
                  moment.utc(displayedActivities[index].createdAt).local().format('D') !==
                    moment
                      .utc(displayedActivities[index + 1].createdAt)
                      .local()
                      .format('D')
                ) {
                  return (
                    <React.Fragment key={`frag-${activity.id}`}>
                      <Activity key={activity.id} activity={activity} />
                      <Typography
                        key={`date-${activity.id}`}
                        className={classes.blueLabel}
                        style={{
                          padding: '15px 30px',
                        }}
                      >
                        {moment
                          .utc(displayedActivities[index + 1].createdAt)
                          .local()
                          .format('MMM D, Y')}
                      </Typography>
                    </React.Fragment>
                  );
                }
                return <Activity key={activity.id} activity={activity} />;
              })
            ) : (
              <Typography
                key={0}
                className={classes.paragraphBlackFont}
                style={{
                  marginLeft: '15px',
                }}
              >
                You have no notifications
              </Typography>
            )}
          </List>
        </Paper>
      </Popover>
      <IconButton onClick={handleOnClick}>
        {hasNewActivities ? <NotificationImportantOutlined /> : <NotificationsNoneOutlined />}
      </IconButton>
    </>
  );
};

export default withStyles(styles)(ActivityWidget);
