import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import TimeAgo from 'timeago-react';

import {
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {get, post, put, remove} from '../../../requests';

import {
  MoreHorizOutlined,
  LabelImportantOutlined
} from '@material-ui/icons'

import {CommentsContext, UserContext, UsersContext} from '../../../context';
import SquareAvatar from '../../SquareAvatar';

const gothamFontFamily = ['Gotham A', 'Gotham B', 'GothamBookMedium'];
const gothamNarrowFontFamily = ['Gotham Narrow A', 'Gotham Narrow B', 'GothamNarrowBook'];

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '25%',
  },
  avatar: {
    backgroundColor: 'lightgrey',
  },
  userName: {
    fontWeight: 700,
  },
  timeAgo: {
    paddingLeft: '8px',
    fontSize: '12px',
    color: '#646975',
    fontFamily: gothamNarrowFontFamily,
  },
  commentAuthorName: {
    fontSize: '16px',
    fontFamily: gothamNarrowFontFamily,
    fontWeight: 'bold',
    color: '#333948',
  },
  commentText: {
    fontSize: '14px',
    fontFamily: gothamNarrowFontFamily,
    color: '#545963',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    '-ms-hyphens': 'auto',
    '-moz-hyphens': 'auto',
    '-webkit-hyphens': 'auto',
    hyphens: 'auto',
  },
  commentTextEditing: {
    width: 345,
    backgroundColor: 'white',
    fontSize: '0.75rem',
    wordWrap: 'break-word',
    color: '#646975',
    border: '2px solid lightgrey',
  },
  reply: {
    textTransform: 'none',
    fontFamily: gothamNarrowFontFamily,
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#0266B2',
  },
  nestedReply: {
    wordWrap: 'break-word',
    fontWeight: 700,
    color: '#0266B2',
  },
  editReply: {
    maxWidth: 285,
    marginLeft: '40%',
    fontWeight: 700,
    color: '#0266B2',
  },
  nested: {
    margin: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(7),
  },
  editable: {
    wordWrap: 'break-word',
    minHeight: 35,
    maxWidth: 354,
  },
  notEditable: {
    maxWidth: 354,
  },
  characterCount: {
    paddingBottom: 5,
    marginLeft: 245,
    height: 15,
    color: '#646975',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    fontWeight: 300,
  },
  characterCountOver: {
    height: 15,
    opacity: 1,
    color: 'red',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    fontWeight: 500,
  },
  saveCommentButton: {
    width: '145px',
    borderRadius: '5px',
    height: '45px',
    backgroundColor: '#0266B2',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  cancelCommentButton: {
    width: '147px',
    borderRadius: '5px',
    height: '47px',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    color: '#0266B2',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
    boxSizing: 'border-box',
    border: '2px solid #EEEEF0',
  },
  textAreaEditing: {
    border: 'none',
    width: 333,
    resize: 'none',
    lineHeight: '130%',
    backgroundColor: '#FAFAFF',
  },
  textAreaViewing: {
    border: 'none',
    width: 333,
    maxHeight: '15vh',
    resize: 'none',
    lineHeight: '130%',
    backgroundColor: '#FFFFFF',
  },
  pinnedComment: {
    paddingBottom: '5px',
    margin: 0,
    padding: 0,
    backgroundColor: '#FAFAFF',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    color: '#646975',
  },
  iconHover: {
    paddingRight: '5px',
    height: '9px',
    width: '5px',
  },
  linkListIcon: {
    width: '40px',
    height: '40px',
    padding: '8px',
    cursor: 'pointer',
  },
  listItem: {
    fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
    color: '#646975',
    fontSize: '14px',
    margin: '5px',
    marginTop: '7px',
  },
  positiveButton: {
    borderRadius: '5px',
    height: '56px',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#0266B2',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
    '&:disabled': {
      backgroundColor: '#EEEEF0',
      color: '#BFBFBF',
    },
  },
  negativeButton: {
    borderRadius: '5px',
    width: '100%',
    height: '56px',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    border: '2px solid #EEEEF0',
    color: '#0266B2',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      color: '#009DD9',
      backgroundColor: '#FFFFFF',
    },
  },
}));

const Comment = props => {
  const {comment, showChildren, setShowChildren, type} = props;

  const classes = useStyles();
  const [, setComments] = useContext(CommentsContext);
  const [user] = useContext(UserContext);
  const [users] = useContext(UsersContext);

  const [ago, setAgo] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientX, setClientX] = useState(window.innerWidth / 2);
  const [clientY, setClientY] = useState(window.innerHeight / 2);
  const [commentAuthor, setCommentAuthor] = useState(null);
  const [commentAuthorName, setCommentAuthorName] = useState('');
  const [moreIconHover, setMoreIconHover] = useState(false);
  const [pinnedUserName, setPinnedUserName] = useState('');
  const [repliesText, setRepliesText] = useState('');
  const [showContext, setShowContext] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canPin, setCanPin] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const showingChildren = showChildren;

  const toggleChildren = () => {
    setShowChildren(!showingChildren);
  };

  const handleMoreIconMouseEnter = () => setMoreIconHover(true);
  const handleMoreIconMouseLeave = () => setMoreIconHover(false);

  const handleContext = e => {
    setClientX(e.clientX);
    setClientY(e.clientY);
    setAnchorEl(e.currentTarget);
    setShowContext(true);
  };

  const handleContextClose = () => {
    setShowContext(false);
    setAnchorEl(null);
  };

  const updateCommentContext = async () => {
    const fetchedComments = await get(`link/${comment.link}/comments`);
    setComments(fetchedComments);
  };

  const handleEditComment = () => {
    setEditComment(true);
    setEditedCommentText(comment.commentText);
    handleContextClose();
  };

  const handleSaveEditedComment = async () => {
    await put(`link/${comment.link}/comment/${comment.id}`, {
      commentText: editedCommentText,
      parentCommentId: comment.parentComment,
    });
    await updateCommentContext();
    setEditComment(false);
  };

  const handleCancelEditedComment = () => setEditComment(false);

  const handlePinComment = async () => {
    if (type === 'pinned' || comment.pinned) {
      await post(`link/${comment.link}/comment/${comment.id}/unpin`);
    } else {
      await post(`link/${comment.link}/comment/${comment.id}/pin`);
    }
    await updateCommentContext();
    handleContextClose();
  };

  const handleDeleteComment = async () => {
    await remove(`link/${comment.link}`, `comment/${comment.id}`);
    await updateCommentContext();
    handleContextClose();
  };

  useEffect(() => {
    if (comment) {
      setAgo(moment.utc(comment.createdAt).local().format('x'));
    }
  }, [comment]);

  useEffect(() => {
    if (type === 'parent' && comment && comment.childComments) {
      const numChildren = comment.childComments.length;
      if (showChildren) {
        if (numChildren === 0) {
          setRepliesText('hide');
        } else if (numChildren === 1) {
          setRepliesText('hide reply');
        } else {
          setRepliesText('hide replies');
        }
      } else if (numChildren === 0) {
        setRepliesText('reply');
      } else if (numChildren === 1) {
        setRepliesText('show reply');
      } else {
        setRepliesText('show replies');
      }
    }
  }, [type, comment, showChildren]);

  useEffect(() => {
    if (users && comment) {
      if (type === 'pinned') {
        const pinnedByUser = users.find(u => u.id === comment.pinnedUserId);
        if (pinnedByUser) {
          setPinnedUserName(pinnedByUser.name);
        }
      }
    }
  }, [users, comment, type]);

  useEffect(() => {
    if (users && comment) {
      const foundUser = users.find(u => u.id === comment.author);
      if (foundUser) {
        setCommentAuthor(foundUser);
        setCommentAuthorName(foundUser.displayName);
      }
    }
  }, [users, comment]);

  useEffect(() => {
    if (user && comment) {
      setCanPin(user.isAdmin);
      setCanDelete(user.isAdmin || user.id === comment.author);
      setCanEdit(user.isAdmin || user.id === comment.author);
    }
  }, [user, comment]);

  useEffect(() => {
    setSaveButtonDisabled(editedCommentText === '');
  }, [editedCommentText]);

  return (
    <>
      <Popover
        open={showContext}
        onContextMenu={e => {
          e.preventDefault();
        }}
        onClose={handleContextClose}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{top: clientY, left: clientX}}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <List dense component="ul" className={classes.ul}>
          {canEdit ? (
            <ListItem button onClick={handleEditComment}>
              <Typography className={classes.listItem}>edit</Typography>
            </ListItem>
          ) : null}

          {canPin ? (
            <ListItem button onClick={handlePinComment}>
              <Typography className={classes.listItem}>
                {type === 'pinned' || comment.pinned ? 'unpin' : 'pin'}
              </Typography>
            </ListItem>
          ) : null}

          {canDelete ? (
            <ListItem button onClick={handleDeleteComment}>
              <Typography className={classes.listItem}>delete</Typography>
            </ListItem>
          ) : null}
        </List>
      </Popover>

      {type === 'pinned' && comment ? (
        <p className={classes.pinnedComment}>
          <Icon small className={classes.iconHover}>
            <LabelImportantOutlined/>
          </Icon>
          {`pinned by ${pinnedUserName}`}
        </p>
      ) : null}
      <Grid
        container
        style={{
          paddingBottom: '20px',
        }}
      >
        <Grid item style={{width: '70px', maxWidth: '70px'}}>
          {type !== 'child' && comment ? <SquareAvatar user={commentAuthor} /> : null}
        </Grid>
        <Grid item style={{flexGrow: 1, maxWidth: '300px'}}>
          <Grid container>
            {type === 'child' && comment ? (
              <Grid item style={{width: '70px', maxWidth: '70px'}}>
                <SquareAvatar user={commentAuthor} />
              </Grid>
            ) : null}
            <Grid item style={{flexGrow: 1, maxWidth: type === 'child' ? '230px' : null}}>
              <Typography>
                <span className={classes.commentAuthorName}>{commentAuthorName}</span>
                <TimeAgo className={classes.timeAgo} datetime={ago} />
              </Typography>
              {editComment ? (
                <Grid container spacing={2} style={{paddingRight: '20px', marginBottom: '20px'}}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      margin="dense"
                      variant="outlined"
                      minRows={3}
                      fullWidth
                      className={classes.textField}
                      value={editedCommentText}
                      onChange={e => setEditedCommentText(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.positiveButton}
                      disabled={saveButtonDisabled}
                      onClick={handleSaveEditedComment}
                    >
                      save
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button className={classes.negativeButton} onClick={handleCancelEditedComment}>
                      cancel
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Typography className={classes.commentText}>{comment.commentText}</Typography>
              )}
              {type === 'parent' && comment ? (
                <Typography className={classes.reply} onClick={toggleChildren} component={Button}>
                  {repliesText}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        {(canEdit || canPin || canDelete) && (
          <Grid item style={{width: '40px', maxWidth: '40px'}}>
            {moreIconHover ? (
              <MoreHorizOutlined
                onClick={handleContext}
                onMouseEnter={handleMoreIconMouseEnter}
                onMouseLeave={handleMoreIconMouseLeave}
                className={classes.linkListIcon}
                color={"#009DD9"}
              />
            ) : (
              <MoreHorizOutlined
                onClick={handleContext}
                onMouseEnter={handleMoreIconMouseEnter}
                onMouseLeave={handleMoreIconMouseLeave}
                className={classes.linkListIcon}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Comment;
