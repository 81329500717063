/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {ButtonBase, Dialog, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {
  CollectionsContext,
  SharedCollectionsContext,
  LinksContext,
  RecentlyViewedLinksContext,
  UserContext,
  SubscribedCollectionContext,
	ProfileCollectionsContext,
} from '../../context';
import {get} from '../../requests';
import styles from '../../styles';
import FormModal from '../FormModal';
import LinkDetails from '../LinkDetails';
import CollectionDropdown from './CollectionDropdown';
import CollectionDropdownContainer from './CollectionDropdownContainer';

const Sidenav = ({classes, clearSearch}) => {
  const navigate = useNavigate();
  const [links] = useContext(LinksContext);
  const [collections] = useContext(CollectionsContext);
  const [sharedCollections, setSharedCollections] = useContext(SharedCollectionsContext);
  const [subscribedCollections, setSubscribedCollections] = useContext(SubscribedCollectionContext);
  const [recentlyViewedLinks] = useContext(RecentlyViewedLinksContext);
	const [profileCollections,] = useContext(ProfileCollectionsContext);
  const [user] = useContext(UserContext);
  const [currentUser] = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [detailsLink, setDetailsLink] = useState(null);
  const [userFavourites, setUserFavourites] = useState(null);
  const [userUploaded, setUserUploaded] = useState(null);
  const [userRecent, setUserRecent] = useState(null);

  const getProfileData = () => {
    get('links/favourite', user.id).then(response => {
      const newfavourites = response.map(l => l.id);
      setUserFavourites({
        name: 'my favourites',
        path: 'favourites',
        links: newfavourites,
      });
    });

    get('links/uploaded', user.id).then(response => {
      const newUploaded = response.map(l => l.id);
      setUserUploaded({
        name: 'my uploads',
        path: 'links',
        links: newUploaded,
      });
    });

    get('links/recently-viewed').then(response => {
      const newViewed = response.map(l => l.id);
      setUserRecent({
        name: 'recently visited',
        path: 'recently-viewed',
        links: newViewed,
      });
    });

    get(`users/${user.id}/shared-collections`).then(response => {
      setSharedCollections(response);
    });
  };

  useEffect(() => {
    if (user) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function clearAndNavigate(path) {
    clearSearch();
    navigate(path);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <List className={classes.root} style={{padding: 0}}>
          <ListItem className={classes.media} onClick={clearSearch}>
            <img src="/images/logo.svg" alt="company logo" />
          </ListItem>
        </List>
      </div>
      <Divider className={classes.divider} />
      <List className={classes.mainRoutes}>
        {['home', 'browse'].map((text, index) => (
          <ListItem
            button
            key={index.toString()}
            className={classes.left}
            onClick={() => clearAndNavigate(`/${text.toLowerCase()}`)}
          >
            <ListItemText>
              <Typography className={classes.navText} noWrap>
                {text}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
        {currentUser.isAdmin && (
          <ListItem button className={classes.left} onClick={() => clearAndNavigate('/admin')}>
            <ListItemText>
              <Typography className={classes.navText} noWrap>
                admin
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {(currentUser.isAdmin || currentUser.isFunctionalAdmin) && (
          <ListItem button className={classes.left} onClick={() => clearAndNavigate('/requests')}>
            <ListItemText>
              <Typography className={classes.navText} noWrap>
                requests
              </Typography>
            </ListItemText>
          </ListItem>
        )}
      </List>
      <Divider className={classes.divider} />
      <nav className={classes.navList}>
        {userRecent && links ? (
          <CollectionDropdown
            key="recent"
            collection={userRecent}
            links={links}
            name={userRecent.name}
            path={userRecent.path}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        ) : null}
        {userFavourites && links ? (
          <CollectionDropdown
            key="favourites"
            collection={userFavourites}
            links={links}
            name={userFavourites.name}
            path={userFavourites.path}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        ) : null}
        {userUploaded && links ? (
          <CollectionDropdown
            key="uploads"
            collection={userUploaded}
            links={links}
            name={userUploaded.name}
            path={userUploaded.path}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        ) : null}

        {collections?.length > 0 && (
          <CollectionDropdownContainer
            collections={profileCollections}
            name={'my collections'}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        )}

        {sharedCollections?.length > 0 && (
          <CollectionDropdownContainer
            collections={sharedCollections}
            name={'shared with me'}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        )}

        {subscribedCollections?.length > 0 && (
          <CollectionDropdownContainer
            collections={subscribedCollections}
            name={'subscribed to'}
            clearSearch={clearSearch}
            setDetailsLink={setDetailsLink}
            setOpenDetails={setOpenDetails}
          />
        )}
      </nav>
      {open ? <FormModal open={open} onClose={handleClose} confirm /> : null}
      <hr className={classes.hr} />
      <div className={classes.bottom}>
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          color="default"
          className={classes.postLinkButton}
        >
          {'post link'}
        </Button>
      </div>

      <Dialog
        style={{paddingRight: 0}}
        fullWidth
        maxWidth="lg"
        open={openDetails}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDetails(false);
          }
        }}
      >
        <DialogTitle id="confirmation-dialog-title" style={{padding: 0}}>
          <IconButton
            className={classes.details}
            aria-label="Close"
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              zIndex: 2,
            }}
            onClick={() => setOpenDetails(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LinkDetails link={detailsLink} />
        </DialogContent>
      </Dialog>
    </Drawer>
  );
};

export default withStyles(styles)(Sidenav);
