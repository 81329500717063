import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import styles from '../../styles';
import PinnedCommentsResults from './PinnedCommentsResults';

const PinnedCommentsPage = () => (
  <section>
    <PinnedCommentsResults />
  </section>
);

export default withStyles(styles)(PinnedCommentsPage);
