import {withStyles} from '@material-ui/core/styles';
import React from 'react';

import styles from '../../styles';

const LinkIcon = props => {
  const {linkType, large, archived} = props;

  const defaultLinkType = 9;

  const linkTypeIcons = {
    1: {
      regular: '/icons/link-types/power-bi.svg',
      large: '/icons/link-types/large-power-bi.svg',
      archived: '/icons/link-types/power-bi-archived.svg',
      largeArchived: '/icons/link-types/large-power-bi-archived.svg',
    },
    6: {
      regular: '/icons/link-types/excel.svg',
      large: '/icons/link-types/large-excel.svg',
      archived: '/icons/link-types/excel-archived.svg',
      largeArchived: '/icons/link-types/large-excel-archived.svg',
    },
    7: {
      regular: '/icons/link-types/spotfire.svg',
      large: '/icons/link-types/large-spotfire.svg',
      archived: '/icons/link-types/spotfire-archived.svg',
      largeArchived: '/icons/link-types/large-spotfire-archived.svg',
    },
    8: {
      regular: '/icons/link-types/sharepoint.svg',
      large: '/icons/link-types/large-sharepoint.svg',
      archived: '/icons/link-types/sharepoint-archived.svg',
      largeArchived: '/icons/link-types/large-sharepoint-archived.svg',
    },
    9: {
      regular: '/icons/link-types/general.svg',
      large: '/icons/link-types/large-general.svg',
      archived: '/icons/link-types/general-archived.svg',
      largeArchived: '/icons/link-types/large-general-archived.svg',
    },
    10: {
      regular: '/icons/link-types/WWW-small.svg',
      large: '/icons/link-types/WWW-large-cleared.svg',
      archived: '/icons/link-types/WWW-small-gray.svg',
      largeArchived: '/icons/link-types/WWW-large-cleared-gray.svg',
    },
    11: {
      regular: '/icons/link-types/GEARS-sm-cleared.svg',
      large: '/icons/link-types/GEARS-large-cleared.svg',
      archived: '/icons/link-types/GEARS-sm-cleared-gray.svg',
      largeArchived: '/icons/link-types/GEARS-large-cleared-gray.svg',
    },
    12: {
      regular: '/icons/link-types/SSRS-sm-cleared.svg',
      large: '/icons/link-types/SSRS-large-cleared.svg',
      archived: '/icons/link-types/SSRS-sm-cleared-gray.svg',
      largeArchived: '/icons/link-types/SSRS-large-cleared-gray.svg',
    },
  };

  let internalLinkType = linkType;
  if (!linkTypeIcons[internalLinkType]) {
    internalLinkType = defaultLinkType;
  }

  let linkIcon = linkTypeIcons[internalLinkType].regular;
  if (archived) {
    if (large) {
      linkIcon = linkTypeIcons[internalLinkType].largeArchived;
    } else {
      linkIcon = linkTypeIcons[internalLinkType].archived;
    }
  } else if (large) {
    linkIcon = linkTypeIcons[internalLinkType].large;
  }

  return (
    <img
      src={linkIcon}
      alt="link icon"
      style={{
        padding: large ? 0 : '5px',
        paddingLeft: large ? '16px' : '5px',
        paddingRight: large ? '16px' : '5px',
      }}
    />
  );
};

export default withStyles(styles)(LinkIcon);
