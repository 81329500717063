import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';

const SquareAvatar = props => {
  const {user} = props;
  const {location} = useLocation();
  const [path, setPath] = useState('');
  const [userImage, setUserImage] = useState('');
  useEffect(() => {
    if (user) {
      const userProfileRoute = `/profile/${user.id}`;
      const tempPath = location === userProfileRoute ? user.id : `/profile/${user.id}`;
      setPath(tempPath);
      setUserImage(user.imageBase64);
    }
  }, [user, location]);

  return (
    <Link to={path}>
      <img
        src={userImage}
        height="45px"
        width="45px"
        style={{
          borderRadius: '5px',
          boxShadow: '0 2px 4px 0 rgb(0,0,0,0.5)',
          marginRight: '15px',
        }}
        alt="profile"
      />
    </Link>
  );
};

export default SquareAvatar;
