/* eslint-disable react/forbid-prop-types */
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {SnackbarProvider} from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';
import Searchbar from './components/Searchbar';
import Sidenav from './components/Sidenav';
import {
  ArchivedLinksProvider,
  CategoriesProvier,
  CertLevelsProvider,
  CollectionsProvider,
  CommentsProvider,
  DataSrcsProvider,
  ExpiringProvider,
  FeaturedProvider,
  LinksProvider,
  PinnedCommentsProvider,
  ProfileCollectionsProvider,
  RecentlyViewedLinksProvider,
  SearchProvider,
  SharedCollectionsProvider,
  SubscribedCollectionProvider,
  TagsProvider,
  TypesProvider,
  UpgradeRequestsProvider,
  UserProvider,
  UsersProvider,
} from './context';
import {fetchMany, get, post} from './requests';
import styles from './styles';

const AppShell = props => {
  const {classes} = props;
  const [profileCollections, setProfileCollections] = useState(null);
  const [user, setUser] = useState('');
  const [users, setUsers] = useState('');
  const [links, setLinks] = useState('');
  const [types, setTypes] = useState('');
  const [tags, setTags] = useState('');
  const [certLevels, setCertLevels] = useState('');
  const [dataSources, setDataSources] = useState('');
  const [collections, setCollections] = useState('');
  const [subscribedCollections, setSubscribedCollections] = useState('');
  const [sharedCollections, setSharedCollections] = useState('');
  const [categories, setCategories] = useState('');
  const [featuredLinks, setFeaturedLinks] = useState(null);
  const [search, setSearch] = useState({searchString: '', viewAll: true});
  const [upgradeRequests, setUpgradeRequests] = useState([]);
  const [expiringLinks, setExpiringLinks] = useState([]);
  const [recentlyViewedLinks, setRecentlyViewedLinks] = useState([]);
  const [archivedLinks, setArchivedLinks] = useState([]);
  const [pinnedComments, setPinnedComments] = useState(null);
  const [comments, setComments] = useState([]);
  const [localSearchBarValue, setLocalSearchBarValue] = useState('');
  const checked = true;

  useEffect(() => {
    post('users').then(setUser);
  }, []);

  useEffect(() => {
    if (collections && user) {
      setProfileCollections(collections.filter(col => col.owner === user.id));
    }
  }, [collections, user]);

  useEffect(() => {
    fetchMany(
      [get('tags?type=Tag'), setTags],
      [get('tags?type=DataSource'), setDataSources],
      [get('link-types'), setTypes],
      [get('collections'), setCollections],
      [get('certification-levels'), setCertLevels],
      [get('links'), setLinks],
      [get('tags?type=Category'), setCategories],
      [get('users'), setUsers],
      [get('links/featured'), setFeaturedLinks],
      [get('links/expiring'), setExpiringLinks],
      [get('comments/pinned'), setPinnedComments]
    );
    if (user) fetchMany([get(`users/${user.id}/subscriptions`), setSubscribedCollections]);
  }, [user]);

  function clearSearch() {
    setLocalSearchBarValue('');
  }

  function handleSearchBarOnChange(event) {
    setLocalSearchBarValue(event.target.value);
  }

  return (
    <ProfileCollectionsProvider value={[profileCollections, setProfileCollections]}>
      <UserProvider value={[user, setUser]}>
        <UsersProvider value={[users, setUsers]}>
          <CollectionsProvider value={[collections, setCollections]}>
            <SharedCollectionsProvider value={[sharedCollections, setSharedCollections]}>
              <SubscribedCollectionProvider
                value={[subscribedCollections, setSubscribedCollections]}
              >
                <CertLevelsProvider value={[certLevels, setCertLevels]}>
                  <LinksProvider value={[links, setLinks]}>
                    <TypesProvider value={[types, setTypes]}>
                      <TagsProvider value={[tags, setTags]}>
                        <DataSrcsProvider value={[dataSources, setDataSources]}>
                          <CategoriesProvier value={[categories, setCategories]}>
                            <SearchProvider value={[search, setSearch]}>
                              <FeaturedProvider value={[featuredLinks, setFeaturedLinks]}>
                                <UpgradeRequestsProvider
                                  value={[upgradeRequests, setUpgradeRequests]}
                                >
                                  <RecentlyViewedLinksProvider
                                    value={[recentlyViewedLinks, setRecentlyViewedLinks]}
                                  >
                                    <ArchivedLinksProvider
                                      value={[archivedLinks, setArchivedLinks]}
                                    >
                                      <ExpiringProvider value={[expiringLinks, setExpiringLinks]}>
                                        <PinnedCommentsProvider
                                          value={[pinnedComments, setPinnedComments]}
                                        >
                                          <CommentsProvider value={[comments, setComments]}>
                                            {user && collections && links && categories && types ? (
                                              <SnackbarProvider
                                                ButtonProps={{
                                                  disableRipple: true,
                                                  disableFocusRipple: true,
                                                }}
                                                SnackbarProps={{
                                                  ContentProps: {
                                                    className: classes.notificationSuccess,
                                                  },
                                                  autoHideDuration: 3000,
                                                  anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  },
                                                }}
                                              >
                                                <Router>
                                                  <Fade in={checked} timeout={1000}>
                                                    <div className={classes.root}>
                                                      <CssBaseline />
                                                      <AppBar
                                                        position="fixed"
                                                        className={classes.appBar}
                                                        elevation={0}
                                                      >
                                                        <Searchbar
                                                          handleSearchBarOnChange={
                                                            handleSearchBarOnChange
                                                          }
                                                          localSearchBarValue={localSearchBarValue}
                                                        />
                                                      </AppBar>
                                                      <Sidenav clearSearch={clearSearch} />
                                                      <main className={classes.content}>
                                                        <Routes clearSearch={clearSearch} />
                                                      </main>
                                                    </div>
                                                  </Fade>
                                                </Router>
                                              </SnackbarProvider>
                                            ) : (
                                              <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item style={{marginTop: '40vh'}}>
                                                  <img src="/images/logo.svg" alt="company logo" />
                                                </Grid>
                                                <Grid item>
                                                  <CircularProgress />
                                                </Grid>
                                              </Grid>
                                            )}
                                          </CommentsProvider>
                                        </PinnedCommentsProvider>
                                      </ExpiringProvider>
                                    </ArchivedLinksProvider>
                                  </RecentlyViewedLinksProvider>
                                </UpgradeRequestsProvider>
                              </FeaturedProvider>
                            </SearchProvider>
                          </CategoriesProvier>
                        </DataSrcsProvider>
                      </TagsProvider>
                    </TypesProvider>
                  </LinksProvider>
                </CertLevelsProvider>
              </SubscribedCollectionProvider>
            </SharedCollectionsProvider>
          </CollectionsProvider>
        </UsersProvider>
      </UserProvider>
    </ProfileCollectionsProvider>
  );
};

AppShell.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppShell);
