/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React, {useContext, useEffect, useState} from 'react';

import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {useSnackbar} from 'material-ui-snackbar-provider';

import {CollectionsContext} from '../../context';
import {get, post} from '../../requests';
import styles from '../../styles';
import {giveUserFeedback} from '../giveUserFeedback/giveUserFeedback';
import StyledCheckbox from './StyledCheckbox';

const CollectionList = props => {
  const snackbar = useSnackbar();
  const [collections, setCollections] = useContext(CollectionsContext);
  const [state, setState] = useState({});
  const [localSearchBarValue, setLocalSearchBarValue] = useState('');
  const [addCollections, setAddCollections] = useState([]);
  const {classes, onClose, link} = props;

  const handleChange = name => event => {
    setState({...state, [name]: event.target.checked});
  };

  const filterOut = collection => {
    const exists = state.hasOwnProperty(collection.name) && state[collection.name] === true;
    return exists;
  };

  const findCollectionId = () => {
    const collectionsArray = collections.filter(filterOut);
    const collectionsArrayOnlyIds = collectionsArray.map(collection => collection.id);
    setAddCollections(collectionsArrayOnlyIds);
  };

  useEffect(() => {
    if (collections && collections.length) {
      collections.map(collection => setState({...state, [collection.name]: false}));
    }
  }, []);

  useEffect(() => {
    findCollectionId();
  }, [state]);

  function handleSearchBarOnChange(event) {
    setLocalSearchBarValue(event.target.value);
  }

  const viewableCollections = collections.filter(c => {
    const {name} = c;
    const lowerCaseName = name.toLowerCase();
    const lowerCaseSearchValue = localSearchBarValue.toLowerCase();
    if (lowerCaseName.includes(lowerCaseSearchValue)) {
      return c;
    }
    return null;
  });

  const addCollectionsAction = () => {
    if (addCollections.length) {
      const collectionIds = addCollections ? addCollections.join(',') : null;
      post(`link/${link.id}/collections?collectionIds=${collectionIds}`)
        .then(response => {
          if (response.id && addCollections.length === 1) {
            const {name} = link;
            const longName = name.length > 30 ? `${name.substring(0, 30)}...` : name;
            const singleCollection = collections.find(c => c.id === addCollections[0]);
            giveUserFeedback(snackbar, `added ${longName} to ${singleCollection.name}`);
          } else if (response.id && addCollections.length > 1) {
            const {name} = link;
            const longName = name.length > 30 ? `${name.substring(0, 30)}...` : name;
            giveUserFeedback(snackbar, `added ${longName} to ${addCollections.length} collections`);
          }
        })
        .then(() => {
          get('collections').then(setCollections);
        });
    }
    onClose();
  };

  const listSize = viewableCollections.length > 8;

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography
            align="center"
            className={classes.titleFont}
            style={{paddingTop: '15px', paddingBottom: '30px'}}
          >
            add to collection
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          marginLeft: 72,
          border: '2px solid #EAEAED',
          borderRadius: 5,
          width: 400,
        }}
      >
        <Grid item xs={1}>
          <IconButton
            disableRipple
            disableFocusRipple
            className={classes.seachNoHover}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <InputBase
            style={{
              paddingLeft: 5,
              height: '23px',
              fontSize: '14px',
              fontWeight: '300',
              lineHeight: '23px',
              width: '100%',
            }}
            placeholder="search"
            onChange={handleSearchBarOnChange}
          />
        </Grid>
      </Grid>
      <div
        style={{
          paddingTop: '30px',
          paddingBottom: '30px',
          paddingLeft: 72,
        }}
      >
        <FormGroup
          column="true"
          style={
            listSize
              ? {
                  width: 400,
                  maxHeight: 185,
                  minHeight: 185,
                  overflowY: 'scroll',
                  overflowX: 'none',
                }
              : {
                  maxHeight: 185,
                  minHeight: 185,
                  overflowY: 'hidden',
                  overflowX: 'none',
                  width: 400,
                }
          }
        >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {viewableCollections && viewableCollections.length
              ? viewableCollections
                  .filter(c => !c.smart)
                  .map(collection => {
                    const {name} = collection;
                    const trimmedName = name.length > 45 ? `${name.substring(0, 45)}...` : name;
                    return (
                      <Grid item xs={12} key={collection.id}>
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              value={collection.name}
                              checked={state[collection.name]}
                              onChange={handleChange(collection.name)}
                            />
                          }
                          label={<span className={classes.checkboxText}>{trimmedName}</span>}
                          key={collection.id}
                        />
                      </Grid>
                    );
                  })
              : null}
          </Grid>
        </FormGroup>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{paddingBottom: 35}}
      >
        <Grid item>
          <Button className={classes.saveCollectionButtonLight} onClick={onClose}>
            cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.saveCollectionButtonDark}
            onClick={addCollectionsAction}
            disabled={!viewableCollections.length}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CollectionList);
