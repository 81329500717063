/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {Grid, Input, Paper, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import styles from '../../styles';

const ImageUpload = ({file, setFile, imageUrl, classes}) => {
  const [displayFile, setDisplayFile] = useState(null);
  const [hover, setHover] = useState(false);

  const handleFileUpload = event => {
    const newDisplayFile = URL.createObjectURL(event.target.files[0]);
    setDisplayFile(newDisplayFile);
    const addedFile = event.target.files[0];
    setFile(addedFile);
  };

  useEffect(() => {
    if (imageUrl) {
      setDisplayFile(imageUrl);
    }
  }, [imageUrl]);

  return (
    <div>
      <Paper className={classes.imagePreview} style={{height: 224, width: 404, marginTop: 6}}>
        {displayFile ? (
          <label
            style={{height: 'inherit', width: 'inherit'}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Input
              style={{display: 'none', height: 'inherit', width: 'inherit', borderRadius: 5}}
              type="file"
              onChange={event => handleFileUpload(event)}
              inputProps={{accept: 'image/*'}}
            />
            {hover && (
              <div className={classes.imageHover} style={{borderRadius: 5}}>
                change image
              </div>
            )}
            <img
              style={{width: '100%', maxHeight: '100%', objectFit: 'cover', borderRadius: 5}}
              src={displayFile || null}
              alt="preview"
            />
          </label>
        ) : (
          <Grid item>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <ImageOutlinedIcon style={{padding: '30px 0 10px 0', borderRadius: 5}} />
              {/*TODO: enable drag and drop image upload*/}
              {/*<Typography>drag an image file here</Typography>*/}
              {/*<Typography>or</Typography>*/}

              <label htmlFor="file">
                <Input
                  id="file"
                  style={{display: 'none'}}
                  type="file"
                  onChange={event => handleFileUpload(event)}
                  inputProps={{accept: 'image/*'}}
                />
                <Typography style={{color: '#0266B2', cursor: 'pointer', fontWeight: 'bold'}}>
                  click to upload
                </Typography>
              </label>
            </div>
          </Grid>
        )}
        <Input
          id="file"
          style={{display: 'none'}}
          type="file"
          onChange={event => handleFileUpload(event)}
          inputProps={{accept: 'image/*'}}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ImageUpload);
