import React, {useEffect, useContext} from 'react';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import {get} from '../../../requests';
import CancelIcon from '@material-ui/icons/Cancel';
import {UsersContext} from '../../../context';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectChip = ({collectionId, sharedToUsers, setSharedToUsers}) => {
  const [users] = useContext(UsersContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUsers();
        setSharedToUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, []);

  const fetchUsers = async () => {
    try {
      if (collectionId) {
        const collection = await get(`collection/${collectionId}`);
        const filteredUsers = users.filter(user => collection.sharedToUsers.includes(user.id));
        return filteredUsers;
      }
      return [];
    } catch (error) {
      console.error('Failed to fetch users:', error);
      throw new Error('Failed to fetch users');
    }
  };

  const handleChange = event => {
    const {
      target: {value},
    } = event;
    setSharedToUsers(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDelete = user => {
    const updatedUsers = sharedToUsers.filter(selectedUser => selectedUser !== user);
    setSharedToUsers(updatedUsers);
  };

  return (
    <FormControl style={{width: '100%'}}>
      <InputLabel id="demo-multiple-chip-label" style={{paddingLeft: 15, marginTop: -5}}>
        Users
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={sharedToUsers}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={selected => (
          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map((value, index) => (
              <Chip
                style={{marginRight: 5, marginBottom: 5}}
                key={index}
                avatar={<Avatar alt={value.displayName} src={value.imageBase64} />}
                label={value.displayName}
                variant="outlined"
                clickable
                onDelete={() => handleDelete(value)}
                deleteIcon={<CancelIcon onMouseDown={event => event.stopPropagation()} />}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {users.map(user => (
          <MenuItem key={user.id} value={user}>
            <Avatar alt={user.displayName} src={user.imageBase64} />
            <Typography variant="body2" display="block" gutterBottom style={{marginLeft: 20}}>
              {user.displayName}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectChip;
