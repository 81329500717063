/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'material-ui-snackbar-provider';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {FormStateProvier, LinksContext, NewImageProvider} from '../../context';
import {get, post, postImage} from '../../requests';
import styles from '../../styles';
import AddLinkForm from '../AddLinkForm';
import ConfirmModal from '../ConfirmModal';
import {giveUserFeedback} from '../giveUserFeedback/giveUserFeedback';

const FormModal = props => {
  const snackbar = useSnackbar();
  const [links, setLinks] = useContext(LinksContext);
  const [formState, setFormState] = useState({});
  const [newLink, setNewLink] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const memoziedFormState = useMemo(() => [formState, setFormState], [formState]);
  const memoziedNewImage = useMemo(() => [newImage, setNewImage], [newImage]);
  const [value, setValue] = useState(true);
  const [body, setBody] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [disableFormModalSaveButton, setDisableFormModalSaveButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const {open, onClose, confirm, onSave, saveButton, small, classes} = props;

  useEffect(() => {
    if (newImage && newLink) {
      const fetchData = async () => {
        await postImage(`link/${newLink.id}/image`, newImage);
        const fetchedLinks = await get('links');
        setLinks(fetchedLinks);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLink]);

  useEffect(() => {
    setBody(formState);
  }, [formState]);

  function handleClose() {
    onClose(value);
  }
  const handleCancel = () => {
    if (confirm) {
      setConfirmModal(true);
    } else {
      onClose(value);
    }
  };

  const handleSave = () => {
    setDisableFormModalSaveButton(true);
    setTimeout(setDisableFormModalSaveButton(false), 1000);
    if (onSave) {
      onSave();
      handleClose();
    } else {
      const fetchData = async () => {
        await post('link', body).then(response => {
          switch (response) {
            case 409:
              setErrorMessage('This link already exists. Please try a different URL.');
              break;
            default:
              if (response.id) {
                setNewLink(response);
                handleClose();
                giveUserFeedback(snackbar, `Link ${response.name} added`);
              }
              break;
          }
        });
        get('links').then(setLinks);
      };
      fetchData();
    }
  };

  return (
    <Dialog
      fullWidth
      PaperProps={{className: classes.addLinkModalHeight}}
      maxWidth={small ? 'sm' : 'md'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <IconButton
          aria-label="Close"
          style={{position: 'absolute', top: 0, right: 0}}
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormStateProvier value={memoziedFormState}>
          <NewImageProvider value={memoziedNewImage}>
            <AddLinkForm
              setDisableFormModalSaveButton={setDisableFormModalSaveButton}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          </NewImageProvider>
        </FormStateProvier>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            {confirm && confirmModal ? (
              <ConfirmModal onClose={handleClose} confirm={setConfirmModal} />
            ) : null}
            <Button className={classes.negativeButton} onClick={handleCancel}>
              {saveButton ? 'close' : 'cancel'}
            </Button>
          </Grid>
          <Grid item>
            {saveButton ? null : (
              <Button
                disabled={disableFormModalSaveButton}
                className={classes.positiveButton}
                onClick={handleSave}
              >
                save
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(FormModal);
