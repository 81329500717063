/* eslint-disable no-unused-vars */

import {Card, Divider, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';

import InactiveLinksContainer from './InactiveLinksContainer';
import {LinksContext} from '../../context';
import styles from '../../styles';
import AdminStatistics from './AdminStatistics';
import PinnedCommentsContainer from './PinnedCommentsContainer';

const Admin = props => {
  const {classes} = props;
  const [links] = useContext(LinksContext);

  return (
    <section>
      <AdminStatistics />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            style={{marginBottom: 29}}
            variant="h4"
            align="center"
            className={classes.titleFont}
          >
            watchlist
          </Typography>
          <Divider className={classes.dividerFull} />
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <InactiveLinksContainer links={links} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <PinnedCommentsContainer />
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default withStyles(styles)(Admin);
