const drawerWidth = 315;
const primary = '#FAFAFC'; // #9e9e9e
const dark = '#FFFFFF'; // #212121
const light = '#FAFAFC';
const headerColor = '#333948';
const textBlack = '#333948';
const textBlue = '#0266B2';
const textGrey = '#545963';
const textRed = '#E21836';
const textLightGrey = '#646975';
const textWhite = '#FFFFFF';
const backgroundBlue = '#0266B2';
const rowBackgroundBlue = '#E6F6FC';
const disabledBtn = '#EEEEF0';
const disabledFont = '#BFBFBF';
const gothamFontFamily = ['Gotham A', 'Gotham B', 'GothamBookMedium'];
const gothamNarrowFontFamily = ['Gotham Narrow A', 'Gotham Narrow B', 'GothamNarrowBook'];

const styles = theme => ({
  spacing: 2,
  root: {
    overflow: 'hidden',
    display: 'flex',
  },
  titleFont: {
    fontFamily: gothamFontFamily,
    fontSize: 27,
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
  },
  headingFont: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 18,
    fontWeight: 'bold',
    color: headerColor,
  },
  blackLabel: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333948',
  },
  labelFont: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 16,
    fontWeight: 'bold',
    color: headerColor,
  },
  errorFont: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 15,
    color: textRed,
  },
  blueLabel: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    fontWeight: 'bold',
    color: textBlue,
  },
  lightGreyLabel: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    lineHeight: '15px',
    color: textLightGrey,
  },
  paragraphFont: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    color: textGrey,
    lineHeight: '23px',
  },
  paragraphBlackFont: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    color: textBlack,
    lineHeight: '23px',
  },
  blueNote: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'italic',
    color: textBlue,
  },
  blue: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    fontWeight: 300,
    color: headerColor,
  },
  blueLink: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 14,
    fontWeight: 'bold',
    color: textBlue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHeaderText: {
    fontFamily: gothamFontFamily,
    fontSize: 18,
    fontWeight: 'bold',
    color: textBlack,
  },
  statsLabelText: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: 16,
    color: textBlack,
  },
  statsValueText: {
    fontFamily: gothamFontFamily,
    fontSize: 27,
    fontWeight: 'bold',
    color: textBlack,
    letterSpacing: '-0.5px',
  },
  tableRow: {
    border: 'none',
    '&:hover': {
      backgroundColor: [rowBackgroundBlue, '!important'],
    },
  },
  checkbox: {
    color: textBlue,
    '&:hover': {
      backgroundColor: ['#FFFFFF', '!important'],
    },
  },
  // // used for table headings
  // headingFont: gothamFontFamily,
  // headingFontSize: 18,
  // headingFontWeight: 'bold',

  // // used for nav links, table subheaders
  // subheadingFont: gothamFontFamily,
  // subheadingFontSize: 16,
  // subheadingFontWeight: 'bold',

  // // used for paragraph text
  // textFont: gothamNarrowFontFamily,
  // textFontSize: 14,
  // textFontWeight: 300,

  // // colors
  // darkBlue: '#0066B2', // used for blue text
  // deepBlue: '#0266B2', // used for buttons
  // lightBlue: '#E6F6FC',

  appBar: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: primary,
  },
  checkboxText: {
    height: '23px',
    color: textGrey,
    fontFamily: gothamNarrowFontFamily,
    fontSize: '14px',
    paddingLeft: 17,
  },
  drawer: {
    position: 'relative',
    width: drawerWidth,
    flexShrink: 0,
    border: 'none',
  },
  dropdownLinkName: {
    color: '#333948',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  showMoreButton: {
    color: '#0266B2',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  dropdownUserName: {
    height: '15px',
    color: '#646975',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    fontWeight: '300',
    paddingTop: 2,
    paddingBottom: 11,
  },
  postLinkButton: {
    fontFamily: gothamFontFamily,
    position: 'absolute',
    bottom: 0,
    marginBottom: '1vh',
    left: '10%',
    width: '245px',
    borderRadius: '5px',
    height: '5.5vh',
    justifyContent: 'center',
    backgroundColor: backgroundBlue,
    border: 'none',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
  },
  saveCollectionButtonDark: {
    fontFamily: gothamFontFamily,
    width: '187px',
    height: '58px',
    borderRadius: '5px',
    justifyContent: 'center',
    backgroundColor: backgroundBlue,
    border: 'none',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
  },
  saveCollectionButtonLight: {
    borderRadius: '5px',
    width: '187px',
    height: '58px',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    border: '2px solid #EEEEF0',
    color: '#0266B2',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      color: '#009DD9',
      backgroundColor: '#FFFFFF',
    },
  },
  positiveButtonSmall: {
    width: '144px',
    borderRadius: '5px',
    height: '40px',
    justifyContent: 'center',
    backgroundColor: '#0266B2',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
      color: '#FFFFFF',
    },
    '&:disabled': {
      backgroundColor: disabledBtn,
      color: disabledFont,
    },
  },
  seachNoHover: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  positiveButton: {
    width: '187px',
    borderRadius: '5px',
    height: '56px',
    justifyContent: 'center',
    backgroundColor: '#0266B2',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
    '&:disabled': {
      backgroundColor: disabledBtn,
      color: disabledFont,
    },
  },
  positiveButtonLarge: {
    width: '235px',
    borderRadius: '5px',
    height: '56px',
    justifyContent: 'center',
    backgroundColor: '#0266B2',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#009DD9',
    },
    '&:disabled': {
      backgroundColor: disabledBtn,
      color: disabledFont,
    },
  },
  negativeButton: {
    borderRadius: '5px',
    width: '187px',
    height: '56px',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    border: '2px solid #EEEEF0',
    color: '#0266B2',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    '&:hover': {
      color: '#009DD9',
      backgroundColor: '#FFFFFF',
    },
  },
  addCollectionButton: {
    position: 'absolute',
    right: 40,
    width: '62px',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    textTransform: 'none',
    color: textBlue,
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  editCollectionSection: {
    position: 'absolute',
    right: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  editCollectionButton: {
    width: '62px',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    textTransform: 'none',
    color: textBlue,
    fontFamily: 'Gotham A, Gotham B, GothamBookMedium',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  hr: {
    margin: 0,
    position: 'absolute',
    marginBottom: '10.5vh',
    bottom: 0,
    left: '10%',
    borderTop: '1px solid #EEEEF0',
    width: 254,
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    marginBottom: '1.5vh',
    left: '10%',
    zIndex: 2,
  },
  media: {
    marginTop: 30,
    marginBottom: 18,
    marginLeft: 25,
    marginRight: 45,
    height: 51,
    width: 235,
  },
  uploadContainer: {
    position: 'absolute',
    bottom: 0,
    height: '60px',
    width: '100%',
    zIndex: '1',
    textTransform: 'none',
    justifyContent: 'left',
    paddingRight: '17px',
    boxSizing: 'content-box',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: dark,
    color: light,
    border: 'none',
    boxShadow: '-8px 2px 18px 2px rgba(103,138,154,0.3)',
  },
  addLinkModalHeight: {
    height: '80vh',
  },
  navText: {
    color: headerColor,
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  nestedNavText: {
    color: headerColor,
    fontFamily: gothamFontFamily,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    marginTop: '80px',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    color: '#A6A9B0',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '2px solid #EAEAED',
    width: '100%',
    marginRight: 10,
  },
  generalPaper: {
    borderRadius: '5px',
    boxShadow: '0 2px 18px 2px rgba(103, 138, 154, 0.07)',
  },
  notificationSuccess: {
    height: '85px',
    width: '770px',
    borderRadius: '5px',
    backgroundColor: '#00B39F',
    boxShadow: '0 8px 11px 0 rgba(152,152,152,0.17)',
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  snackbarPrimary: {
    maxWidth: 600,
    color: '#FFFFFF',
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: 30,
  },
  snackbarSecondary: {
    maxWidth: 600,
    color: '#FFFFFF',
    fontFamily: gothamNarrowFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    paddingLeft: 30,
  },
  sectionDesktop: {
    color: '#A6A9B0',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  searchIcon: {
    padding: 0,
    color: headerColor,
  },
  navList: {
    height: 'initial',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - (10.5vh + 370px))',
  },
  divider: {
    position: 'relative',
    borderTop: '2px solid #EEEEF0',
    marginLeft: '35px',
    marginRight: '35px',
  },
  centerTop: {
    marginTop: '0px',
  },
  dividerFull: {
    borderTop: '2px solid #EEEEF0',
    marginBottom: 20,
    marginLeft: 15,
    marginRight: 20,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(10),

    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  greyBox: {
    height: 200,
    width: '100%',
    margin: '3%',
    backgroundColor: '#ffffff',
  },
  dropList: {
    width: '275px',
    marginLeft: '35px',
    marginRight: '20px',
    backgroundColor: dark,
  },
  icon: {
    marginRight: '24px',
  },
  left: {
    padding: '15px 0px 20px 55px',
  },
  nested: {
    color: headerColor,
    paddingLeft: theme.spacing(3),
  },
  imagePreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F6FA',
    boxShadow: 0,
    position: 'relative',
  },
  imageHover: {
    height: 'inherit',
    width: 'inherit',
    objectFit: 'cover',
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: backgroundBlue,
    opacity: 0.85,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontWeight: 'bold',
    fontFamily: gothamFontFamily,
    fontSize: 16,
    cursor: 'pointer',
  },
  // add Collection
  root2: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: '795px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  toggle: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#009DD9',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  formControl: {
    width: '100%',
    marginBottom: 20,
    marginTop: 6,
    fontFamily: gothamFontFamily,
  },
  textField: {
    marginBottom: 25,
  },
  // Category Browse
  categoryChip: {
    height: 152,
    width: 270,
    textAlign: 'center',
    position: 'relative',
    margin: 15,
    borderRadius: 5,
  },
  categoryChipName: {
    width: 208,
    color: 'white',
    fontFamily: gothamFontFamily,
    fontSize: 27,
    fontWeight: 'bold',
    lineHeight: 1,
    textAlign: 'center',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  categoryCardHover: {
    height: 152,
    width: 270,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: '5px',
    backgroundColor: backgroundBlue,
    opacity: 0,
    transition: '0.15s ease',
    zIndex: 2,
    '&:hover': {
      opacity: 0.85,
    },
    color: 'white',
    fontFamily: gothamFontFamily,
    fontSize: 27,
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    textTransform: 'none',
  },
  categoryCardHoverActionIcons: {
    zIndex: 3,
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 270,
    float: 'right',
    paddingRight: '5px',
  },
  previewImg: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  squareAvatar: {
    borderRadius: '5px',
    backgroundColor: 'lightgrey',
  },
  // Search results
  searchResultsHeader: {
    color: headerColor,
    fontFamily: gothamFontFamily,
    fontSize: '27px',
    letterSpacing: '-0.5px',
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: '29px',
  },
  searchResultsSubHeader: {
    color: headerColor,
    fontFamily: gothamFontFamily,
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
    lineHeight: '21px',
    textAlign: 'center',
    marginBottom: '29px',
  },
  searchResultsViewAll: {
    position: 'absolute',
    right: 40,
    textAlign: 'right',
    border: 'none',
    textTransform: 'none',
    color: textBlue,
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    cursor: 'pointer',
  },
  searchResultsNoResultsText: {
    fontFamily: gothamNarrowFontFamily,
    fontWeight: 300,
    color: textGrey,
    fontSize: '14px',
    lineHeight: '23px',
  },
  searchResultUserImage: {
    height: 160,
    width: 160,
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    margin: '15px',
  },
  searchResultUserName: {
    textAlign: 'center',
    width: '160px',
    margin: '15px',
    marginTop: '-5px',
    color: textBlack,
    fontFamily: gothamFontFamily,
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  linkListIcon: {
    width: '40px',
    height: '40px',
    padding: '8px',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  linkRowBoldText: {
    color: textBlack,
    fontFamily: gothamNarrowFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '23px',
  },
  linkRowRegularText: {
    color: textGrey,
    fontFamily: gothamNarrowFontFamily,
    fontSize: '14px',
    lineHeight: '23px',
  },
  commentRowRegularText: {
    color: '#545963',
    fontfamily: gothamNarrowFontFamily,
    fonSize: '14px',
    fontWeight: 300,
    lineHeight: '23px',
  },
  featuredCount: {
    height: '32px',
    width: '331px',
    color: '#333948',
    fontSize: '27px',
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
    lineHeight: '32px',
  },
  // CollectionGrid
  base: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    justifySelf: 'end',
  },
  viewAllButton: {
    width: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    fontFamily: gothamFontFamily,
    textTransform: 'none',
    color: '#0266B2',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  card: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0 2px 18px 2px rgba(103,138,154,0.07)',
  },
  statsDropdown: {
    float: 'right',
    paddingRight: '25px',
    width: '180px',
    height: '40px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 18px 2px rgba(103, 138, 154, 0.07)',
  },
  statsCard: {
    textAlign: 'left',
    minWidth: '100px',
    width: '150px',
    height: '133px',
    borderRadius: '5px',
    boxShadow: '0 2px 18px 2px rgba(103, 138, 154, 0.07)',
    padding: '25px',
  },
  // link tiles
  linkCard: {
    height: 248,
    width: 270,
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    position: 'relative',
    textAlign: 'center',
    boxShadow: '1px 2px 18px 2px rgba(103,138,154,0.07)',
    cursor: 'pointer',
    transition: '0.15s ease',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '1px 2px 18px 2px rgba(103,138,154,0.35)',
    },
  },
  focusHighlight: {
    opacity: 0,
  },
  linkCardButton: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  linkCardTitle: {
    fontFamily: gothamNarrowFontFamily,
    inlineSize: '248px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: textBlack,
  },
  linkCardMenuOpen: {
    transform: 'scale(1.02)',
    boxShadow: '1px 2px 18px 2px rgba(103,138,154,0.35)',
  },
  linkCardPublisherName: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: '14px',
    color: textGrey,
  },
  linkCardPreviewText: {
    color: textWhite,
    fontFamily: gothamFontFamily,
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    height: 248,
    width: 270,
    margin: '0 auto',
  },
  oneStorCertified: {
    fontFamily: gothamFontFamily,
    backgroundColor: '#FAAB18',
    color: textWhite,
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: 'auto',
    width: '147px',
    height: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  functionallyCertified: {
    fontFamily: gothamFontFamily,
    backgroundColor: '#009DD9',
    color: textWhite,
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: 'auto',
    width: '147px',
    height: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  linkCardActionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px',
    width: '100%',
  },
  linkCardActionIcon: {
    margin: 'auto 0',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  linkNameText: {
    fontFamily: gothamFontFamily,
    fontSize: '21px',
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
    color: textBlack,
  },
  linkUpdatedText: {
    fontFamily: gothamNarrowFontFamily,
    fontSize: '12px',
    color: textLightGrey,
  },
});

export default styles;
