/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {IconButton, InputBase, ListItem, Toolbar, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Search} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {SearchContext, UserContext} from '../../context';
import styles from '../../styles';
import SquareAvatar from '../SquareAvatar';
import ActivityWidget from './ActivityWidget';

const Searchbar = ({classes, handleSearchBarOnChange, localSearchBarValue}) => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [, setSearchContext] = useContext(SearchContext);

  function handleOnClickSearchButton() {
    setSearchContext({
      searchString: localSearchBarValue,
      viewAll: true,
    });
    navigate('/search');
  }

  function handleOnKeyDown(event) {
    if (event.key === 'Enter') {
      handleOnClickSearchButton();
    }
  }

  return (
    <Toolbar>
      <div className={classes.search}>
        <ListItem
          component={Link}
          to="/search"
          style={{display: 'inline', paddingLeft: 5, paddingRight: 5}}
        >
          <IconButton aria-label="search">
            <Search />
          </IconButton>
        </ListItem>
        <InputBase
          style={{
            paddingLeft: 5,
            height: '23px',
            fontSize: '14px',
            lineHeight: '23px',
            width: '90%',
            fontFamily: ['Gotham A', 'Gotham B', 'GothamBookMedium'],
          }}
          type="text"
          placeholder="search"
          value={localSearchBarValue}
          onChange={e => handleSearchBarOnChange(e)}
          onKeyDown={handleOnKeyDown}
        />
      </div>
      <div className={classes.grow} />
      <ActivityWidget />
      <div className={classes.sectionDesktop}>
        <div
          style={{
            height: '60px',
            paddingRight: '20px',
          }}
        >
          <Typography
            className={classes.labelFont}
            style={{
              lineHeight: '60px',
            }}
            noWrap
          >
            {user.name}
          </Typography>
        </div>
        <div style={{marginTop: 5}}>
          <SquareAvatar user={user} />
        </div>
      </div>
    </Toolbar>
  );
};

Searchbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Searchbar);
